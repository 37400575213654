import { SHOP_CUSTOM_IMG_PATH } from "App-config";

import "components/order/style/Beautydesigner.css";

const Beautydesigner = ({ menuCate }) => {
  return (
    <div className="wrap">
      <div className="designer" id={menuCate.mc_idx}>
        <p>{menuCate.mc_name}</p>
        <div className="designer-img">
          <div className="picture">
            <img
              src={
                SHOP_CUSTOM_IMG_PATH +
                "hair/cateImg/" +
                menuCate.s_idx +
                "/" +
                menuCate.mc_img
              }
              alt="디자이너이미지"
            />
          </div>
        </div>
        <div className="introduce">
          <ul>
            <li>{menuCate.mc_explained}</li>
            {/* <li>화요일 휴무</li> */}
          </ul>
          {/* <ul>
                <li><img src={likes} alt="좋아요" /><span>2</span></li>
                <li><img src={chat} alt="댓글" /><span>12</span></li>
            </ul> */}
        </div>
      </div>
    </div>
  );
};

export default Beautydesigner;
