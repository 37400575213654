import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import {
  delAddrHistory,
  getAddrApi,
  getAddrHistory,
  setLocation,
} from "service/AccountService";

import KakaoAddrPopup from "components/common/KakaoAddrPopup";

import "components/order/style/OrderDetail.css";
import stateUtilityInstance from "../../TestClass";
import "components/common/style/LocationSet.css";

const LocationSet = ({ userInfo, setUserInfo, redirectUrl }) => {
  const navigate = useNavigate();

  const { state } = useLocation();

  const userAddrBtn = useRef();
  const userAddrDetailInput = useRef();

  const [addr, setAddr] = useState("");
  const [popup, setPopup] = useState(false);
  const [enroll_company, setEnroll_company] = useState({
    address:'',
  });
  
  const [historyList, setHistoryList] = useState([]);
  const handleComplete = (data) => {
   
    setPopup(!popup);
}
  const addrHandleEvent = (fullAddr) => {
    
    setAddr(fullAddr);
  };
  const handleInput = (e) => {
    
      setAddr(document.getElementById("address-input").value);
  }
  const addrSaveClickEvent = () => {
   // const _addr = addr.trim();
   const _addr = document.getElementById("address-input").value;
    const _userAddrDetail = userAddrDetailInput.current.value.trim();

    if (!_addr && !_userAddrDetail) navigate(-1);

    if (!_addr) {
      userAddrBtn.current.focus();
      return;
    }
    if (!_userAddrDetail) {
      userAddrDetailInput.current.focus();
      return;
    }

    setLocation({ addr: _addr, addrDetail: _userAddrDetail })
      .then((res) => {
        if (res.success) {
          setUserInfo(res.data);
          if (redirectUrl) {
            navigate(redirectUrl);
          } else {
            alert("주소가 변경되었습니다.");
          }
        }
      })
      .catch(console.error);
  };

  const gpsLocationClickEvent = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          if (position) {
            getAddrApi({
              lat: position.coords.latitude,
              lon: position.coords.longitude,
            })
              .then((res) => {
                if (res.success) {
                  addrHandleEvent(res.data);
                }
              })
              .catch((err) => {
                console.error(err);
              });
          }
        },
        function (error) {
          console.error(error);
          alert(error);
        }
      );
    } else {
      console.log("주소 사용불가능 ");
    }
  };

  const historyElementClickEvent = (addrData) => {
    if (addrData.ah_content) {
      const addrTemp = addrData.ah_content.split("/");
      try {
        if (addrTemp.length > 0) {
          setAddr(addrTemp[0].trim());
          document.getElementById("address-input").value = addrTemp[0].trim();
        }
        if (addrTemp.length > 1) {
          if (addrTemp[1].trim()) {
            userAddrDetailInput.current.value = addrTemp[1];
          } else {
            userAddrDetailInput.current.value = "";
            userAddrDetailInput.current.focus();
          }
        }
      } catch (error) {}
    }
  };

  const HistoryAddrList = ({ historyList }) => {
    return historyList.map((addr, index) => (
      <li key={addr.ah_idx}>
        <p onClick={() => historyElementClickEvent(addr)}>{addr.ah_content}</p>
        <button
          className="list-delete"
          onClick={(e) => {
            delAddrHistory({ ahIdx: addr.ah_idx, isAll: false })
              .then((res) => {
                if (res.success) {
                  try {
                    let newHistoryList = [...historyList];
                    for (
                      let index = 0;
                      index < newHistoryList.length;
                      index++
                    ) {
                      const element = newHistoryList[index];
                      if (element.ah_idx == addr.ah_idx) {
                        newHistoryList.splice(index, 1);
                        break;
                      }
                    }
                    setHistoryList(newHistoryList);
                  } catch (error) {}
                } else alert(res.msg);
              })
              .catch(console.error);
          }}
        >
          X
        </button>
      </li>
    ));
  };

  useEffect(() => {
    stateUtilityInstance.setMobileHeaderTitle('위치설정');
    if (state && state.isDetailFocus) {
      setAddr(state.addr);
      userAddrDetailInput.current.focus();
    }

    getAddrHistory()
      .then((res) => {
        if (res.success) {
          if (res.data.length > 0) setHistoryList(res.data);
        }
      })
      .catch((err) => {
        // 로그인 확인
        console.error(err);
      });
  }, []);

  return (
    <section className="locationset-frame">
      <span className="locationset-title">위치설정</span>
      <div className="location-set-box">
        <span className="order-title font-w-heavy">현재주소</span>
        <p>{userInfo.mb_addr}</p>
      </div>
      <div className="address-find">
        <span className="order-title font-w-heavy">주소찾기</span>
        <p>지번,도로명,건물명을 입력하세요</p>
        <div className="default-address">
          <input
            type="text"
            id="address-input"
            onChange={handleInput}
            placeholder="현재주소"
            readOnly
          />
           <button onClick={handleComplete}>찾기</button>
           {popup && <KakaoAddrPopup ></KakaoAddrPopup>}
         
        </div>
        <div className="detail-address">
          <input
            type="text"
            id="detail-input"
            placeholder="상세주소"
            ref={userAddrDetailInput}
          />
        </div>
      </div>
      <div className="location-save-box">
        <button
          className="address-save-button"
          ref={userAddrBtn}
          onClick={addrSaveClickEvent}
        >
          주소저장
        </button>
        <button
          className="location-save-button"
          onClick={gpsLocationClickEvent}
        >
          현 위치로 설정
        </button>
      </div>

      {historyList && historyList.length > 0 && (
        <ul className="recent-address">
          <div className="recent-address-title">
            <span className="order-title font-w-heavy">최근 주소</span>
            <button
              className="recent-address-delete-button"
              onClick={(e) => {
                if (window.confirm("주소 내역을 전부 삭제하시겠습니까?")) {
                  delAddrHistory({ ahIdx: null, isAll: true })
                    .then((res) => {
                      if (res.success) {
                        try {
                          setHistoryList([]);
                        } catch (error) {}
                      } else alert(res.msg);
                    })
                    .catch(console.error);
                }
              }}
            >
              전체삭제
            </button>
          </div>
          <HistoryAddrList historyList={historyList} />
        </ul>
      )}
    </section>
  );
};

export default LocationSet;
