import { useNavigate } from "react-router-dom";

import { USER_DTO } from "App-config";
import { requestAnswer } from "service/ADSService";

import questionImg from "src_assets/question.png";

import "components/main/style/QuestionModal.css";

function QuestionModal({ data, setUser, requestAnswerEvent }) {
  const navigate = useNavigate();
  const ADS_IDX = data.a_idx;

  const checkAnswerEvent = (e) => {
    requestAnswer({ a_idx: ADS_IDX, answer: e.currentTarget.value })
      .then((res) => {
        if (res.isDuplicate) {
          alert("포인트가 이미 지급된 광고입니다.");
        }
        requestAnswerEvent(res);
      })
      .catch((err) => {
        alert("다시 로그인해주세요.");
        sessionStorage.clear();
        setUser(USER_DTO);
        navigate("/login");
      });
  };
  return (
    <form className="question-form">
      <div className="question-head">
        <img src={questionImg} alt="질문 아이콘" className="question-img"></img>
        <span className="question-text">{data.a_question}</span>
      </div>
      <ul className="question-list">
        <li onClick={checkAnswerEvent} value="1">
          <input
            type="checkbox"
            id="q-check-first"
            name="q-check-first"
          ></input>
          <label htmlFor="q-check-first">
            <span>{data.a_answer_1}</span>
          </label>
        </li>
        <li onClick={checkAnswerEvent} value="2">
          <input
            type="checkbox"
            id="q-check-second"
            name="q-check-second"
          ></input>
          <label htmlFor="q-check-second">
            <span>{data.a_answer_2}</span>
          </label>
        </li>
        <li onClick={checkAnswerEvent} value="3">
          <input
            type="checkbox"
            id="q-check-third"
            name="q-check-third"
          ></input>
          <label htmlFor="q-check-third">
            <span>{data.a_answer_3}</span>
          </label>
        </li>
      </ul>
    </form>
  );
}

export default QuestionModal;
