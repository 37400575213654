import { useEffect, useRef } from "react";
import Scroll from "react-scroll";


const useThemeObserver = (
    setState ,
    stateNumber
  ) => {
    //console.dir(Scroll);    

    const isRef = useRef(HTMLElement);
    const option = {threshold: 0.2, rootmargin:0};
  
    useEffect(() => {
      const observer = new IntersectionObserver((entry) => {
        if (entry[0].isIntersecting) {

          var nav = document.getElementById('navScroll');
          var x = nav.scrollLeft;
          //console.log("navScroll" + "-x좌표 : " + x);
          

          var navItem = document.getElementById("navBtn"+stateNumber.navNumber);
          //navItem.getBoundingClientRect().x;

          const navState = {
            navNumber : stateNumber.navNumber,
            navScroll : x + navItem.getBoundingClientRect().x - 50,
          };
          //setState(navState);
          //console.log('test'+stateNumber.navNumber);
          //nav.scrollTo(x,0);
          //console.log(document.querySelector("#navBtn"+stateNumber));
          //document.querySelector("#navBtn"+stateNumber).scrollIntoView(Scroll);
          //var nav = document.getElementById("navBtn"+stateNumber);
          //var x = nav.getBoundingClientRect().x;
          //console.log("navBtn"+stateNumber + "-x좌표 : " + x);

          //nav = document.querySelector("#navScroll");
          //x = nav.scrollLeft;
          //console.dir(x);
          //x = window.scrollX;
          
          
          //Scroll.animateScroll.scrollTo(x+50,{to : "navScroll", containerId : "navScroll" ,horizontal:true});
        
          
        }
      }, option);
  
      if (isRef.current) {
        observer.observe(isRef.current);
      }
  
      return () => observer.disconnect();
    }, []);
  
    return [isRef];
  };

  export default useThemeObserver;