import KakaoMap from "components/common/KakaoMap";

import "components/order/style/ShopInfo.css";

// 지호 220706 상점정보보기
const ShopInfoComponentJustInfo = ({ shopInfo }) => {
    function bizNoFormatter(num) {
        var formatNum = "";
        try {
            if (num.length == 10) {
                formatNum = num.replace(/(\d{3})(\d{2})(\d{5})/, "$1-$2-$3");
            }
        } catch (e) {
            formatNum = num;
            console.error(e);
        }
        return formatNum;
    }

    return (
        <section className="shop-info-frame">
            <div className="shop-map-wrap">
                <div className="shop-map">
                    <span className="order-title">주변 위치</span>
                    <KakaoMap
                        lat={shopInfo.lat}
                        lng={shopInfo.lon}
                        userLocation={true} />
                </div>
            </div>
            <div className="ceo-info-wrap">
                <div className="ceo-info">
                    <span className="order-title">위치정보</span>
                    <table className="ceo-info-table">
                        <tbody>
                            <tr>
                                <td>설치장소</td>
                                <td id="loc1"></td>
                            </tr>
                            <tr>
                                <td>도로주소</td>
                                <td id="loc2"></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </section>
    );
};

export default ShopInfoComponentJustInfo;
