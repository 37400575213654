import { useEffect, useRef } from "react";

import "components/order/style/FollowBasket.css";

const FollowBasket = ({ menuList, basketCount, basketClickEvent }) => {
  const shopBasketRef = useRef();
  const basketRef = useRef();
  const scrollEvent = (e) => {
    console.log('scroll');
    let shopBasket = shopBasketRef.current?.clientHeight;
    let basket_y = basketRef.current?.clientHeight / 1.3;

    const scrollY = window.scrollY;
    let scroll_target = document.querySelector(".follow-basket");

    const headerHeight = document.querySelector(".header.main")?.offsetHeight;
    const bannerHeight = document.querySelector(".banner-frame")?.offsetHeight;

    if (scrollY > shopBasket) {
    } else if (scrollY > bannerHeight + headerHeight) {
      scroll_target.setAttribute("style", "top:" + (scrollY - basket_y) + "px");
    } else {
      scroll_target.setAttribute("style", "top: 20px");
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", scrollEvent);

    return () => {
      window.removeEventListener("scroll", scrollEvent);
    };
  }, []);

  const NaviList = () => {
    const liTagList = menuList.map((item, index) => {
      return (
        <li
          key={index}
          onClick={() => {
            const headerHeight =
              document.querySelector(".header.main").offsetHeight;
            const element = document.getElementById(item.mc_idx);
            const y =
              element.getBoundingClientRect().top +
              window.pageYOffset -
              headerHeight * 1.5;
            window.scrollTo({ top: y, behavior: "smooth" });
          }}
        >
          <p>{item.mc_name}</p>
        </li>
      );
    });
    return <ul className="menu-navi">{liTagList}</ul>;
  };

  return (
    <section className="shopping-basket" ref={shopBasketRef}>
      <div className="follow-basket" ref={basketRef}>
        <button onClick={basketClickEvent} className="add-item">
          <div className="add-item-count">{basketCount}</div>
        </button>
        <button
          className="to-top-button"
          onClick={() => {
            const bannerHeight =
              document.querySelector(".header.main").offsetHeight;
            window.scrollTo({
              top: bannerHeight,
              behavior: "smooth",
            });
          }}
        >
          TOP
        </button>

        {menuList.length > 0 && <NaviList />}
      </div>
    </section>
  );
};

export default FollowBasket;
