import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import testimg from "src_assets/item_img.png";

import Pagination from "components/common/Pagination";
// import KakaoAddrPopup from "components/common/KakaoAddrPopup";
import { getGiftCouponList } from "service/OrderService";

import "components/mykiikey/style/Orderlist.css";
import "components/mykiikey/style/Mykiikey.css";
import "components/common/style/Modal.css";

import stateUtilityInstance from "../../TestClass";

import face from "src_assets/icon/face.png";
import twi from "src_assets/icon/twi.png";
import kakao from "src_assets/icon/kakao.png";
import you from "src_assets/icon/you.png";
import { NewLoading } from "components/common/NewLoading";
import { IMAGE_BASE_URL } from "App-config";

// 지호 220707 주문내역 ziho_ohl
// 2022-07-17 주문내역 가져오기 페이징처리, 로직변경
const GiftCouponList = ({
  userInfo,
  giftCouponList,
  setGiftCouponList,
  isMobile,
}) => {
  const [img, setImg] = useState(null);
  const [imgPrice, setImgPrice] = useState(0);
  const [dpModal, setDpModal] = useState(null);
  const [isLoad, setIsLoad] = useState(false);

  const VIEW_LIMIT = 6;
  const [currentPage, setCurrentPage] = useState(1);
  const offset = (currentPage - 1) * VIEW_LIMIT;
  const giftCouponListLimit = isMobile
    ? giftCouponList.slice(0, offset + VIEW_LIMIT)
    : giftCouponList.slice(offset, offset + VIEW_LIMIT);

  const giftCouponImgClickEvent = (data) => {
    setDpModal(data);
  };

  const liTagList = giftCouponListLimit.map((couponList, index) => {
    return (
      <>
        <div className="gift-img">
          <p
            className="menu-preparing"
            style={{ display: couponList.is_use == "0" ? "none" : "" }}
          >
            사용완료
          </p>
          <img
            src={IMAGE_BASE_URL + "/gift/coupon/" + couponList.cl_img}
            onClick={() => {
              giftCouponImgClickEvent(true);
              setImg(IMAGE_BASE_URL + "/gift/coupon/" + couponList.cl_img);
              setImgPrice(couponList.m_salePrice);
            }}
            onError={""}
            alt="메뉴이미지"
          ></img>

          <div className="gift-list-head">
            <div className="gift-list-state">
              <span>{couponList.m_name}</span>
            </div>
          </div>
        </div>
      </>
    );
  });

  useEffect(() => {
    stateUtilityInstance.setMobileHeaderTitle("기프티콘");
    getGiftCouponList(userInfo.mb_id)
      .then((res) => {
        if (res.success) {
          setIsLoad(true);
          setGiftCouponList(res.data);
        }
      })
      .catch((err) => {});
  }, []);

  const DPModal = () => {
    return (
      <div className="Modal" onClick={() => setDpModal(null)}>
        <div
          className={
            "modalBody-" +
            (window.innerHeight - window.innerWidth > 0 ? "wid" : "hei")
          }
          onClick={(e) => e.stopPropagation()}
        >
          <button id="modalCloseBtn" onClick={() => setDpModal(null)}>
            ✖
          </button>
          <img src={img} alt="메뉴이미지"></img>
          <div className="modalPrice">
            <p>
              {imgPrice.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              <span
                style={{
                  paddingLeft: "3px",
                  fontSize: 18,
                  verticalAlign: "middle",
                }}
              >
                원
              </span>
            </p>
          </div>
        </div>
      </div>
    );
  };

  const resultView = (
    <>
      {dpModal && <DPModal />}
      <ul className="daps-ul-list-gift">
        {/* 지호 220707 주문내역 데이터 로딩 */}
        {liTagList}
      </ul>
      {/* <button className="info-chagne-confirm">저장</button> */}
      <Pagination
        isMobile={isMobile}
        limit={VIEW_LIMIT}
        offset={offset}
        type={"giftCoupon"}
        totalCount={giftCouponList.length}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </>
  );

  return (
    <div className="daps-frame">{isLoad ? resultView : <NewLoading />}</div>
  );
};

export default GiftCouponList;
