import {
    useState,
    useRef,
    useCallback,
    forwardRef,
    useImperativeHandle,
  } from "react";
  
  import { debounceFunction } from "App-config";
  
  import "components/order/style/OrderDetail.css";
  
  const useInput = ({
    initialValue,
    payChangeHandler
  }) => {
    const [value, setValue] = useState(initialValue);
  
    const submitPay = useCallback(
        debounceFunction((val) => payChangeHandler(val), 100),
        []
    );

    const changeHandler = (val) => {
        console.log("test ::" + val)
      setValue(val);
      submitPay(val);
    };
  
    const onChange = (event) => {
      const targetVal = event.target.value;

      if (!isNaN(targetVal) && parseInt(targetVal) > 0) {
        const val = targetVal.replace(/(^0+)/, "") * 1;
        changeHandler(val);
      } else {
        changeHandler(0);
      }
    }
  
    return { value, onChange };
  };
  
  const OrderUsePay = forwardRef(
    (
      {
        payChangeHandler,
        usePay
      },
      ref
    ) => {
      const checkRef = useRef();
      const pay = useInput({
        initialValue: 0,
        usePay: usePay,
        initElement: checkRef,
        payChangeHandler: payChangeHandler
      });
  
      useImperativeHandle(ref, () => ({
        pointReset,
      }));
  
      const pointReset = () => {
        pay.onChange({ target: { checked: false } });
      };
  
      return (
        <div className="wrap_frame">
          <div className="order-content pay-order-content">
              <div className="kiikey-point-use-wrap">
                <div className="kiikey-point-use">
                  <div className="kiikey-point-used">
                    <span>금액 입력</span>
                    <input
                      id="point-use-input"
                      type="text"
                      placeholder="0"
                      value={
                        pay.value
                      }
                      onChange={(e) => {
                        pay.onChange(e);
                      }}
                    />
                    </div>
                  </div>
                </div>
            </div>
        </div>
      );
    }
  );
  
  export default OrderUsePay;
  