import { useEffect, useState } from "react";
import stateUtilityInstance from "TestClass";
import { ReactComponent as AlertIcon } from "src_assets/icon/alert_001_icon.svg";
import { useLocation } from "react-router-dom";

const PaymentSuccessPage = ({ userInfo }) => {
  let { state } = useLocation();
  const { paytype } = useState(0);

  /* state = {
    totalAmt: 10000,
    usePoint: 13000,
    totalDelFee: 3000,
    myPoint: 10000,
  }; */

  console.log(userInfo);

  useEffect(() => {
    stateUtilityInstance.setMobileHeaderTitle("주문완료", true, true);
  }, [state]);

  const PayContent = () => {
    if (!state) return <></>;
    if (state.totalAmt - state.usePoint + state.totalDelFee > 0) {
      return (
        <>
          <div style={AlertGridContainerStyle}>
            <div style={{ fontWeight: "700" }}>계좌번호</div>
            <div>1005-503-785077 우리은행</div>

            <div style={{ fontWeight: "700" }}>예금주명</div>
            <div>백옥생코리아(주)</div>

            <div style={{ fontWeight: "700" }}>입금금액</div>
            <div>
              {(state.totalAmt - state.usePoint + state.totalDelFee)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              원
            </div>
          </div>

          <div>
            <p>반드시 주문자명으로 입금해 주세요. </p>
            <p>입금확인이 된 후 출고처리가 진행됩니다.</p>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div>전액 포인트로 주문하셨습니다.</div>
          <div style={AlertGridContainerStyle}>
            <div style={{ fontWeight: "700" }}>결제금액</div>
            <div>
              {(state.totalAmt + state.totalDelFee)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              원
            </div>

            <div style={{ fontWeight: "700" }}>잔여포인트</div>
            <div>
              {(state.myPoint - state.usePoint)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </div>
          </div>
        </>
      );
    }
  };

  //if(state)
  return (
    <>
      <div style={mainContainerStyle}>
        <div style={AlertContainerStyle}>
          <div style={AlertTitleContainerStyle}>
            <div>주문해 주셔서 감사합니다</div>
            <AlertIcon style={{ transform: "translate(0, 1px)" }} />
          </div>

          <div style={AlertContentContainerStyle}>
            <PayContent />
          </div>
        </div>
      </div>
    </>
  );
};

const mainContainerStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",

  color: "#000",
  fontFamily: "Inter",
  fontSize: "16px",
  fontStyle: "normal",
  lineHeight: "150%",
};

const AlertContainerStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  padding: "1em",

  borderRadius: "0.8em",
  border: "1px solid #828282",
  background: "#FFF",
  boxSizing: "border-box",
};

const AlertTitleContainerStyle = {
  width: "100%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "flex-end",
  fontSize: "1.2em",
  fontWeight: "700",
};

const AlertContentContainerStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  gap: "1.5em",
  width: "100%",
  padding: "1.5em 1em",
  borderRadius: "0.5em",
  background: "#EBEBEB",
  boxSizing: "border-box",
};

const AlertGridContainerStyle = {
  display: "grid",
  gridTemplateColumns: "auto 1fr",
  rowGap: "0.0em",
  columnGap: "0.7em",
};

export default PaymentSuccessPage;
