import { useEffect } from "react";

import { auth, RecaptchaVerifier } from "service/firebase";

import { RECAPTCHA_ID } from "App-config";

const AuthFindIdComponent = ({
  isView,
  setAuthView,
  setAuthInputView,
  phoneInputDisabled,
}) => {
  useEffect(() => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      RECAPTCHA_ID,
      {
        size: "normal",
        callback: function (response) {
          // onSolvedRecaptcha();
          console.log("auth 11");
          setAuthInputView(true);
        },
        "expired-callback": function () {
          console.log("auth 22");
          setAuthView(false);
          phoneInputDisabled(false);
        },
      },
      auth
    );
  }, []);

  return (
    <span
      style={{
        height: "auto",
        display: isView ? "" : "none",
      }}
    >
      <div id={RECAPTCHA_ID} />
    </span>
  );
};

export default AuthFindIdComponent;
