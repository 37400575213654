import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";

import { DEFAULT_POINT_PANNEL_LENGTH } from "App-config";

import game_start_img from "src_assets/game_start_btn.png";
import game_start_img_mobile from "src_assets/game_start_btn_mobile.png";
import bannerimg from "src_assets/mobile-coin-test.png";
import bannertv from "src_assets/yellow_tv_with_coin.png";
import mobileBannertv from "src_assets/yellow_tv_with_coin_mobile.png";
import comma from "src_assets/comma.png";

import "components/main/style/Banner.css";

const ADBanner = ({ isOrder, user, playVideoEvent, hiddenCatchGame }) => {
  const navigate = useNavigate();

  const [pointPannel, setPointPannel] = useState("0");

  const targetEle = useRef();

  useEffect(() => {
    if (user) setPointPannel(adBannerFn.makeNewPannel(user.myPoint));
  }, [user]);

  return (
    <section className="banner-frame">
      <div className="banner-mobile-bg" />
      <img
        src={bannerimg}
        alt="모바일에서 뜨는 코인 이미지"
        className="mobile-banner-img"
      />
      <div className="banner-inner">
        <div className="banner-left">
          <div className="banner-text">
            <span>
              <b>광고</b>보고
            </span>
            <span>
              <b>적립</b>하고
            </span>
            <span>지금바로</span>
            <span>
              <b>이쁨</b>하세요!
            </span>
          </div>
        </div>
        <div className="banner-right" ref={targetEle}>
          <img src={bannertv} alt="데스크탑 티비이미지" className="banner-tv" />

          <img
            src={mobileBannertv}
            alt="모바일티비이미지"
            className="mobile-tv"
          />
          <div className="tv-ad-index">
            <button
              onClick={(e) => {
                if (isOrder) {
                  navigate("/");
                } else {
                  playVideoEvent(e);
                }
              }}
              className="ad-show-button"
            />
            <img
              src={game_start_img}
              onClick={(e) => {
                hiddenCatchGame(e);
              }}
              className="game-show-button"
            />
            <img
              src={game_start_img_mobile}
              onClick={(e) => {
                hiddenCatchGame(e);
              }}
              className="game-show-button-mobile"
            />
            <div className="ad-number-index">
              <span>{user.viewCnt}</span>
              <span>{user.totalViewCnt}</span>
            </div>
          </div>
        </div>
        <div className="banner-mid">
          <div className="count-number">
            <div className="count-text">
              <span>
                안녕하세요 <b>{user.mbName}</b>님
              </span>
              <span>
                내 <b>포인트</b>를 확인하세요!
              </span>
            </div>
            <div className="ad-point-box">
              <ol className="ad-point">
                <li className="point-result">{pointPannel[0]}</li>
                <li className="comma">
                  <img src={comma} alt="쉼표이미지"></img>
                </li>
                <li className="point-result">{pointPannel[1]}</li>
                <li className="point-result">{pointPannel[2]}</li>
                <li className="point-result">{pointPannel[3]}</li>
                <li className="comma">
                  <img src={comma} alt="쉼표이미지"></img>
                </li>
                <li className="point-result">{pointPannel[4]}</li>
                <li className="point-result">{pointPannel[5]}</li>
                <li className="point-result">{pointPannel[6]}</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const parseStringPoint = (point) => {
  if (!point || point < 1 || isNaN(parseInt(point))) {
    return "";
  }
  return String(point);
};
const makeNewPannel = (point) => {
  const strPoint = parseStringPoint(point);
  let newPointPannel = [];
  for (let i = 0; i < DEFAULT_POINT_PANNEL_LENGTH; i++) {
    if (strPoint[i]) newPointPannel.push(strPoint[i]);
    else newPointPannel.unshift("0");
  }
  return newPointPannel;
};

export const adBannerFn = {
  makeNewPannel: makeNewPannel,
};

export default ADBanner;
