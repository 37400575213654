import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import BeautyInfo from "components/order/beautyPart/BeautyInfo";
import BeautyQuestions from "components/order/beautyPart/BeautyQuestions";
import BeautyReview from "components/order/beautyPart/BeautyReview";
import testimg from "src_assets/item_img.png";

import "components/order/style/Beautydetail.css";
import { getMenuDetail } from "service/OrderService";
import { SHOP_CUSTOM_IMG_PATH } from "App-config";

const Beautydetail = () => {
  const navigate = useNavigate();

  const { state } = useLocation();

  const [changeTab, setChangeTab] = useState(1);

  const [menuImgs, setMenuImgs] = useState([]);
  const [imgIndex, setImgIndex] = useState(0);
  const [currentImg, setCurrentImg] = useState();

  const [menuInfo, setMenuInfo] = useState({});
  const [reviews, setReviews] = useState([]);
  const [inquires, setInquires] = useState([]);

  useEffect(() => {
    if (state) {
      setMenuInfo(state.menu);

      getMenuDetail({
        s_idx: state.shopInfo.s_idx,
        m_idx: state.menu.m_idx,
        table_name: state.shopInfo.table_name,
      })
        .then((res) => {
          if (res.success) {
            if (res.data.imgs) {
              setCurrentImg(<ImgLiTag imgData={res.data.imgs[0]} />);
              setMenuImgs(res.data.imgs);
            }
            setReviews(res.data.reviews);
            setInquires(res.data.inquires);
          }
        })
        .catch(console.error);
    } else {
      navigate("/order");
    }
  }, []);

  const ImgLiTag = ({ imgData }) => {
    return (
      <li>
        <img
          src={
            SHOP_CUSTOM_IMG_PATH +
            state.shopInfo.table_name.replace("_", "") +
            "/" +
            imgData.s_idx +
            "/" +
            imgData.m_idx +
            "/" +
            imgData.img_name
          }
          alt="어어"
        />
      </li>
    );
  };

  const ImgDotList = () => {
    return (
      <ul className="indicator">
        {menuImgs.map((imgData, index) => (
          <li
            key={index}
            className={imgIndex === index && "active"}
            onClick={() => {
              setImgIndex(index);
              setCurrentImg(<ImgLiTag imgData={imgData} />);
            }}
          ></li>
        ))}
      </ul>
    );
  };

  return (
    <div className="Beautydetail-frame">
      <div className="img-wrap">
        <ul className="slide-img">{currentImg}</ul>
        <div className="box-bottom">
          <ImgDotList />
        </div>
      </div>
      {Object.keys(menuInfo).length > 0 && (
        <ul className="beauty-detail-info">
          <li className="beauty-product-name">
            <p>메뉴명 :</p>
            <p>{menuInfo.m_name}</p>
          </li>
          <li className="beauty-product-price">
            <p>가격 :</p>
            {menuInfo.m_price <= 0 ? (
              menuInfo.m_option_cate[0]?.m_options[0]?.mo_price ===
              menuInfo.m_option_cate[0]?.m_options[
                menuInfo.m_option_cate[0]?.m_options.length - 1
              ]?.mo_price ? (
                <p>
                  {menuInfo.m_option_cate[0]?.m_options[0]?.mo_price
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  원
                </p>
              ) : (
                <p>
                  {menuInfo.m_option_cate[0]?.m_options[0]?.mo_price
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
                    " ~ " +
                    menuInfo.m_option_cate[0]?.m_options[
                      menuInfo.m_option_cate[0]?.m_options.length - 1
                    ]?.mo_price
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  원
                </p>
              )
            ) : (
              <p>
                {menuInfo.m_price
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                원
              </p>
            )}
          </li>
          {menuInfo.m_time && (
            <li className="beauty-product-time">
              <p>소요시간 :</p>
              <p>{menuInfo.m_time.split(":")[0] * 1}시간</p>
            </li>
          )}

          {/* 아직 기능이 없어 주석처리함
        <span className="detail-function">
          <button className="detail-share-button"></button>
          <button className="detail-like-button"></button>
        </span> */}
        </ul>
      )}

      <div className="detail-wrap">
        <div className="detail-wrap-tab">
          {/* 이상윤 220720 라벨에 인풋으로 수정함. */}
          <input
            type="radio"
            id="detail-tab"
            name="details-tab"
            checked={changeTab === 1}
          ></input>
          <label htmlFor="detail-tab" onClick={() => setChangeTab(1)}></label>
          <input
            type="radio"
            id="raview-tab"
            name="details-tab"
            checked={changeTab === 2}
          ></input>
          <label htmlFor="raview-tab" onClick={() => setChangeTab(2)}></label>
          <input
            type="radio"
            id="questions-tab"
            name="details-tab"
            checked={changeTab === 3}
          ></input>
          <label
            htmlFor="questions-tab"
            onClick={() => setChangeTab(3)}
          ></label>
        </div>
        {changeTab === 1 && <BeautyInfo menuInfo={menuInfo} />}
        {changeTab === 2 && <BeautyReview reviews={reviews} />}
        {changeTab === 3 && (
          <BeautyQuestions
            shopInfo={state.shopInfo}
            menuInfo={menuInfo}
            inquires={inquires}
            setInquires={setInquires}
          />
        )}
      </div>
      <button
        className="back-page-button"
        onClick={() =>
          navigate("/order/shop", { state: { shopInfo: state.shopInfo } })
        }
      >
        뒤로가기
      </button>
    </div>
  );
};

export default Beautydetail;
