import { useEffect, useState } from "react";

import Pagination from "components/common/Pagination";

import "components/mykiikey/style/Mykiikey.css";
import "components/mykiikey/style/TicketList.css";
import stateUtilityInstance from "../../TestClass";

import { REVIEW_IMG_PATH } from "App-config";
import { deleteticket, getTicketList } from "service/AccountService";
import { NewLoading } from "components/common/NewLoading";

const TicketList = ({ isMobile }) => {
  const VIEW_LIMIT = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [ticketDetail, setTicketDetail] = useState(null);
  const [ticketList, setTicketList] = useState([]);
  const [isLoad, setIsLoad] = useState(false);

  const offset = (currentPage - 1) * VIEW_LIMIT;
  const ticketListLimit = isMobile
    ? ticketList.slice(0, offset + VIEW_LIMIT)
    : ticketList.slice(offset, offset + VIEW_LIMIT);

  //  const ticketDeleteClickEvent = (data) => {
  //    deleteticket({ iqIdx: data.iq_idx })
  //      .then((res) => {
  //        if (res.success) {
  //          setTicketList(
  //           //  ticketList.filter((ticket) => ticket.iq_idx != data.iq_idx)
  //          );
  //        }
  //      })
  //      .catch(console.error);
  //  };

  useEffect (() => {
    stateUtilityInstance.setMobileHeaderTitle('영화티켓 구매내역');

    getTicketList()
      .then((res) => {
        if (res.success) {
          setIsLoad(true);
          setTicketList(res.data);
        }
      })
      .catch((err) => {
        console.error("ticket err", err);
        console.error("ticket err msg", err.msg);
      });
  }, []);

  const liTagList = ticketListLimit.map((item, index) => {
    return (
      <li key={index}>
        <div className="ticket-list-head">
          <span className="ticket-list-name">{item.mo_name}</span>
        </div>
        <div className="ticket-list-date">
          <span>{item.t_datetime}</span>
          <ul className="ticket-list-body">
            <li>
              <button
                className="ticket-check-button"
                onClick={() => setTicketDetail(item)}
              >
                사용내역확인
              </button>
            </li>
            <li className="ticket-button">
              {/* <button
                className="ticket-delete-button"
                // onClick={() => ticketDeleteClickEvent(item)}
              >
                내역삭제
              </button> */}
            </li>
          </ul>
        </div>
      </li>
    );
  });

  const TicketAnswer = ({ ticketDetail }) => {
    return (
      <div className="order-history">
        <p>상태</p>
        <span>
          {ticketDetail.tm_state > 0 ? (
            <>
              <p>영화티켓 {ticketDetail.t_state == 1 ? "사용" : "미사용"}</p>
              <p>식당 {ticketDetail.f_state == 1 ? "사용" : "미사용"}</p>
            </>
          ) : (
            <p>영화티켓 {ticketDetail.t_state == 1 ? "사용" : "미사용"}</p>
          )}
        </span>
        <p>구매날짜</p>
        <span>{ticketDetail.t_datetime}</span>
      </div>
    );
  };

  const TicketModal = () => {
    return (
      <div className="ticket-modal-frame">
        <div className="ticket-modal">
          <div className="ticket-title">
            <p>사용내역확인</p>
            <button onClick={() => setTicketDetail(null)}>X</button>
          </div>
          <div className="ticket-text">
            <p style={{fontSize : 12, fontWeight: "bold"}}>모닝( 06:00~ )</p>
            <p style={{fontSize : 12, fontWeight: "bold"}}>브런치( 11:01~ )</p>
            <p style={{fontSize : 12, fontWeight: "bold"}}>일반( 13:01~ )</p>
          </div>
          <div className="order-history">
            <p>교환정보</p>
            <span>이름&nbsp;&nbsp;&nbsp;&nbsp;: {ticketDetail.mb_name}</span>
            <span>연락처 : {ticketDetail.mb_phone}</span>
            <span>&nbsp;</span>
            <p>상품명</p>
            <span>{ticketDetail.mo_name}</span>
          </div>
          <TicketAnswer ticketDetail={ticketDetail} />
        </div>
      </div>
    );
  };

  const resultView = (
    <>
      <div className="daps-ul-list">{liTagList}</div>
      <Pagination
        isMobile={isMobile}
        limit={VIEW_LIMIT}
        offset={offset}
        type={"ticketList"}
        totalCount={ticketList.length}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
      {ticketDetail && <TicketModal />}
    </>
  );

  return (
    <div className="daps-frame">
      {isLoad ? resultView : <NewLoading />}
    </div>
  );
};

export default TicketList;
