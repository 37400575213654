import { useNavigate } from "react-router-dom";

import corporate from "src_assets/corporate.png";
import general from "src_assets/general.png";

import "components/account/style/LoginRoute.css";
import { PARTNER_LOGIN_URL } from "App-config";

const SigninRoute = () => {
  const navigate = useNavigate();

  const redirectPartnerLogin = (e) => {
    e.preventDefault();
    window.open(PARTNER_LOGIN_URL);
  };

  const redirectLogin = (e) => {
    e.preventDefault();
    navigate("/account/signin");
  };
  return (
    <section className="loginRoute">
      <div className="loginRoute-frame">
        <div className="frame-title">
          <h2>로그인 회원 구분 선택</h2>
        </div>
        <div className="member-type">
          <div className="corporate-member-box">
            <h3>기업회원</h3>
            <span>
              <img src={corporate} alt="기업"></img>
            </span>
            <button onClick={redirectPartnerLogin}>기업회원 로그인</button>
          </div>
          <div className="general-member-box">
            <h3>일반회원</h3>
            <span>
              <img src={general} alt="기업"></img>
            </span>
            <button onClick={redirectLogin}>일반회원 로그인</button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SigninRoute;
