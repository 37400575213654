import { isKiikeyApp, IS_ANDROID, IS_IOS } from "App-config";
import { Link, animateScroll } from "react-scroll";
import { setShopScrap } from "service/OrderService";

import "components/order/style/FollowBasket.css";
import { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const FollowBasketMobile = ({
  isLike,
  setIsLike,
  shopInfo,
  menuList,
  basketClickEvent,
  basketCount,
  navNumber,
  navState,
}) => {
  const shopScrapClickEvent = () => {
    setShopScrap(shopInfo.s_idx)
      .then((res) => {
        if (res.success) {
          setIsLike(res.data > 0);
        }
      })
      .catch(console.error);
  };

  const navigate = useNavigate();

  const NaviList = () => {
    const state = useState();

    //ref.current.scrollIntoView({behavior: 'smooth'});
    useEffect(() => {
      //Console.log('스크롤 초기화 x:' +navState.navScroll);
      //document.getElementById('navScroll')?.scrollTo(navState.navScroll,0);
      document.getElementById("navScroll")?.scrollTo({
        left: navState.navScroll,
        //behavior:'smooth',
      });

      //animateScroll.scrollTo(navState.navScroll,{containerId : "navScroll" ,horizontal:true});
    }, []);

    const liTagList = menuList.map((item, index) => {
      //console.log(navNumber, " :: ", item.mc_idx )
      return (
        <li
          id={"navBtn" + item.mc_idx}
          style={{
            backgroundColor:
              navState.navNumber === item.mc_idx && "var(--kk-color-Ymain)",
          }}
        >
          <Link
            to={item.mc_idx}
            spy={true}
            smooth={true}
            offset={isKiikeyApp ? -100 : -80}
          >
            {item.mc_name}
          </Link>
        </li>
      );
    });
    return (
      <ul className="shopping-menu-nav-mobile" id="navScroll">
        {liTagList}
      </ul>
    );
  };

  return (
    <></>
  );
};

export default FollowBasketMobile;
