import { useRef } from "react";

import TimerComponent from "components/common/TimerComponenet";

const AuthInputComponent = ({
  isView,
  setAuthInputView,
  phoneInputDisabled,
}) => {
  const authInputRef = useRef();

  const authClickHandler = (e) => {
    if (e) {
      e.preventDefault();
    }
    console.log(authInputRef.current.value);
    window.confirmationResult
      .confirm(authInputRef.current.value)
      .then(() => {
        alert("인증성공");
        phoneInputDisabled(true);
        setAuthInputView(false);
      })
      .catch((err) => {
        console.error(err);
        alert("인증실패");
      });
  };

  if (isView) {
    return (
      <tr>
        <td>
          <TimerComponent
            isView
            callBackFnc={() => {
              console.log("타이머종료");
              phoneInputDisabled(false);
              setAuthInputView(false);
            }}
          />
        </td>
        <td className="small-td">
          <input
            id=""
            type="text"
            placeholder="SMS 인증번호를 입력해주세요."
            className="join-input-style"
            ref={authInputRef}
          />
        </td>
        <td>
          <button
            onClick={authClickHandler}
            className="join-button-large-size join-btn-style createbutton"
          >
            확인
          </button>
        </td>
      </tr>
    );
  }
  return "";
};

export default AuthInputComponent;
