import { useNavigate } from "react-router-dom";

import { USER_DTO } from "App-config";
import { requestGameAnswer } from "service/ADSService";
import answer_img from "src_assets/answer_img.png";
import answer_imgs from "src_assets/answer_imgs.png";
import sad_img from "src_assets/sad_img.png";
import { SHOP_CUSTOM_IMG_PATH } from "App-config";
import game_title from "src_assets/hiddencatch_game_title.png";
import close_btn from "src_assets/close_btn.png";

import "components/main/style/GamePlayModal.css";
import { useEffect } from "react";

function GamePlayModal({
  data,
  setUser,
  closeGamePopupEvent,
  isMobile,
  requestAnswerEvent,
  isApp,
  scriptHandler,
}) {
  const navigate = useNavigate();
  const HCG_IDX = data.g_idx;

  var answer_res = false;
  var gameOver = false;
  var id;

  var clickEvent = true;

  var pc_width = 460;
  var pc_height = 576;
  var mobile_width = 310;
  var mobile_height = 424;
  document.documentElement.scrollWidth <= 1024
    ? (isMobile = true)
    : (isMobile = false);

  // var keys;
  // var values;
  var positionArray = JSON.parse("[" + data.xy_answer + "]");
  var numbersToGo = positionArray.length;

  if (isMobile) {
    var positionArray2 = [];
    for (var k = 0; k < positionArray.length; k++) {
      var mobile_x =
        (mobile_width * parseInt(positionArray[k].split(",")[0])) / pc_width;
      var mobile_y =
        (mobile_height * parseInt(positionArray[k].split(",")[1])) / pc_height;
      var mobile_xy = mobile_x + "," + mobile_y;
      positionArray2.push(mobile_xy);
    }
    positionArray = positionArray2;
  }

  const checkAnswerEvent = (e) => {
    requestGameAnswer({ g_idx: HCG_IDX, answer: answer_res })
      .then((res) => {
        if (res.isDuplicate) {
          alert("포인트가 이미 지급된 게임입니다.");
        }
        requestAnswerEvent(res, answer_res);
      })
      .catch((err) => {
        alert("다시 로그인해주세요.");
        sessionStorage.clear();
        setUser(USER_DTO);
        navigate("/login");
      });
  };

  const playGame = (event) => {
    var delay = 600;
    const myCanvas = document.getElementById("box2");
    const myCanvasGame = document.getElementById("box3");

    var numbersToGoInput = document.getElementById("numbersToGo");
    var finishMessage = document.getElementById("finishMessage");

    var rect = myCanvas.getBoundingClientRect();
    var rectGame = myCanvasGame.getBoundingClientRect();

    var x = event.clientX - rect.left;
    var y = event.clientY - rect.top;

    var x_game = event.clientX - rectGame.left;
    var y_game = event.clientY - rectGame.top;

    var answer;
    var a_res = false;
    var res;
    var check = false;

    var answer_radius = 12.5;
    if (clickEvent) {
      for (var i = 0; i < positionArray.length; i++) {
        if (
          (window["isDone" + i] == false &&
            x > parseFloat(positionArray[i].split(",")[0]) - answer_radius &&
            x < parseFloat(positionArray[i].split(",")[0]) + answer_radius &&
            y > parseFloat(positionArray[i].split(",")[1]) - answer_radius &&
            y < parseFloat(positionArray[i].split(",")[1]) + answer_radius) ||
          (x_game >
            parseFloat(positionArray[i].split(",")[0]) - answer_radius &&
            x_game <
              parseFloat(positionArray[i].split(",")[0]) + answer_radius &&
            y_game >
              parseFloat(positionArray[i].split(",")[1]) - answer_radius &&
            y_game < parseFloat(positionArray[i].split(",")[1]) + answer_radius)
        ) {
          answer = i + 1;

          // x = positionArray[i].split(",")[0];
          // y = positionArray[i].split(",")[1];

          var fadeimg = document.querySelector(".image_fade");
          fadeimg.style.top = parseFloat(y) - 245 + "px";
          fadeimg.style.left = parseFloat(x) - 290 + "px";

          fadeimg.style.display = "";
          setTimeout(function () {
            fadeimg.style.display = "none";
          }, delay);

          window["isDone" + i] = true;
          numbersToGo = numbersToGo - 1;
          numbersToGoInput.innerHTML = numbersToGo;

          a_res = true;
        }
        check = true;
      }
      if (check) {
        if (a_res) {
          // 정답시 해당 좌표에 이미지로 표시
          setTimeout(() => {
            targetResData();
            clearTimeout();
          }, delay);
        } else {
          // 틀렸다는 img 표시
          var fadeimg = document.querySelector(".image_fade_sad");
          fadeimg.style.top = parseFloat(y) + 30 + "px";
          fadeimg.style.left = parseFloat(x) - 15 + "px";
          fadeimg.style.display = "";
          setTimeout(function () {
            fadeimg.style.display = "none";
            clearTimeout();
          }, delay);
        }
      }
      clickEvent = false;
      setTimeout(function () {
        clickEvent = true;
      }, delay);
    }

    function targetResData() {
      document.querySelectorAll(".answer_" + answer)[0].style.display = "";
      document.querySelectorAll(".answer_" + answer)[1].style.display = "";

      showFinished(numbersToGo);
    }

    function showFinished(num) {
      if (num == 0) {
        delay += delay;
        finishMessage.innerHTML = "모두 다 찾았습니다! 축하합니다! :)";
        gameOver = true;
        answer_res = true;
        clearInterval(id);
        checkAnswerEvent();
        setTimeout(() => {
          requestAnswerEvent(res, true);
        }, delay);
      }
    }
  };

  const closeWebView = (e) => {
    clearInterval(id);

    scriptHandler(e, "closeGame");
  };

  // 뒤로가기이벤트 동작시 게임시간 초기화후 모달 닫음
  window.onpopstate = function (e) {
    clearInterval(id);
    if (isApp) {
      scriptHandler(e, "closeGame");
    }
    closeGamePopupEvent("closeBtn", 0);
  };

  useEffect(() => {
    var pc_width = 460;
    var pc_height = 576;
    var mobile_width = 310;
    var mobile_height = 424;

    document.documentElement.scrollWidth <= 1024
      ? (isMobile = true)
      : (isMobile = false);

    clearInterval(id);
    var finishMessage = document.getElementById("finishMessage");
    var elem = document.getElementById("myBar");
    var width = 1;
    var progbar = 0;
    var timer = parseInt(data.g_play_time);

    var positionArray = JSON.parse("[" + data.xy_answer + "]");

    if (isMobile) {
      var positionArray2 = [];
      for (var k = 0; k < positionArray.length; k++) {
        var mobile_x =
          (mobile_width * parseInt(positionArray[k].split(",")[0])) / pc_width;
        var mobile_y =
          (mobile_height * parseInt(positionArray[k].split(",")[1])) /
          pc_height;
        var mobile_xy = mobile_x + "," + mobile_y;
        positionArray2.push(mobile_xy);
      }
      positionArray = positionArray2;
    }

    var b = "";
    var a = document.querySelector(".img_list");
    var aa = document.querySelector(".img_list2");
    for (var i = 0; i < positionArray.length; i++) {
      var cnt = i + 1;

      var y = parseFloat(positionArray[i].split(",")[1]);
      if (isMobile) {
        b +=
          '<img src="' +
          answer_imgs +
          '" class="image_fade_answer answer_' +
          cnt +
          '" style="top:' +
          y +
          "px;left:" +
          parseFloat(positionArray[i].split(",")[0]) +
          'px; display:none">';
      } else {
        b +=
          '<img src="' +
          answer_imgs +
          '" class="image_fade_answer answer_' +
          cnt +
          '" style="top:' +
          y +
          "px;left:" +
          parseFloat(positionArray[i].split(",")[0]) +
          'px; display:none">';
      }

      // b += '<img src="' + answer_imgs + '" class="image_fade_answer" style="top:' + 0 + "px;left:" + 0 + 'px; display:" id="' + (positionArray.length + cnt) + '">';
      window["isDone" + i] = false;
    }

    var numbersToGo = positionArray.length;
    var numbersToGoInput = document.getElementById("numbersToGo");
    numbersToGoInput.innerHTML = numbersToGo;
    a.innerHTML = b;
    aa.innerHTML = b;
    function frame() {
      if (width >= timer) {
        // 시간종료
        clearInterval(id);
        finishMessage.innerHTML = "아쉽지만 다음 기회에! :)";
        width = 0;
        progbar = 0;
        checkAnswerEvent();
        // requestAnswerEvent(res, false);
      } else {
        // 시간가는중
        width++;
        progbar = (100 / timer) * width;
        elem.style.width = progbar + "%";
      }
    }
    id = setInterval(frame, 1000);

    document.getElementById("box2").addEventListener("click", playGame);
    document.getElementById("box3").addEventListener("click", playGame);

    return () => {
      clearInterval(id);
    };
  }, []);

  const apiServer = SHOP_CUSTOM_IMG_PATH + "game/hiddenCatchGame/";

  const canvasOriStyle = {
    backgroundColor: "lightblue",
    backgroundImage: "url(" + apiServer + data.game_img_ori + ")",
    // 변경
    backgroundSize: "100% 100%",
  };

  const canvasStyle = {
    backgroundColor: "lightblue",
    backgroundImage: "url(" + apiServer + data.game_img + ")",
    // 변경
    backgroundSize: "100% 100%",
  };

  return (
    <div className="game-modal">
      {isApp ? (
        <div className="closeWebViewPlay">
          <img src={close_btn} onClick={closeWebView} />
        </div>
      ) : (
        ""
      )}
      <div className="game-play-head">
        <img src={game_title} />
      </div>
      <form className="game-play-form">
        <div className="game-play">
          <img
            src={answer_img}
            className="image_fade"
            style={{ display: "none" }}
          />
          <img
            src={sad_img}
            className="image_fade_sad"
            style={{ display: "none" }}
          />
          <div className="img_list"></div>
          <canvas id="box2" style={canvasOriStyle}></canvas>
          <div className="img_list2"></div>
          <canvas id="box3" style={canvasStyle}></canvas>

          <div style={{ width: "100%" }}>
            <p style={{ color: "#f38a0f", fontWeight: "bold" }}>
              남은개수&nbsp;
              <span id="numbersToGo"></span>개
            </p>
            <div id="description">
              <p
                id="finishMessage"
                style={{ color: "#f38a0f", fontWeight: "bold" }}
              ></p>
            </div>
            <div id="myProgress">
              <div id="myBar"></div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default GamePlayModal;
