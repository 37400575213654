import "components/mykiikey/style/Mykiikey.css";
import "components/mykiikey/style/ReviewWriting.css";
import "components/mykiikey/style/ReviewType.css";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const ReviewType = ({ reviewList, setReviewList }) => {
  const navigate = useNavigate();

  const [type, setType] = useState(1);

  const { state } = useLocation();

  const reviewTypeClickEvent = ({ od_idx, s_name, s_idx, c2_idx, c3_idx }) => {
    navigate("/mykiki/orderList/" + (type == 2 ? "reviewTypeProduct" : "review"), {
      state: { od_idx, s_name, s_idx, c2_idx, c3_idx, type },
    });
  };

  const reviewTypeChange = (e) => {
    setType(e.target.dataset.type);
  };
  
  return (
    <div className="daps-frame">
      <div class="daps-title-box">주문내역</div>
      <div className="review-type-frame">
        <div className="review-title"><p>리뷰타입선택</p></div>
        <div className="review-type-choice">
          <div className="review-type-radio">
            <input
              type="radio"
              className="review-type-radio-btn"
              id="shop-review"
              name="review-type"
              value="shop-review"
              data-type="1"
              checked={type == 1 ? true : false}
              onChange={reviewTypeChange}
            />
            <label htmlFor="shop-review" className="review-type-label">
              가게별 리뷰
            </label>
          </div>
          <div className="review-type-radio">
            <input
              type="radio"
              className="review-type-radio-btn"
              id="product-review"
              name="review-type"
              value="product-review"
              data-type="2"
              checked={type == 2 ? true : false}
              onChange={reviewTypeChange}
            />
            <label htmlFor="product-review" className="review-type-label">
              상품별 리뷰
            </label>
          </div>
        </div>
        <div className="review-write-btn">
          <button
            className="review-writing-btn"
            onClick={() =>
              reviewTypeClickEvent({
                od_idx: state.od_idx,
                s_name: state.s_name,
                s_idx: state.s_idx,
                c2_idx: state.c2_idx,
                c3_idx: state.c3_idx,
              })
            }
          >
            작성
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReviewType;
