import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import testimg from "src_assets/item_img.png";

import Pagination from "components/common/Pagination";
// import KakaoAddrPopup from "components/common/KakaoAddrPopup";
import {
  cancelOrder,
  deleteOrder,
  getOrderHistory,
  resetBuyBasket,
  getMemberInfo,
  updateOrderAddr,
} from "service/OrderService";

import "components/mykiikey/style/Orderlist.css";
import "components/mykiikey/style/Mykiikey.css";
import stateUtilityInstance from "../../TestClass";

import face from "src_assets/icon/face.png";
import twi from "src_assets/icon/twi.png";
import kakao from "src_assets/icon/kakao.png";
import you from "src_assets/icon/you.png";
import { NewLoading } from "components/common/NewLoading";

import { ReactComponent as CartIcon } from "src_assets/icon/icon_cart.svg";
import { ReactComponent as TruckIcon } from "src_assets/icon/icon_truck.svg";
import { serverErrorHandler, SHOP_CUSTOM_IMG_PATH,callCancel,createReviewList } from "App-config";
import { ReactComponent as PinDropIcon } from "src_assets/icon/pin_drop.svg";
import { getDelHistoryList } from "service/AccountService";

// 지호 220707 주문내역 ziho_ohl
// 2022-07-17 주문내역 가져오기 페이징처리, 로직변경
const OrderList = ({
  userInfo,
  setUserInfo,
  orderHistoryList,
  setOrderHistoryList,
  isMobile,
}) => {
  const navigate = useNavigate();

  const [share, setShare] = useState(null);
  const [isLoad, setIsLoad] = useState(false);

  const [filter, setFilter] = useState(0);

  const VIEW_LIMIT = 6;
  const [currentPage, setCurrentPage] = useState(1);

  const offset = (currentPage - 1) * VIEW_LIMIT;

  const [orderHistoryListLimit, setOrderHistoryListLimit] = useState([]);
  const [modalOpen, setModalOpen] = useState(-1);
  const [mbAddr, setMbAddr] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    setCurrentPage(1);
    let output = isMobile
      ? orderHistoryList.filter((x) => filter == 0 || x.od_state == filter)
      : //.slice(0, offset + VIEW_LIMIT)
        orderHistoryList.slice(offset, offset + VIEW_LIMIT);

    setOrderHistoryListLimit(output);
  }, [filter, orderHistoryList]);
  const ReviewTime = ({ isState, isReviewSuc, review_time }) => {
    if (isReviewSuc) {
      return (
        <>
          {isState && (
            <p>리뷰 쓰기는 주문 완료 이후 3개월 동안만 가능합니다.</p>
          )}
          <p className="review-date">{review_time}</p>
        </>
      );
    } else {
      return (
        <>
          {isState && <p>리뷰쓰기 기간이 지났습니다.</p>}
          <p className="review-date">{review_time}</p>
        </>
      );
    }
  };

  const searchOrderByKeyword = (keyword) => {
    let output = [];
    if (!keyword) {
      output = orderHistoryList.filter(
        (x) => filter == 0 || x.od_state == filter
      );
      setOrderHistoryListLimit(output);
      return;
    }
    const check = (data) => {
      const menus = data.detail.rows;
      for (const menu of menus) {
        if (menu.m_name)
          if (menu.m_name.includes(keyword)) {
            return true;
          }
      }

      return false;
    };
    output = orderHistoryListLimit.filter((x) => check(x));

    setOrderHistoryListLimit(output);
  };

  const orderShareClickEvent = (data) => {
    setShare(data);
  };

  const orderDetailClickEvent = (od_idx) => {
    navigate("/mykiki/orderList/" + od_idx);
  };

  const orderReviewClickEvent = ({ od_idx, s_name, s_idx, c2_idx, c3_idx }) => {
   // navigate("/mykiki/orderList/" + (c2_idx == "1" ? "review" : "review"), {
   //   state: { od_idx, s_name, s_idx, c2_idx, c3_idx },
   // });
    createReviewList(od_idx,s_idx);
  };
  const orderChangeClickEvent = ({ od_Date }) => {
    const currentDate = new Date();
    const orderDate = new Date(od_Date);
    const timeDifference = currentDate.getTime() - orderDate.getTime();
    const daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24));

    if (daysDifference > 30) {
      alert(
        '교환, 환불 가능 기간이 지났습니다.\n\n교환 환불은 배송완료 후 30일 이내에 \n가능합니다.'
      );
    }else{
      callCancel();
    }
  };
  const handleClick = (od_idx) => {
    //calllDell(true);
    setModalOpen(od_idx);
  };
  const OrderState = ({ orderHistory }) => {
    let orderStat = "결제전";
    if (orderHistory.od_state == 1) {
      orderStat = "결제완료";
    } else if (orderHistory.od_state == 2) {
      orderStat = "배송준비";
    } else if (orderHistory.od_state == 3) {
      orderStat = "배송중";
    } else if (orderHistory.od_state == 4) {
      orderStat = "배송완료";
    } else if (orderHistory.od_state < 0) {
      orderStat = "취소완료";
    }

    if (
      (orderHistory.s_idx == 10228 && orderHistory.od_state == 1) ||
      (orderHistory.s_idx == 10228 && orderHistory.od_state == 2) ||
      (orderHistory.s_idx == 10228 && orderHistory.od_state == 3)
    ) {
      orderStat = "구매완료";
    } else if (orderHistory.s_idx == 10228 && orderHistory.od_state == 4) {
      orderStat = "사용완료";
    }

    return <span className="order-state">{orderStat}</span>;
  };

  const orderHistoryDeleteClickEvent = (orderHistory) => {
    deleteOrder({ od_idx: orderHistory.od_idx })
      .then((res) => {
        if (res.success) {
          let newOrderHistory = [...orderHistoryList];
          for (let index = 0; index < newOrderHistory.length; index++) {
            const element = newOrderHistory[index];
            if (orderHistory.od_idx === element.od_idx) {
              newOrderHistory.splice(index, 1);
              break;
            }
          }
          setOrderHistoryList(newOrderHistory);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const orderCnacelClickEvent = (od_idx) => {
    navigate("/mykiki/orderList/cancel/" + od_idx);
    return;
    cancelOrder({ od_idx_list: [od_idx] })
      .then((res) => {
        if (res.success) {
          let newOrderHistory = [...orderHistoryList];
          for (let index = 0; index < newOrderHistory.length; index++) {
            const element = newOrderHistory[index];
            if (od_idx === element.od_idx) {
              let cancelOrder = newOrderHistory.splice(index, 1);
              cancelOrder[0].od_state = -1;
              cancelOrder[0].od_cancel_type = 1;
              newOrderHistory.splice(index, 0, cancelOrder[0]);
              setOrderHistoryList(newOrderHistory);
              break;
            }
          }
          let newUserInfo = { ...userInfo };
          newUserInfo.mb_point = newUserInfo.mb_point + res.data * 1;
          setUserInfo(newUserInfo);
        }
        alert("취소 신청이 완료되었습니다. 환불포인트:" + res.data + "점");
      })
      .catch((err) => {
        alert(err.msg);
        console.error(err);
      });
  };
  const resetBuy = (od_idx) => {
  
    const state = {
      od_idx: od_idx,
    };

    resetBuyBasket(state)
      .then((res) => {
        if (res.success) {
          navigate("/order/repayment?od_idx=" + od_idx);
         // navigate("/order/payment");
        } else {
        }
      })
      .catch((err) => {
        alert(err.msg);
        console.error(err);
      });
  };
  const liTagList = orderHistoryListLimit.map((orderHistory, index) => {
    // const order_day = new Date(orderHistory.od_datetime);
    const order_day = new Date(orderHistory.od_datetime.replace(" ", "T"));

    // 259200000 : unix timestamp millisecond 3day

    //const isReviewSuc = order_day.getTime() + 259200000 > Date.now();
    const now = new Date();
    const threeMonthsFromNow = new Date(now.setMonth(now.getMonth() + 3));
    const unixTimestampMilliseconds = threeMonthsFromNow.getTime();
    const isReviewSuc =
      order_day.getTime() + unixTimestampMilliseconds > Date.now();

    const toDateString = (date) => {
      const year = date.getFullYear(); // 2023
      const month = (date.getMonth() + 1).toString().padStart(2, "0"); // 06
      const day = date.getDate().toString().padStart(2, "0"); // 18

      return `${year}.${month}.${day}`;
    };

    const getTitle = () => {
      const menus = orderHistory.detail.rows;
      let title = "";

      let newArr = [];
      for (const menu of menus) {
        if (menu.m_name) {
          newArr.push(menu.m_name);
        }
      }

      let etc = newArr.length - 1;

      return newArr.length
        ? `${newArr[0]}${etc > 0 ? ` 외 ${etc}건` : ""}`
        : "";
    };

    const getImgSrc = () => {
      const menus = orderHistory.detail.rows;
      for (const menu of menus) {
        if (menu.m_img) {
          return (
            SHOP_CUSTOM_IMG_PATH +
            menu.table_name.replace("_", "") +
            "/menu/" +
            menu.m_img
          );
        }
      }

      return "";
    };

    return (
      <li key={index}>
        <div className="order-list-head-time">
          <div style={{ fontWeight: "700" }}>
            {toDateString(new Date(orderHistory.od_datetime))}
          </div>
          <div
            style={{
              color: "#0085FF",
            }}
            onClick={() => orderDetailClickEvent(orderHistory.od_idx)}
          >
            상세보기
          </div>
        </div>
        <div className="order-list-info">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              style={{
                fontSize: "18px",
                fontWeight: "700",
              }}
            >
              <OrderState orderHistory={orderHistory} />
            </div>
            <div style={{ display: "none" }}></div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "1em",
            }}
          >
            <div
              style={{
                width: "100px",
                height: "100px",
                borderRadius: "8px",
                border: "1px solid #BDBDBD",
                overflow: "hidden",
              }}
            >
              <img
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
                src={getImgSrc()}
              />
            </div>

            <div
              style={{
                width: 0,
                flexGrow: 1,
              }}
            >
              <div>{getTitle()}</div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <span>
                    {(
                      parseInt(orderHistory.sp_product_amt) -
                      parseInt(orderHistory.od_delivery_tip)
                    )
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    원
                  </span>
                  {/* <span>/</span>
                  <span>1개</span> */}
                </div>
                <div>
                  <CartIcon />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div>판매자문의 {">"}</div>
                <div>
                  <TruckIcon />
                </div>
              </div>
            </div>
          </div>

          <div className="order-history-item-btn-container">
            {(orderHistory.od_state == 0 ||
              orderHistory.od_state == 1 ||
              orderHistory.od_state == 2) && (
              <>
                <div onClick={() => orderCnacelClickEvent(orderHistory.od_idx)}>
                  주문취소
                </div>
                <div onClick={() => handleClick(orderHistory.od_idx)}>
                  배송지변경
                </div>
              </>
            )}

            {orderHistory.od_state == 3 && (
              <>
                <div>반품접수취소</div>
              </>
            )}

            {orderHistory.od_state == 4 && (
              <>
                <div
                onClick={() =>
                  orderChangeClickEvent({
                    od_Date: toDateString(new Date(orderHistory.od_datetime)),                    
                  })
                }
                >교환반품신청</div>
                <div
                  onClick={() =>
                    orderReviewClickEvent({
                      od_idx: orderHistory.od_idx,
                      s_name: orderHistory.s_name,
                      s_idx: orderHistory.s_idx,
                      c2_idx: orderHistory.c2_idx,
                      c3_idx: orderHistory.c3_idx,
                    })
                  }
                >
                  마이리뷰쓰기
                </div>
              </>
            )}

            {orderHistory.od_state == -1 && (
              <>
                <div
                  onClick={(e) => {
                    navigate(
                      "/mykiki/orderList/cancel/detail/" + orderHistory.od_idx
                    );
                  }}
                >
                  취소상세
                </div>
                <div
                  onClick={(e) => {
                    resetBuy(orderHistory.od_idx);
                  }}
                >
                  재구매
                </div>
              </>
            )}
          </div>
        </div>

        <div style={{ display: "none" }}>
          <div className="order-list-head">
            <div className="order-list-state">
              <OrderState orderHistory={orderHistory} />
              <span className="shop-name">{orderHistory.sp_product_nm}</span>
            </div>
            {(orderHistory.od_state > 1 || orderHistory.od_state < 0) && (
              <button
                className="order-list-delete"
                style={{ display: "none" }}
                onClick={() => {
                  orderHistoryDeleteClickEvent(orderHistory);
                }}
              >
                X
              </button>
            )}
          </div>
          <span className="order-info">
            {/* 현재 시간과 비교 */}
            <ReviewTime
              isState={
                orderHistory.od_state > 0 && orderHistory.od_option < 6
                  ? true
                  : false
              }
              isReviewSuc={isReviewSuc}
              review_time={orderHistory.od_datetime}
            />
          </span>
          {orderHistory.c2_idx == 20 && orderHistory.od_state == 2 && (
            <>
              <span style={{ fontSize: 12, fontWeight: 500, color: "red" }}>
                환불요청은 고객센터로 문의해주세요.
              </span>
              {/* <span style={{ fontSize: 13, fontWeight: 500 }}>
              ※ 구매내역중 하나라도 사용시 환불 불가합니다.
            </span> */}
            </>
          )}

          <div className="order-list-function">
            <span className="order-list-buttons">
              {orderHistory.od_state > 3 && (
                <button
                  style={{ display: "none" }}
                  className="share-button"
                  onClick={() => {
                    orderShareClickEvent(orderHistory);
                    // setShare(orderHistory);
                  }}
                >
                  공유하기
                </button>
              )}
              <button
                className="orderdetail-button"
                onClick={() => orderDetailClickEvent(orderHistory.od_idx)}
              >
                주문상세
              </button>
              {orderHistory.c2_idx != 20 && orderHistory.od_state == 1 && (
                <button
                  className="order-cancle-button"
                  onClick={() => orderCnacelClickEvent(orderHistory.od_idx)}
                >
                  주문취소
                </button>
              )}
              {orderHistory.c2_idx == 20 && orderHistory.od_state == 1 && (
                <button
                  className="order-cancle-button"
                  onClick={() => orderCnacelClickEvent(orderHistory.od_idx)}
                >
                  주문취소
                </button>
              )}
              {isReviewSuc &&
                orderHistory.od_option < 6 &&
                orderHistory.od_write_review < 1 &&
                orderHistory.od_state > 3 && (
                  <button
                    className="review-button"
                    onClick={() =>
                      orderReviewClickEvent({
                        od_idx: orderHistory.od_idx,
                        s_name: orderHistory.s_name,
                        s_idx: orderHistory.s_idx,
                        c2_idx: orderHistory.c2_idx,
                        c3_idx: orderHistory.c3_idx,
                      })
                    }
                  >
                    리뷰쓰기
                  </button>
                )}
            </span>
          </div>
        </div>
      </li>
    );
  });

  useEffect(() => {
    stateUtilityInstance.setMobileHeaderTitle("주문내역", true);
    getOrderHistory()
      .then((res) => {
        if (res.success) {
          setIsLoad(true);
          setOrderHistoryList(res.data);
        }
      })
      .catch((err) => {});
    getMemberInfo()
      .then((res) => {
        setUserInfo(res);

        var split = res.mb_addr.split("/");

        var addrArray = [];
        addrArray.push(split[0]?.trim() || "");
        addrArray.push(split[1]?.trim() || "");
        addrArray.push(split[2]?.trim() || res.mb_name);
        addrArray.push(split[3]?.trim() || res.mb_id);
        addrArray.push(split[4]?.trim() || res.mb_phone);

        setMbAddr(addrArray);

        //setMbAddr(res.mb_addr);
      })
      .catch((err) => {
        console.error("payment: err :: ", err);
        //에러처리필요
        serverErrorHandler(err);
      });
  }, []);

  const ShareModal = () => {
    return (
      <div className="share-modal">
        <button className="share-delete-btn" onClick={() => setShare(null)} />
        <ul>
          <li>
            <a href="https://www.facebook.com/campaign/landing.php?&campaign_id=1662308814&extra_1=s%7Cc%7C514638008096%7Ce%7C%ED%8E%98%EC%9D%B4%EC%8A%A4%20%EB%B6%81%7C&placement=&creative=514638008096&keyword=%ED%8E%98%EC%9D%B4%EC%8A%A4%20%EB%B6%81&partner_id=googlesem&extra_2=campaignid%3D1662308814%26adgroupid%3D69637362208%26matchtype%3De%26network%3Dg%26source%3Dnotmobile%26search_or_content%3Ds%26device%3Dc%26devicemodel%3D%26adposition%3D%26target%3D%26targetid%3Dkwd-303215285480%26loc_physical_ms%3D1009879%26loc_interest_ms%3D%26feeditemid%3D%26param1%3D%26param2%3D&gclid=Cj0KCQjwlemWBhDUARIsAFp1rLW6TYl0AW3w-nKnpvC2df5zT9IZCUUjwNR6jQRFI6Yq6K0cfPHIEZgaAo-oEALw_wcB">
              <img src={face} alt="페이스북"></img>
              <p>Facebook</p>
            </a>
          </li>
          <li>
            <a href="https://twitter.com/?lang=ko">
              <img src={twi} alt="트위터"></img>
              <p>Twitter</p>
            </a>
          </li>
          <li>
            <a href="https://www.kakaocorp.com/page/service/service/KakaoTalk">
              <img src={kakao} alt="카카오"></img>
              <p>Kakao</p>
            </a>
          </li>
          <li>
            <a href="#">
              <img src={you} alt="유튜브"></img>
              <p>Youtube</p>
            </a>
          </li>
        </ul>
        <div className="url-box">
          <span className="url">{window.location.href}</span>
          <button className="url-copy-button">복사</button>
        </div>
      </div>
    );
  };

  const resultView = (
    <>
      {share && <ShareModal />}
      <div className="order-list-seach-container">
        <form
          className="order-search-box-wrap"
          style={{ height: "fit-content" }}
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();

            const target = e.currentTarget;
            let input = target.querySelector("input");
            searchOrderByKeyword(input.value);
          }}
        >
          <div className="order-search-box">
            <input
              id="order-search"
              placeholder="주문한 상품을 검색해 보세요"
            />
          </div>
          <button
            type="submit"
            className="order-search-button"
            onClick={(e) => {
              /* if (isMobile) {
              navigate("/order/ShopSearch", {
                state: {
                  searchKeyList: searchKeyList,
                },
              });
            }

            setIsFocus(true); */
            }}
          />
        </form>

        <form className="order-list-search-filter">
          <label style={{ width: "50%" }}>
            <input
              type="radio"
              name="filter"
              checked={filter == 0}
              onChange={(e) => {
                if (e.currentTarget.checked) setFilter(0);
              }}
            />
            <span>전체</span>
          </label>

          <label style={{ width: "50%" }}>
            <input
              type="radio"
              name="filter"
              checked={filter == -1}
              onChange={(e) => {
                if (e.currentTarget.checked) setFilter(-1);
              }}
            />
            <span>취소내역</span>
          </label>

       
        </form>
      </div>
      <ul
        className="daps-ul-list"
        style={{
          height: 0,
          flexGrow: 1,
          overflow: "auto",
        }}
      >
        {orderHistoryListLimit && orderHistoryListLimit.length ? (
          <>
            {/* 지호 220707 주문내역 데이터 로딩 */}
            {liTagList}

            {/* <Pagination
            isMobile={isMobile}
            limit={VIEW_LIMIT}
            offset={offset}
            type={"orderHistory"}
            totalCount={orderHistoryList.length}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          /> */}
          </>
        ) : (
          <div
            className="daps-ul-empty"
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "10%",
              boxSizing: "border-box",
            }}
          >
            <p>주문내역이 없습니다.</p>
          </div>
        )}

        <div
          style={{
            color: "#666",
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "1.5",
            padding: "1em 0",
            borderTop: "2px solid #BDBDBD",
          }}
        >
          <p>
            이쁨몰은 통신판매업 중개자이며, 통신판매의 당사자가 아닙니다.
            따라서, 상품/거래정보 및 가격에 대하여 책임을 지지 않습니다.
          </p>
        </div>
      </ul>

      {/* <button className="info-chagne-confirm">저장</button> */}
      {modalOpen >= 0 && (
        <DelHistoryModal
          userInfo={userInfo}
          setModalOpen={setModalOpen}
          setMbAddr={setMbAddr}
          odIdx={modalOpen}
        />
      )}
    </>
  );

  return (
    <div
      className="daps-frame"
      style={{ padding: "0 1.5em", display: "flex", flexDirection: "column" }}
    >
      {isLoad ? resultView : <NewLoading tabState={0} index={0} />}
    </div>
  );
};

const DelHistoryModal = ({ userInfo, setModalOpen, setMbAddr, odIdx }) => {
  const [delHistoryList, setDelHistoryList] = useState([]);
  useEffect(() => {
    if (!userInfo || !userInfo.mb_id) return;

    const formData = new FormData();
    formData.append("action", "getDelHistoryList");
    formData.append("mb_id", userInfo.mb_id);

    //{ action: "getDelHistoryList", mb_id: userInfo.mb_id }
    getDelHistoryList(formData)
      .then((res) => {
        //console.log(res);
        setDelHistoryList(res.rows);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [userInfo]);

  return (
    <div
      style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: 9999,
        width: "100dvw",
        height: "100vh",
        background: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      onClick={() => {
        setModalOpen(-1);
      }}
    >
      <div
        style={{
          width: "80%",
          height: "80%",
          background: "#fff",
          padding: "1em",
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          gap: "1em",
        }}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        {userInfo.mb_addr && (
          <DelHistoryItem
            nick={"집"}
            name={userInfo.mb_name}
            phone={userInfo.mb_phone}
            mb_id={userInfo.mb_id}
            addr={userInfo.mb_addr}
            setMbAddr={setMbAddr}
            setModalOpen={setModalOpen}
            odIdx={odIdx}
          />
        )}

        {delHistoryList && delHistoryList.length ? (
          delHistoryList.map((del) => {
            return (
              <DelHistoryItem
                nick={del.nick}
                name={del.name}
                phone={del.phone}
                mb_id={del.mb_id}
                addr={del.ah_content}
                setMbAddr={setMbAddr}
                setModalOpen={setModalOpen}
                odIdx={odIdx}
              />
            );
          })
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

const DelHistoryItem = ({
  nick,
  name,
  phone,
  mb_id,
  addr,
  setMbAddr,
  setModalOpen,
  odIdx,
}) => {
  return (
    <div
      onClick={(e) => {
        var addr_split = addr.split("/");
        var output = [];
        output.push(addr_split[0]?.trim() || "");
        output.push(addr_split[1]?.trim() || "");
        output.push(name || "");
        output.push(mb_id || "");
        output.push(phone || "");
        output.push(nick || "");

        updateOrderAddr({ od_idx: odIdx, addr: output.join("/") })
          .then(() => {
            alert("배송지 변경이 완료되었습니다.");
          })
          .catch(() => {
            alert("배송지 변경에 실패하였습니다.");
          })
          .finally(() => {
            setMbAddr(output);
            setModalOpen(-1);
          });
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "1em",
          border: "1px solid #E0E0E0",
          borderRadius: "8px",
          boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)",
          padding: "1em",
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: "500",
          lineHeight: "1.5",
          fontSize: "15px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "0.5em",
            }}
          >
            <PinDropIcon />
            <span style={{ paddingTop: "0.5em" }}>{nick}</span>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "0.2em",
          }}
        >
          <div style={{ display: "flex", alignItems: "center", gap: "1em" }}>
            <div style={{ width: "4em" }}>{name}</div>
            <div>{phone}</div>
          </div>
          <div>{addr}</div>
        </div>
      </div>
    </div>
  );
};

export default OrderList;
