import profileimg from "src_assets/profile.png";
import myreview from "src_assets/myreview.png";
import myscrap from "src_assets/myscrap.png";
import mypoint from "src_assets/mypoint.png";
import "components/mykiikey/style/Mykiikey.css";
import "components/mykiikey/style/Mykiikeysetting.css";
import { useNavigate } from "react-router-dom";

const Mykiikeysetting = ({ myName, myPoint, reviewCnt, scrapCnt }) => {
  const navigate = useNavigate();

  return (
    <div className="daps-frame">
      <div onClick={() => navigate("/mykiki/privacy")} className="profile-area">
        <div className="photo">
          <img src={profileimg} alt="프로필이미지"></img>
        </div>
        <div className="myname">
          <p>안녕하세요!</p>
          <p>
            <b>{myName}</b>님
          </p>
        </div>
        <button className="profile-setting">내 정보 수정</button>
      </div>
      <ul className="my-list">
        <li onClick={() => navigate("/mykiki/pointuseHistory")}>
          <span className="my-list-title">
            <img src={mypoint} alt="코인이미지"></img>
            <p>내 포인트</p>
          </span>
          <span className="my-list-result">
            <p>{myPoint}</p>
            <p></p>
          </span>
        </li>
        <li onClick={() => navigate("/mykiki/reviewList")}>
          <span className="my-list-title">
            <img src={myreview} alt="코인이미지"></img>
            <p>내 리뷰</p>
          </span>
          <span className="my-list-result">
            <p>{reviewCnt}</p>
            <p>건</p>
          </span>
        </li>
        <li onClick={() => navigate("/mykiki/adscrap")}>
          <span className="my-list-title">
            <img src={myscrap} alt="코인이미지"></img>
            <p>내 스크랩</p>
          </span>
          <span className="my-list-result">
            <p>{scrapCnt}</p>
            <p>건</p>
          </span>
        </li>
        <li onClick={() => navigate("/mykiki/eventApply")}>
          <span className="my-list-title">
            <img src={myscrap} alt="코인이미지"></img>
            <p>이벤트</p>
          </span>
          <span className="my-list-result">
            <button>EVENT</button>
          </span>
        </li>
      </ul>
    </div>
  );
};

export default Mykiikeysetting;
