import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getOrderDetail } from "service/OrderService";
import { SHOP_CUSTOM_IMG_PATH } from "App-config";
import stateUtilityInstance from "TestClass";

import { ReactComponent as CartIcon } from "src_assets/icon/icon_cart.svg";

const OrderCancelDetail = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [orderDetail, setOrderDetail] = useState(null);

  useEffect(() => {
    stateUtilityInstance.setMobileHeaderTitle("취소상세", true);
    if (params.odIdx) {
      getOrderDetail({ od_idx: params.odIdx })
        .then((res) => {
          if (!!res.data) {
            if (parseInt(res.data.order.od_state) >= 0) {
              alert("잘못된 접근입니다.");
              navigate("/mykiki/orderList", { replace: true });
            }

            setOrderDetail(res.data);
          }
        })
        .catch((err) => {
          console.error(err);
          navigate("/mykiki/orderList");
        });
    }
  }, []);

  const OrderMenu = ({ order, order_menu }) => {
    let newArr = [];
    let currentIndex = 0;
    order_menu.forEach((menu, index) => {
      if (menu.m_name) {
        if (index > 0) {
          currentIndex++;
        }
        newArr.push({
          m_name: menu.m_name,
          m_price: menu.m_price * 1,
          c_cnt: menu.c_cnt,
          m_img: menu.m_img,
          table_name: menu.table_name,
          options: [],
        });
      } else if (menu.moc_name) {
        // newArr[currentIndex].options.push({
        //   c_cnt: menu.c_cnt,
        //   moc_name: menu.moc_name,
        // });
      } else if (menu.mo_name) {
        // newArr[currentIndex].m_price =
        //  newArr[currentIndex].m_price + menu.mo_price * 1;
        // newArr[currentIndex].options.push({
        //   c_cnt: menu.c_cnt,
        // mo_name: menu.mo_name,
        // mo_price: menu.mo_price * 1,
        //});
      }
    });

    return (
      <>
        {newArr.map((menu, index) => {
          return (
            <div style={menuItemContainerStyle}>
              <div
                style={{
                  width: "100px",
                  height: "100px",
                  borderRadius: "8px",
                  border: "1px solid #BDBDBD",
                  overflow: "hidden",
                }}
              >
                <img
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                  src={
                    SHOP_CUSTOM_IMG_PATH +
                    menu?.table_name?.replace("_", "") +
                    "/menu/" +
                    menu?.m_img
                  }
                />
              </div>

              <div
                style={{
                  fontSize: "15px",
                  width: 0,
                  flexGrow: 1,
                  padding: "0.3em 0",
                }}
              >
                <div>{menu.m_name}</div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "0.5em",
                  }}
                >
                  <span>
                    {menu.m_price
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    원
                  </span>
                  <span>/</span>
                  <span>{menu.c_cnt}개</span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: "0.5em",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "0.5em",
                    }}
                  >
                    <div>배송비</div>
                    <div>
                      {orderDetail.order.od_delivery_tip > 0
                        ? orderDetail.order.od_delivery_tip
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "원"
                        : "무료배송"}
                    </div>
                  </div>
                  <CartIcon />
                </div>
              </div>
            </div>
          );
        })}
      </>
    );
  };

  const getCancelTypeString = (state) => {
    switch (parseInt(state)) {
      case 1:
        return "단순변심";
      case 2:
        return "주문실수";
      case 3:
        return "배송지연";
      case 4:
        return "서비스 불만족";
      default:
        return "";
    }
  };

  if (!orderDetail) return <></>;

  return (
    <div style={mainContainerStyle}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          borderRadius: "10px",
          background: "#EBEBEB",
          padding: "1.2em 1em 1em",
          fontSize: "15px",
          fontWeight: "700",
        }}
      >
        취소 완료 후에는 철회가 불가합니다.
        <br /> 다시 구매를 원하시면, 주문내역 페이지에서 재구매를 눌러주세요
      </div>

      <div style={menuListContainerStyle}>
        <OrderMenu order={orderDetail.order} order_menu={orderDetail.rows} />
      </div>

      <div style={lineStyle}></div>

      <div
        style={{
          display: "grid",
          gridTemplateColumns: "4em 1fr",
          alignItems: "center",
          columnGap: "1em",
          marginTop: "1em",
        }}
      >
        <div style={{ fontWeight: "700" }}>취소사유</div>
        <div style={{ fontSize: "13px" }}>
          {getCancelTypeString(orderDetail.order.od_cancel_type)}
        </div>

        <div style={{ fontWeight: "700" }}>3줄사유</div>
        <div style={{ fontSize: "13px" }}>
          {orderDetail.order.od_cancel_content || "-"}
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "8px",
          background: "#BDBDBD",
          boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)",
          color: "#FFF",
          padding: "0.6em 1em 0.4em",
        }}
        onClick={() => {
          navigate(-1);
        }}
      >
        확인
      </div>
    </div>
  );
};

const mainContainerStyle = {
  width: "100%",
  height: "100%",
  overflow: "auto",
  padding: "2em 1em",
  boxSizing: "border-box",

  display: "flex",
  flexDirection: "column",
  gap: "1em",

  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: "500",
  lineHeight: "1.5",
  fontSize: "15px",
  color: "#000",
};

const menuListContainerStyle = {
  display: "flex",
  flexDirection: "column",
  gap: "1em",
};

const menuItemContainerStyle = {
  display: "flex",
  flexDirection: "row",
  gap: "1em",
};

const lineStyle = {
  width: "100%",
  minHeight: "2px",
  background: "#BDBDBD",
};

export default OrderCancelDetail;
