import { useState } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";

import { signin } from "service/AccountService";

import SigninRoute from "components/account/SigninRoute";
import Signin from "components/account/Signin";
import SignupRoute from "components/account/SignupRoute";
import Signup from "components/account/Signup";
import IdFind from "components/account/IdFind";
import PwFind from "components/account/PwFind";
import Agreement from "components/account/Agreement";
import PwReset from "components/account/PwReset";
import IdResult from "components/account/IdResult";
import JustAuth from "components/account/JustAuth";
import JustAuthResult from "components/account/JustAuthResult";
import SigninWebView from "components/account/SigninWebView";

const AccountRouter = ({ isLogin, setIsLogin, setUser, closePopupEvent }) => {
  const navigate = useNavigate();

  const [loginId, setLoginId] = useState("");
  const [loginPw, setLoginPw] = useState("");
  const [passFindValidCheck, setPassFindValidCheck] = useState(false);
  const [passFindResultId, setPassFindResultId] = useState("");

  const [idFindValidCheck, setIdFindValidCheck] = useState(false);
  const [idFindResultId, setIdFindResultId] = useState("");

  const loginEvent = (e) => {
    e.preventDefault();
    signin({ inId: loginId, inPw: loginPw })
      .then((res) => {
        if (res.success) {
          window.localStorage.setItem("KiikeyAppToken",res.data.token);
          setUser(res.data.user);
          setIsLogin(true);
          navigate("/order",{replace:true});

        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const validLoginIdCheck = (e) => {
    let curValue = e.target.value;

    // if (
    //   RegExp(NOT_SPACE_REG).test(curValue) ||
    //   RegExp(NOT_SPECIAL_CODE_REG).test(curValue)
    // ) {
    //   console.log("err");
    //   curValue = curValue.replace(NOT_SPACE_REG, "");
    //   curValue = curValue.replace(NOT_SPECIAL_CODE_REG, "");
    // }

    setLoginId(curValue);
  };
  const validLoginPwCheck = (e) => {
    // TODO: 비밀번호 유효성체크 추가필요
    const curValue = e.target.value;

    setLoginPw(curValue);
  };
  return (
    <Routes>
      <Route path="" />
      <Route path="signinRoute" element={<SigninRoute />} />
      <Route
        path="signin"
        element={
          // <Signin
          //   isLogin={isLogin}
          //   loginId={loginId}
          //   setLoginId={setLoginId}
          //   loginPw={loginPw}
          //   validLoginIdCheck={validLoginIdCheck}
          //   validLoginPwCheck={validLoginPwCheck}
          //   loginEvent={loginEvent}
          //   closePopupEvent={closePopupEvent}
          // />

          <SigninWebView
            isLogin={isLogin}
            loginId={loginId}
            setLoginId={setLoginId}
            loginPw={loginPw}
            validLoginIdCheck={validLoginIdCheck}
            validLoginPwCheck={validLoginPwCheck}
            loginEvent={loginEvent}
            closePopupEvent={closePopupEvent}
          />
        }
      />
      <Route path="signupRoute" element={<SignupRoute />} />
      <Route path="signup" element={<Signup setLoginId={setLoginId} />} />
      <Route
        path="idFind"
        element={
          <IdFind
            setIdFindValidCheck={setIdFindValidCheck}
            setIdFindResultId={setIdFindResultId}
          />
        }
      />
      <Route
        path="idResult"
        element={
          idFindValidCheck && idFindResultId ? (
            <IdResult
              setIdFindValidCheck={setIdFindValidCheck}
              setIdFindResultId={setIdFindResultId}
              idFindResultId={idFindResultId}
              setLoginId={setLoginId}
            />
          ) : (
            <Navigate to="/account/idFind" />
          )
        }
      />
      <Route
        path="pwFind"
        element={
          <PwFind
            setPassFindValidCheck={setPassFindValidCheck}
            setPassFindResultId={setPassFindResultId}
          />
        }
      />
      <Route
        path="pwReset"
        element={
          passFindValidCheck && passFindResultId ? (
            <PwReset
              setPassFindValidCheck={setPassFindValidCheck}
              setPassFindResultId={setPassFindResultId}
              passFindResultId={passFindResultId}
              setLoginId={setLoginId}
            />
          ) : (
            <Navigate to="/account/pwFind" />
          )
        }
      />
      <Route path="agreement" element={<Agreement />} />
      <Route
        path="justAuth"
        element={
          <JustAuth
            setIdFindValidCheck={setIdFindValidCheck}
          />
        }
      />
      <Route
        path="justAuthResult"
        element={
          idFindValidCheck ? (
            <JustAuthResult
              setIdFindValidCheck={setIdFindValidCheck}
            />
          ) : (
            <Navigate to="/account/JustAuth" />
          )
        }
      />
    </Routes>
  );
};

export default AccountRouter;
