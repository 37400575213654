import { useEffect, useState } from "react";
import { Link, Navigate, Route, Routes, useNavigate } from "react-router-dom";

import { getBasket, getMemberInfo } from "service/OrderService";
import { getReviewList } from "service/AccountService";
import { getADWatchList, getScrapList } from "service/ADSService";
import { getInquireList, getTicketList } from "service/AccountService";

import ReviewType from "components/mykiikey/ReviewType";
import ReviewTypeProduct from "components/mykiikey/ReviewTypeProduct";

import BarcodeUseList from "components/mykiikey/BarcodeUseList";
import ReviewWriting from "components/mykiikey/ReviewWriting";
import Mykiikeysetting from "components/mykiikey/Mykiikeysetting";
import ADSscrap from "components/mykiikey/ADSscrap";
import AccountCheck from "components/mykiikey/AccountCheck";
import OrderList from "components/mykiikey/OrderList";
import GiftCouponList from "components/mykiikey/GiftCouponList";
import Statement from "components/mykiikey/Statement";
import Adwatched from "components/mykiikey/ADSwatched";
import ADBanner from "components/main/ADBannerComponent";
import Notice from "components/mykiikey/Notice";
import Privacy from "components/mykiikey/Privacy";
import PointuseHistory from "components/mykiikey/Pointhistory";
import Servicecenter from "components/mykiikey/Servicecenter";
import Favoritestore from "components/mykiikey/Favoritestore";
import EventApply from "components/mykiikey/EventApply";
import ReviewList from "components/mykiikey/ReviewList";
import LocationSet from "components/common/LocationSet";

import InquireList from "components/mykiikey/InquireList";
import TicketList from "components/mykiikey/TicketList";

import {
  isKiikeyApp,
  IS_IOS,
  IS_ANDROID,
  serverErrorHandler,
} from "App-config";
import Header from "components/common/Header";
import EventEnter from "components/mykiikey/EventEnter";
import MyWallet from "components/mykiikey/MyWallet";
import OrderCancel from "components/mykiikey/OrderCancel";
import OrderCancelConfirm from "components/mykiikey/OrderCancelConfirm";
import OrderCancelDetail from "components/mykiikey/OrderCancelDetail";
import OrderReceipt from "components/mykiikey/OrderReceipt";
import stateUtilityInstance from "TestClass";

const MykiikeyRouter = ({
  user,
  setAD,
  isMobile,
  isLogin,
  setIsNotQuiz,
  setIsRunning,
  playVideoEvent,
  closePopupEvent,
  closeGamePopupEvent,
  hiddenCatchGame,
}) => {
  const navigate = useNavigate();

  const [userInfo, setUserInfo] = useState();
  const [passCheck, setPassCheck] = useState(false);

  const [scrapList, setScrapList] = useState([]);
  const [watchList, setWatchList] = useState([]);
  const [noticeList, setNoticeList] = useState([]);
  const [orderHistoryList, setOrderHistoryList] = useState([]);
  const [giftCouponList, setGiftCouponList] = useState([]);
  const [userQnaList, setUserQnaList] = useState([]);
  const [pointHistoryList, setPointHistoryList] = useState([]);
  const [reviewList, setReviewList] = useState([]);
  const [eventList, setEventList] = useState([]);
  const [favoriteStoreList, setFavoriteStoreList] = useState([]);
  const [inquireList, setInquireList] = useState([]);
  const [ticketList, setTicketList] = useState([]);
  const [term, setTerm] = useState(1);

  useEffect(() => {
    closePopupEvent();
    getMemberInfo()
      .then((res) => {
        setUserInfo(res);

        getBasket()
          .then((res) => {
            if (res.success && res.data.length >= 0) {
              let bkCnt = 0;
              res.data.forEach((ele) => {
                ele.rows.forEach((row) => {
                  bkCnt++;
                });
              });
              console.log("바스켓 카운트 :" + bkCnt);

              stateUtilityInstance.setBasketCount(bkCnt);
            }
          })
          .catch((err) => {
            console.error(err);
            serverErrorHandler(err);
          });
      })
      .catch((err) => {
        console.error("mykikirouter: err :: ", err);
        console.error("mykikirouter: err msg:: ", err.msg);
        serverErrorHandler(err);
      });

    getADWatchList()
      .then((res) => {
        if (res.success) {
          setWatchList(res.data);
        }
      })
      .catch((err) => {
        console.error("adwatch err", err);
        console.error("adwatch err msg", err.msg);
        serverErrorHandler(err);
      });

    getScrapList()
      .then((res) => {
        if (res.success) {
          setScrapList(res.data);
        }
      })
      .catch((err) => {
        console.error("scrap err", err);
        console.error("scrap err msg", err.msg);
        sessionStorage.clear();
        serverErrorHandler(err);
      });

    getReviewList()
      .then((res) => {
        if (res.success) {
          setReviewList(res.data);
        }
      })
      .catch((err) => {
        console.error("review err", err);
        console.error("review err msg", err.msg);
        serverErrorHandler(err);
      });
    getInquireList()
      .then((res) => {
        if (res.success) {
          setInquireList(res.data);
        }
      })
      .catch((err) => {
        console.error("inquire err", err);
        console.error("inquire err msg", err.msg);
        serverErrorHandler(err);
      });
  }, []);
  return (
    <main
      className="main"
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      {!isMobile && (
        <ADBanner
          isLogin={isLogin}
          user={user}
          playVideoEvent={playVideoEvent}
          closeGamePopupEvent={closeGamePopupEvent}
          hiddenCatchGame={hiddenCatchGame}
        />
      )}
      {userInfo && (
        <section
          className={"mykiikey-frame" + (isKiikeyApp ? " kapp" : "")}
          style={{
            marginTop: 0,
            marginBottom: 0,
            paddingTop: "60px",
            paddingBottom: 0,
          }}
        >
          {isMobile && <Header />}
          <div className="mykiikey-menu-list-area">
            <div className="mykiikey-setting">
              <Mykiikeysetting
                myName={userInfo.mb_name}
                myPoint={userInfo.mb_point}
                scrapCnt={scrapList.length}
                reviewCnt={reviewList.length}
              />
            </div>
            <ul className="menu-list">
              <li>
                <Link to="positioning">
                  <span className="list-daps">
                    <p>위치설정</p>
                  </span>
                  <button className="menu-list-button"></button>
                </Link>
              </li>
              <li>
                <Link to="pointuseHistory">
                  <span className="list-daps">
                    <p>포인트 사용 내역</p>
                  </span>
                  <button className="menu-list-button"></button>
                </Link>
              </li>
              <li>
                <Link to="orderList">
                  <span className="list-daps">
                    <p>주문 내역</p>
                  </span>
                  <button className="menu-list-button"></button>
                </Link>
              </li>
              <li>
                <Link to="giftCouponList">
                  <span className="list-daps">
                    <p>기프티콘</p>
                  </span>
                  <button className="menu-list-button"></button>
                </Link>
              </li>
              <li>
                <Link to="">
                  <span className="list-daps">
                    <p>광고 시청 리스트</p>
                  </span>
                  <button className="menu-list-button"></button>
                </Link>
              </li>
              <li>
                <Link to="adscrap">
                  <span className="list-daps">
                    <p>광고 스크랩 리스트</p>
                  </span>
                  <button className="menu-list-button"></button>
                </Link>
              </li>
              <li>
                <Link to="favoritestore">
                  <span className="list-daps">
                    <p>찜한 가게</p>
                  </span>
                  <button className="menu-list-button"></button>
                </Link>
              </li>
              {/* <li>
              <Link to="#">
                <span className="list-daps"><p>환경설정</p></span>
                <button className="menu-list-button"></button>
              </Link>
            </li> */}
              <li>
                <Link to="privacy">
                  <span className="list-daps">
                    <p>개인 정보 변경</p>
                  </span>
                  <button className="menu-list-button"></button>
                </Link>
              </li>
              <li>
                <Link to="servicecenter">
                  <span className="list-daps">
                    <p>고객센터 Q&A</p>
                  </span>
                  <button className="menu-list-button"></button>
                </Link>
              </li>
              <li>
                <Link to="notice">
                  <span className="list-daps">
                    <p>공지사항</p>
                  </span>
                  <button className="menu-list-button"></button>
                </Link>
              </li>
              <li>
                <Link to="reviewList">
                  <span className="list-daps">
                    <p>내가 적은 리뷰들</p>
                  </span>
                  <button className="menu-list-button"></button>
                </Link>
              </li>
              <li>
                <Link to="inquireList">
                  <span className="list-daps">
                    <p>내가 적은 문의들</p>
                  </span>
                  <button className="menu-list-button"></button>
                </Link>
              </li>
              <li>
                <Link to="ticketList">
                  <span className="list-daps">
                    <p>영화관람권</p>
                  </span>
                  <button className="menu-list-button"></button>
                </Link>
              </li>
              <li>
                <Link to="eventApply">
                  <span className="list-daps">
                    <p>이벤트</p>
                  </span>
                  <button className="menu-list-button"></button>
                </Link>
              </li>
            </ul>
          </div>
          <div
            className={"mykiikey-menu-area" + (isKiikeyApp ? " kapp" : "")}
            style={{
              paddingTop: 0,
              paddingBottom: 0,
              overflow: "auto",
            }}
          >
            <Routes>
              <Route
                path="barcodeUseList"
                element={
                  <BarcodeUseList
                    orderHistoryList={orderHistoryList}
                    setOrderHistoryList={setOrderHistoryList}
                    isMobile={isMobile}
                  />
                }
              />
              <Route
                path=""
                element={
                  <Adwatched
                    isMobile={isMobile}
                    watchList={watchList}
                    setWatchList={setWatchList}
                    setScrapList={setScrapList}
                  />
                }
              />
              <Route
                path="adscrap"
                element={
                  <ADSscrap
                    setAD={setAD}
                    setIsRunning={setIsRunning}
                    setIsNotQuiz={setIsNotQuiz}
                    setWatchList={setWatchList}
                    setScrapList={setScrapList}
                    isMobile={isMobile}
                    scrapList={scrapList}
                  />
                }
              />
              <Route
                path="privacy"
                element={
                  passCheck ? (
                    <Privacy userInfo={userInfo} />
                  ) : (
                    <Navigate to="/mykiki/accountCheck" />
                  )
                }
              />
              <Route
                path="accountCheck"
                element={
                  passCheck ? (
                    <Navigate to="/mykiki/privacy" />
                  ) : (
                    <AccountCheck
                      setPassCheck={setPassCheck}
                      setUserInfo={setUserInfo}
                    />
                  )
                }
              />
              <Route
                path="orderList"
                element={
                  <OrderList
                    userInfo={userInfo}
                    setUserInfo={setUserInfo}
                    orderHistoryList={orderHistoryList}
                    setOrderHistoryList={setOrderHistoryList}
                    isMobile={isMobile}
                  />
                }
              />

              <Route
                path="giftCouponList"
                element={
                  <GiftCouponList
                    userInfo={userInfo}
                    giftCouponList={giftCouponList}
                    setGiftCouponList={setGiftCouponList}
                    isMobile={isMobile}
                  />
                }
              />
              <Route path="orderList/:odIdx" element={<Statement />} />
              <Route
                path="orderList/receipt/:odIdx"
                element={<OrderReceipt />}
              />
              <Route
                path="orderList/cancel/:odIdx"
                element={
                  <OrderCancel userInfo={userInfo} setUserInfo={setUserInfo} />
                }
              />
              <Route
                path="orderList/cancel/confirm/:odIdx"
                element={<OrderCancelConfirm />}
              />
              <Route
                path="orderList/cancel/detail/:odIdx"
                element={<OrderCancelDetail />}
              />

              <Route
                path="reviewList"
                element={
                  <ReviewList
                    reviewList={reviewList}
                    setReviewList={setReviewList}
                    isMobile={isMobile}
                  />
                }
              />

              <Route
                path="inquireList"
                element={
                  <InquireList
                    inquireList={inquireList}
                    setInquireList={setInquireList}
                    isMobile={isMobile}
                  />
                }
              />
              <Route
                path="ticketList"
                element={
                  <TicketList
                    ticketList={ticketList}
                    setTicketList={setTicketList}
                    isMobile={isMobile}
                  />
                }
              />
              <Route
                path="orderList/reviewType"
                element={
                  <ReviewType
                    reviewList={reviewList}
                    setReviewList={setReviewList}
                  />
                }
              />
              <Route
                path="orderList/reviewTypeProduct"
                element={
                  <ReviewTypeProduct
                    reviewList={reviewList}
                    setReviewList={setReviewList}
                  />
                }
              />

              <Route
                path="orderList/review"
                element={
                  <ReviewWriting
                    reviewList={reviewList}
                    setReviewList={setReviewList}
                  />
                }
              />
              <Route
                path="eventApply"
                element={
                  <EventApply
                    eventList={eventList}
                    setEventList={setEventList}
                    isMobile={isMobile}
                  />
                }
              />
              <Route
                path="eventApply/enter"
                element={<EventEnter userInfo={userInfo} isMobile={isMobile} />}
              />
              <Route
                path="favoritestore"
                element={
                  <Favoritestore
                    favoriteStoreList={favoriteStoreList}
                    setFavoriteStoreList={setFavoriteStoreList}
                    isMobile={isMobile}
                  />
                }
              />
              <Route
                path="servicecenter"
                element={
                  <Servicecenter
                    userQnaList={userQnaList}
                    setUserQnaList={setUserQnaList}
                    isMobile={isMobile}
                  />
                }
              />
              <Route
                path="pointuseHistory"
                element={
                  <PointuseHistory
                    term={term}
                    setTerm={setTerm}
                    pointHistoryList={pointHistoryList}
                    setPointHistoryList={setPointHistoryList}
                    isMobile={isMobile}
                  />
                }
              />
              <Route
                path="notice"
                element={
                  <Notice
                    noticeList={noticeList}
                    setNoticeList={setNoticeList}
                    isMobile={isMobile}
                  />
                }
              />
              <Route
                path="positioning"
                element={
                  <LocationSet
                    userInfo={userInfo}
                    setUserInfo={setUserInfo}
                    redirectUrl={""}
                  />
                }
              />
              <Route
                path="myWallet"
                element={
                  <MyWallet
                    userInfo={userInfo}
                    setUserInfo={setUserInfo}
                    redirectUrl={""}
                  />
                }
              />
            </Routes>
          </div>
        </section>
      )}
    </main>
  );
};

export default MykiikeyRouter;
