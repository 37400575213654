import { useEffect, useState } from "react";

import { SHOP_CUSTOM_IMG_PATH } from "App-config";

import "components/order/style/jangbojaPart/JangbojaProductModal.css";

import textimg from "src_assets/shop-thumbnail-img.png";

// 이상윤 220720 새 컴포넌트 , 장보고에서 상품이미지를 눌렀을때 나오는 모달 창
const JangbojaDetail = ({ modalData, setIsModalView }) => {
  const [menuImgs, setMenuImgs] = useState([]);
  const [imgIndex, setImgIndex] = useState(0);
  const [currentImg, setCurrentImg] = useState();

  useEffect(() => {
    setCurrentImg(<ImgLiTag imgData={modalData[0]} />);
    setMenuImgs(modalData);
  }, []);

  const ImgLiTag = ({ imgData }) => {
    return (
      <li>
        <img
          src={SHOP_CUSTOM_IMG_PATH + imgData}
          onError={defaultImgEvent}
          alt={imgIndex}
        />
      </li>
    );
  };

  const defaultImgEvent = (e) => {
    e.target.remove();
  };

  return (
    <div className="jangboja-img-modal-frame">
      <div className="jangboja-img-modal">
        <button
          className="modal-close-button"
          onClick={() => {
            setIsModalView(false);
          }}
        />
        <ul className="product-img-slide">
          <button
            className="slide-button-left"
            onClick={() => {
              let tIndex = imgIndex;
              if (tIndex === 0) {
                tIndex = menuImgs.length - 1;
              } else {
                tIndex--;
              }
              setCurrentImg(<ImgLiTag imgData={modalData[tIndex]} />);
              setImgIndex(tIndex);
            }}
          />
          <button
            className="slide-button-right"
            onClick={() => {
              let tIndex = imgIndex;
              if (tIndex === menuImgs.length - 1) {
                tIndex = 0;
              } else {
                tIndex++;
              }
              setCurrentImg(<ImgLiTag imgData={modalData[tIndex]} />);
              setImgIndex(tIndex);
            }}
          />
          {currentImg}
        </ul>
      </div>
    </div>
  );
};

export default JangbojaDetail;
