import { AD_API_PATH } from "App-config";
import { call, customAlert } from "service/ApiService";

export const getADList = async () => {
  try {
    const response = await call(AD_API_PATH, "POST", { action: "getADList" });
    if (!response.success) {
      console.error("getADList fail:", response.msg);
      customAlert(response.msg);
    }
    return response;
  } catch (error) {
    console.error("getADList err:", error);
    customAlert(error.msg);
    return false;
  }
};

export const getAD = async (a_idx) => {
  const response = await call(AD_API_PATH, "POST", {
    action: "getAD",
    a_idx: a_idx,
  });

  return response;
};
export const getGame = async (g_idx) => {
  const response = await call(AD_API_PATH, "POST", {
    action: "getHCG",
    g_idx: g_idx,
  });

  return response;
}

export const requestAnswer = async (answerDTO) => {
  answerDTO.action = "requestAnswer";
  const response = await call(AD_API_PATH, "POST", answerDTO);
  return response;
};

export const requestGameAnswer = async (answerDTO) => {
  answerDTO.action = "gameOver";
  const response = await call(AD_API_PATH, "POST", answerDTO);
  return response;
};

export const getScrapList = async () => {
  const response = await call(AD_API_PATH, "POST", { action: "getScrapList" });
  return response;
};

export const getADWatchList = async () => {
  const response = await call(AD_API_PATH, "POST", {
    action: "getADWatchList",
  });
  return response;
};

export const setScrap = async (adDTO) => {
  adDTO.action = "setScrap";
  const response = await call(AD_API_PATH, "POST", adDTO);
  return response;
};

export const delScrap = async (adDTO) => {
  adDTO.action = "delScrap";
  const response = await call(AD_API_PATH, "POST", adDTO);
  return response;
};
