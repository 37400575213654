import { useEffect } from "react";

import logo from "src_assets/kiikey_logo_white.png";

import "components/about/about.css";

const AboutComponent = ({ closePopupEvent }) => {
  useEffect(() => {
    // 뒤로가기로 페이지이동시 광고닫기
    closePopupEvent();
  }, []);
  return (
    <section className="introduction">
      <div className="kiikey-intro-wrap">
        <div className="intro-text-area">
          <div className="s-intro-text">
            <span>이쁨로 통하는</span>
            <span>MULTY PLATFORM</span>
          </div>
          <div className="l-intro-text">
            <span className="l-intro-logo">
              <img src={logo} alt="로고이미지"></img>
            </span>
            <span>Series</span>
          </div>
        </div>
      </div>
      <div className="kiikey-discount-wrap">
        <div className="discount-text-area">
          <span className="frame-number">02</span>
          <span className="discount-title">이쁨은 배송음식무제한 할인</span>
          <ul className="discount-text-list">
            <li>
              <span className="list-number">01</span>
              <span className="list-text">
                <h2>월 100만원 버는 돈버는 앱</h2>
                <p>동영상 광고 시청으로 적립,문제풀고 적립,주문하고 적립</p>
                <p>
                  맛집 공유하고 적립 사용자 행동 모든걸 다 포인트로
                  적립해드립니다.
                </p>
              </span>
            </li>
            <li>
              <span className="list-number">02</span>
              <span className="list-text">
                <h2>동영상 광고 시청하고 평생 할인 받자</h2>
                <p>출,퇴근 시간, 심심할때 동영상 광고 시청으로</p>
                <p>쌓은 포인트를 1원 부터 사용가능합니다.</p>
                <p>열심히 동영상 광고를 시청한 당신,</p>
                <p>평생 먹을 배송음식 이쁨가 쏩니다.</p>
              </span>
            </li>
            <li>
              <span className="list-number">03</span>
              <span className="list-text">
                <h2>이쁨앱 하나로 달라지는 라이프 스타일</h2>
                <p>
                  적립된 적립금은 음식주문, 마트장보기로 즉시 사용할 수 있으며
                </p>
                <p>배고플 때만 찾는 앱이 아닌 일상생활 속에 모든것을</p>
                <p>
                  이쁨에서 즐길 수 있는 하나된 IOT시스템이라 볼 수 있습니다.
                </p>
              </span>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default AboutComponent;
