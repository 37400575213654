import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getOrderDetail } from "service/OrderService";
import { SHOP_CUSTOM_IMG_PATH } from "App-config";
import stateUtilityInstance from "TestClass";

import { ReactComponent as AlertIcon } from "src_assets/icon/alert_001_icon.svg";
import { ReactComponent as NoticeTextIcon } from "src_assets/icon/notice_text_icon.svg";
import { cancelOrder } from "service/OrderService";

const OrderCancel = ({ userInfo, setUserInfo }) => {
  const navigate = useNavigate();
  const params = useParams();

  const [orderDetail, setOrderDetail] = useState(null);
  const [cancelType, setCancelType] = useState(1);

  useEffect(() => {
    stateUtilityInstance.setMobileHeaderTitle("주문취소", true);
    if (params.odIdx) {
      getOrderDetail({ od_idx: params.odIdx })
        .then((res) => {
          if (!!res.data) {
            setOrderDetail(res.data);
          }
        })
        .catch((err) => {
          console.error(err);
          navigate("/mykiki/orderList");
        });
    }
  }, []);

  const OrderMenu = ({ order, order_menu }) => {
    let newArr = [];
    let currentIndex = 0;
    order_menu.forEach((menu, index) => {
      if (menu.m_name) {
        if (index > 0) {
          currentIndex++;
        }
        newArr.push({
          m_name: menu.m_name,
          m_price: menu.m_price * 1,
          c_cnt: menu.c_cnt,
          m_img: menu.m_img,
          table_name: menu.table_name,
          options: [],
        });
      } else if (menu.moc_name) {
        // newArr[currentIndex].options.push({
        //   c_cnt: menu.c_cnt,
        //   moc_name: menu.moc_name,
        // });
      } else if (menu.mo_name) {
        // newArr[currentIndex].m_price =
        //  newArr[currentIndex].m_price + menu.mo_price * 1;
        // newArr[currentIndex].options.push({
        //   c_cnt: menu.c_cnt,
        // mo_name: menu.mo_name,
        // mo_price: menu.mo_price * 1,
        //});
      }
    });

    return (
      <>
        {newArr.map((menu, index) => {
          return (
            <div style={menuItemContainerStyle}>
              <div
                style={{
                  width: "100px",
                  height: "100px",
                  borderRadius: "8px",
                  border: "1px solid #BDBDBD",
                  overflow: "hidden",
                }}
              >
                <img
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                  src={
                    SHOP_CUSTOM_IMG_PATH +
                    menu?.table_name?.replace("_", "") +
                    "/menu/" +
                    menu?.m_img
                  }
                />
              </div>

              <div
                style={{
                  fontSize: "15px",
                  width: 0,
                  flexGrow: 1,
                  padding: "0.3em 0",
                }}
              >
                <div>{menu.m_name}</div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "0.5em",
                  }}
                >
                  <span>
                    {menu.m_price
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    원
                  </span>
                  <span>/</span>
                  <span>{menu.c_cnt}개</span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "0.5em",
                  }}
                >
                  <div>배송비</div>
                  <div>
                    {orderDetail.order.od_delivery_tip > 0
                      ? orderDetail.order.od_delivery_tip
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "원"
                      : "무료배송"}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </>
    );
  };

  const orderCnacelClickEvent = () => {
    let input = document.querySelector(".write-cancel-content");

    cancelOrder({
      od_idx_list: [orderDetail.order.od_idx],
      cancelTypeReal: cancelType,
      cancelContent: input?.value || "",
    })
      .then((res) => {
        if (res.success) {
          let newUserInfo = { ...userInfo };
          newUserInfo.mb_point = newUserInfo.mb_point + res.data * 1;
          setUserInfo(newUserInfo);
        }

        navigate(
          "/mykiki/orderList/cancel/confirm/" + orderDetail.order.od_idx
        );
        //alert("취소 신청이 완료되었습니다. 환불포인트:" + res.data + "점");
      })
      .catch((err) => {
        alert(err.msg);
        console.error(err);
      });
  };

  if (!orderDetail) return <></>;

  console.log(orderDetail);
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        overflow: "auto",
        padding: "2em 1em",
        boxSizing: "border-box",

        display: "flex",
        flexDirection: "column",
        gap: "1em",

        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "1.5",
        fontSize: "15px",
        color: "#000",
      }}
    >
      <div style={noticeStyle}>
        <div style={noticeHeaderStyle}>
          <div
            style={{
              fontSize: "18px",
              fontWeight: "700",
            }}
          >
            마음에 안드셨나요?
          </div>
          <AlertIcon />
        </div>
        <div style={noticeContentStyle}>
          <p>취소사유를 알려 주시면</p>
          <p>더 좋은 상품으로 다시 뵙겠습니다.</p>
        </div>
      </div>

      <div>
        <div
          style={{
            fontSize: "18px",
          }}
        >
          주문상품
        </div>
        <div style={menuListContainerStyle}>
          <OrderMenu order={orderDetail.order} order_menu={orderDetail.rows} />
        </div>
      </div>

      <form style={cancelTypeContainerStyle}>
        <label>
          <input
            type="radio"
            name="cancelType"
            style={{ display: "none" }}
            onChange={(e) => setCancelType(1)}
            checked={cancelType == 1}
          />
          <div
            style={{
              ...cancelTypeStyle,
              background: cancelType == 1 ? "#BDBDBD" : "#FFF",
              color: cancelType == 1 ? "#FFF" : "#828282",
            }}
          >
            단순변심
          </div>
        </label>
        <label>
          <input
            type="radio"
            name="cancelType"
            onChange={(e) => setCancelType(3)}
            checked={cancelType == 3}
            style={{ display: "none" }}
          />
          <div
            style={{
              ...cancelTypeStyle,
              background: cancelType == 3 ? "#BDBDBD" : "#FFF",
              color: cancelType == 3 ? "#FFF" : "#828282",
            }}
          >
            배송지연
          </div>
        </label>
        <label>
          <input
            type="radio"
            name="cancelType"
            onChange={(e) => setCancelType(2)}
            checked={cancelType == 2}
            style={{ display: "none" }}
          />
          <div
            style={{
              ...cancelTypeStyle,
              background: cancelType == 2 ? "#BDBDBD" : "#FFF",
              color: cancelType == 2 ? "#FFF" : "#828282",
            }}
          >
            주문실수
          </div>
        </label>
        <label>
          <input
            type="radio"
            name="cancelType"
            onChange={(e) => setCancelType(4)}
            checked={cancelType == 4}
            style={{ display: "none" }}
          />
          <div
            style={{
              ...cancelTypeStyle,
              background: cancelType == 4 ? "#BDBDBD" : "#FFF",
              color: cancelType == 4 ? "#FFF" : "#828282",
            }}
          >
            서비스 불만족
          </div>
        </label>
      </form>

      <div>
        <div style={{ fontSize: "18px" }}>사유입력</div>
        <div>
          <textarea
            className="write-cancel-content"
            style={{
              width: "100%",
              height: "5em",
              borderRadius: "8px",
              border: "1px solid #BDBDBD",
              background: "#FFF",
              boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)",
              padding: "1em",
              boxSizing: "border-box",
            }}
            onInput={(e) => {
              const target = e.currentTarget;
              target.value = target.value.slice(0, 100);
            }}
          ></textarea>
        </div>
      </div>

      <div>
        <p
          style={{
            padding: "0 0.5em",
            color: "#828282",
          }}
        >
          취소 요청 전에 판매자와 취소 가능여부를 확인해 주세요. 이미 배송이
          시작되었다면 취소가 불가합니다.
        </p>
      </div>

      <div style={{ marginTop: "1em" }}>
        <NoticeTextIcon />
        <p
          style={{
            padding: "0 0.5em",
            color: "#828282",
          }}
        >
          주문 취소 및 현금결제 할인을 유도하는 판매자가 있다면 사기 가능성이
          있으니, 구매자의 안전거래를 위해 이쁨몰 고객센터에 꼭 알려주세요.
        </p>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "8px",
          background: "#BDBDBD",
          boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)",
          color: "#FFF",
          padding: "0.6em 1em 0.4em",
        }}
        onClick={(e) => {
          orderCnacelClickEvent();
        }}
      >
        취소 신청하기
      </div>
    </div>
  );
};

const noticeStyle = {
  display: "flex",
  flexDirection: "column",
  fontSize: "15px",
  borderRadius: "8px",
  border: "1px solid #828282",
  padding: "1em",
};

const noticeHeaderStyle = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "flex-end",
  paddingLeft: "1.5em",
};

const noticeContentStyle = {
  borderRadius: "8px",
  background: "#EBEBEB",
  padding: "1.5em 1em",
};

const menuListContainerStyle = {
  display: "flex",
  flexDirection: "column",
  gap: "1em",
};

const menuItemContainerStyle = {
  display: "flex",
  flexDirection: "row",
  gap: "1em",
};

const cancelTypeContainerStyle = {
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  //gridTemplateRows: "repeat(auto-fill, 40px)",
  gap: "1em",
  padding: "2em",
  borderTop: "2px solid #BDBDBD",
  borderBottom: "2px solid #BDBDBD",
};

const cancelTypeStyle = {
  width: "100%",
  height: "40px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "8px",
  border: "1px solid #BDBDBD",
  background: "#FFF",
  boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)",
  color: "#828282",
  boxSizing: "border-box",
};

export default OrderCancel;
