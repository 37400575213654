import { useEffect, useState } from "react";

import { getAgreeList } from "service/AccountService";

import "components/common/style/UserAgree.css";

const Useagree = ({ htmlStr }) => {
  return (
    <div
      className="user-agree-box"
      dangerouslySetInnerHTML={{ __html: htmlStr }}
    ></div>
  );
};

const UserAgree = () => {
  const [useAgree, setUseAgree] = useState("");

  useEffect(() => {
    getAgreeList().then((res) => {
      res.data.forEach((item) => {
        if (item.docsSubject === "이용약관") {
          setUseAgree(item.docsContent);
        }
      });
    });
  }, []);

  return (
    <section className="user-agree">
      <div className="user-agree-area">
        <h2>이용약관</h2>
        <Useagree htmlStr={useAgree} />
      </div>
    </section>
  );
};

export default UserAgree;
