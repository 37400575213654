import { useEffect } from "react";

import "components/order/style/ShopInfo.css";
const { Kakao } = window;
const KakaoNavi = ({  name ,lat,lng,}) => {
    useEffect(() => {
        
        
        let container = document.getElementById("navi");
       

        container.addEventListener("click", () => {
        console.log(Number(lat),Number(lng));
        Kakao.Navi.start({
            name: name, // 목적지 이름
            x: Number(lng), // 목적지 x 좌표
            y: Number(lat), // 목적지 y 좌표
            coordType: "wgs84", // 좌표계 타입
          })
        });
    });
    return (
        <div id="navi" className="" style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>          
                <img src="https://developers.kakao.com/assets/img/about/buttons/navi/kakaonavi_btn_medium.png"
                alt="길 안내하기 버튼" />         
        </div>
      );
}
export default KakaoNavi;
