import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { passwordChange } from "service/AccountService";

import "components/account/style/PasswordReset.css";

const PwReset = ({
  setLoginId,
  passFindResultId,
  setPassFindResultId,
  setPassFindValidCheck,
}) => {
  const navigate = useNavigate();

  const [passwd, setPasswd] = useState("");
  const [passwdCheck, setPasswdCheck] = useState("");

  const onSubmitClickEvent = (e) => {
    e.preventDefault();

    if (passwd.length < 8 || passwdCheck.length < 8) {
      alert("비밀번호는 8자리 이상이어야 합니다.");
      return;
    }

    if (passwd !== passwdCheck) {
      alert("비밀번호를 확인해주세요.");
      return;
    }

    const userDTO = {
      mbId: passFindResultId,
      passwd: passwd,
    };

    passwordChange(userDTO)
      .then((res) => {
        if (res.success) {
          setPassFindValidCheck(false);
          setPassFindResultId("");
          setLoginId(res.data);

          navigate("/account/signin");
        }
      })
      .catch((err) => {
        alert(err.msg);
        console.error(err);
      });
  };

  return (
    <section className="password-reset">
      <form className="pw-reset-form" onSubmit={onSubmitClickEvent}>
        <div className="form-title">비밀번호 변경</div>
        <div className="npw-input-area">
          <span className="pw-note">새로운 비밀번호를 설정해주세요</span>
          <div className="npw-input">
            <span>
              <label htmlFor="passwd">새 비밀번호</label>
              <input
                type="password"
                name="passwd"
                id="passwd"
                onChange={(e) => setPasswd(e.target.value)}
                value={passwd}
              />
            </span>
            <span>
              <label htmlFor="passwd-check">비밀번호 확인</label>
              <input
                type="password"
                name="passwd-check"
                id="passwd-check"
                onChange={(e) => setPasswdCheck(e.target.value)}
                value={passwdCheck}
              />
            </span>
          </div>
        </div>
        <button type="submit" className="ps-button">
          완료
        </button>
      </form>
    </section>
  );
};

export default PwReset;
