import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {
  selectJbgMenu,
  selectJbgMenuSearch,
  selectJbgShopInfo,
} from "service/JangbogoService";

import JangbojaProductModal from "components/order/jangbojaPart/JangbojaProductModal";
import JangbojaDetail from "components/order/jangbojaPart/JangbojaDetail";
import CateTypeShopSearch from "components/order/categoryPart/CateTypeShopSearch";
import CateTypeCategorySearch from "components/order/categoryPart/CateTypeCategorySearch";

import FollowBasket from "components/order/FollowBasket";
import FollowBasketMobile from "components/order/FollowBasketMobile";

import bannerImg from "src_assets/jbg/jang.png";

import "components/order/style/MenuIntro.css";
import "components/order/style/ShopMenuFood.css";
import "components/order/style/ShopMenuGrade.css";

const JangbojaShop = ({
  isMobile,
  shopInfo,
  setShopInfo,
  basketList,
  setBasketList,
  totalAmt,
  setTotalAmt,
}) => {
  const navigate = useNavigate();

  const [isInfoView, setIsInfoView] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [isModalView, setIsModalView] = useState(false);

  const [shopMenu, setShopMenu] = useState(null);
  const [searchMenu, setSearchMenu] = useState(null);
  const [searchKey, setSearchKey] = useState("");

  const [isLike, setIsLike] = useState(false);

  useEffect(() => {
    const formData = new FormData();
    formData.append("action", "selectShopInfo");
    formData.append("sIdx", 7);

    selectJbgShopInfo(formData)
      .then((res) => {
        if (res.success) {
          setShopInfo(res.data);
        }
      })
      .catch(console.error);

    const formData2 = new FormData();
    formData2.append("action", "selectMenu");

    selectJbgMenu(formData2)
      .then((res) => {
        if (res.success) {
          setShopMenu(res.data);
        }
      })
      .catch(console.error);
  }, []);

  const searchEvent = (searchKeyword) => {
    if (!searchKeyword) {
      alert("검색어를 입력해주세요");
      setSearchKey("");
    } else {
      const formData = new FormData();
      formData.append("action", "selectSearchMenu");
      formData.append("keyword", searchKeyword);

      selectJbgMenuSearch(formData)
        .then((res) => {
          if (res.success) {
            setSearchMenu(res.data);
            setSearchKey(searchKeyword);
            document.querySelectorAll(
              ".category-search-list li.active"
            ).className = "";
          }
        })
        .catch(console.error);
    }
  };

  const basketAddClickEvent = () => {
    if (shopInfo.is_run < 1 || shopInfo.is_rest > 0) {
      alert("현재 영업중이 아닙니다.");
      return false;
    }

    let menuTempArr = [];
    let totAmt = 0;
    const targetElement = document.querySelectorAll(".category-item-list li");
    targetElement.forEach((ele) => {
      let menuTemp = {
        m_idx: ele.dataset.midx,
        m_count: 0,
        m_name: ele.dataset.mname,
        m_price: ele.dataset.price * 1,
        optionCateArray: [],
        totalPrice: 0,
      };

      const mCount = ele.querySelector("p.count-num").innerText;
      if (mCount > 0) {
        menuTemp.m_count = mCount * 1;
        menuTemp.totalPrice = menuTemp.m_price * (mCount * 1);
        ele.querySelector("p.count-num").innerText = "0";

        menuTempArr.push(menuTemp);

        totAmt += menuTemp.totalPrice;
      }
    });
    if (menuTempArr.length > 0) {
      if (basketList.sIdx != shopInfo.s_idx) {
        if (basketList.sIdx > 0) {
          if (
            !window.confirm(
              "이전에 담은 메뉴를 삭제하시겠습니까?\n\n(장바구니에는 같은 매장의 메뉴만 담을 수 있습니다.)"
            )
          ) {
            return false;
          }
        }
        setBasketList({ sIdx: shopInfo.s_idx, rows: menuTempArr });
        setTotalAmt(totAmt);
      } else {
        let newBasketList = [...basketList.rows, ...menuTempArr];
        setBasketList({ sIdx: shopInfo.s_idx, rows: newBasketList });
        setTotalAmt(totalAmt + totAmt);
      }
    }

    return true;
  };

  const basketClickEvent = () => {
    if (basketAddClickEvent()) {
      navigate("/order/confirm");
    }
  };

  return (
    <section className="shop-menu-list-frame">
      <div className="shop-menu-list-inner">
        <div className="thumbnailimg-box">
          <img src={bannerImg} alt="장보고 이미지" className="jangboja-img" />
        </div>
        <div className="shop-menu-list">
          <div className="shop-menu-title">
            {/* 이상윤 220720 shop-menu-info-link 를 클릭하면 JangbojaDetail   */}
            <button
              className="shop-menu-info-link"
              onClick={() => {
                setIsInfoView(!isInfoView);
              }}
            ></button>
            <h2>{shopInfo?.s_name}</h2>
          </div>
        </div>

        {shopInfo && shopMenu && (
          <>
            {isInfoView ? (
              <JangbojaDetail />
            ) : (
              <>
                <CateTypeShopSearch searchEvent={searchEvent} />
                <CateTypeCategorySearch
                  shopMenu={shopMenu}
                  setModalData={setModalData}
                  setIsModalView={setIsModalView}
                  searchKey={searchKey}
                  setSearchKey={setSearchKey}
                  searchMenu={searchMenu}
                  setSearchMenu={setSearchMenu}
                />
              </>
            )}
          </>
        )}

        {isModalView && (
          <JangbojaProductModal
            modalData={modalData}
            setIsModalView={setIsModalView}
          />
        )}
      </div>
      <>
        {isMobile ? (
          <FollowBasketMobile
            isLike={isLike}
            setIsLike={setIsLike}
            menuList={[]}
            shopInfo={shopInfo}
            basketList={basketList}
            basketAddClickEvent={basketAddClickEvent}
            basketClickEvent={basketClickEvent}
          />
        ) : (
          <FollowBasket
            menuList={[]}
            basketList={basketList}
            basketAddClickEvent={basketAddClickEvent}
            basketClickEvent={basketClickEvent}
          />
        )}
      </>
    </section>
  );
};

export default JangbojaShop;
