import "components/order/style/jangbojaPart/JangbojaDetail.css";

// 이상윤 220720 새 컴포넌트 , 장보고에서 (!) 누를때 나오는 상세정보
const JangbojaDetail = () => {
  return (
    <section className="jangboja-datail-section">
      <div className="shop-ad-area">
        <span className="shop-ad-area-title">깨알 홍보</span>
        <textarea readOnly disabled className="shop-ad-area-text">
          신선한 재료로 더욱 만나게 항상 빠른배송으로 맛있는 한끼를
        </textarea>
      </div>
      <ul className="shop-info-list">
        <li>
          <div className="shop-info-list-head">
            <span className="shop-info-name">장보고마트 감삼점</span>
            <span className="shop-info-detail">자세히 보기</span>
          </div>
          {/* shop-info-detail 을 누르면 나옴 */}
          <div className="shop-info-list-body dis-none">
            <ul className="shop-info-detail-list">
              <li>
                <span>주소</span>
                <span>대구광역시 달서구 와룡로 157</span>
              </li>
              <li>
                <span>전화번호</span>
                <span>053-247-0111</span>
              </li>
              <li>
                <span>사업자번호</span>
                <span>tkdjq</span>
              </li>
            </ul>
          </div>
        </li>
        <li>
          <div className="shop-info-list-head">
            <span className="shop-info-name">장보고마트 감삼점</span>
            <span className="shop-info-detail">자세히 보기</span>
          </div>
          {/* shop-info-detail 을 누르면 나옴 */}
          <div className="shop-info-list-body">
            <ul className="shop-info-detail-list">
              <li>
                <span>주소</span>
                <span>대구광역시 달서구 와룡로 157</span>
              </li>
              <li>
                <span>전화번호</span>
                <span>053-247-0111</span>
              </li>
              <li>
                <span>사업자번호</span>
                <span>tkdjq</span>
              </li>
            </ul>
          </div>
        </li>
        <li>
          <div className="shop-info-list-head">
            <span className="shop-info-name">장보고마트 감삼점</span>
            <span className="shop-info-detail">자세히 보기</span>
          </div>
          {/* shop-info-detail 을 누르면 나옴 */}
          <div className="shop-info-list-body">
            <ul className="shop-info-detail-list">
              <li>
                <span>주소</span>
                <span>대구광역시 달서구 와룡로 157</span>
              </li>
              <li>
                <span>전화번호</span>
                <span>053-247-0111</span>
              </li>
              <li>
                <span>사업자번호</span>
                <span>tkdjq</span>
              </li>
            </ul>
          </div>
        </li>
        <li>
          <div className="shop-info-list-head">
            <span className="shop-info-name">장보고마트 감삼점</span>
            <span className="shop-info-detail">자세히 보기</span>
          </div>
          {/* shop-info-detail 을 누르면 나옴 */}
          <div className="shop-info-list-body">
            <ul className="shop-info-detail-list">
              <li>
                <span>주소</span>
                <span>대구광역시 달서구 와룡로 157</span>
              </li>
              <li>
                <span>전화번호</span>
                <span>053-247-0111</span>
              </li>
              <li>
                <span>사업자번호</span>
                <span>tkdjq</span>
              </li>
            </ul>
          </div>
        </li>
        <li>
          <div className="shop-info-list-head">
            <span className="shop-info-name">장보고마트 감삼점</span>
            <span className="shop-info-detail">자세히 보기</span>
          </div>
          {/* shop-info-detail 을 누르면 나옴 */}
          <div className="shop-info-list-body">
            <ul className="shop-info-detail-list">
              <li>
                <span>주소</span>
                <span>대구광역시 달서구 와룡로 157</span>
              </li>
              <li>
                <span>전화번호</span>
                <span>053-247-0111</span>
              </li>
              <li>
                <span>사업자번호</span>
                <span>tkdjq</span>
              </li>
            </ul>
          </div>
        </li>
        <li>
          <div className="shop-info-list-head">
            <span className="shop-info-name">장보고마트 감삼점</span>
            <span className="shop-info-detail">자세히 보기</span>
          </div>
          {/* shop-info-detail 을 누르면 나옴 */}
          <div className="shop-info-list-body">
            <ul className="shop-info-detail-list">
              <li>
                <span>주소</span>
                <span>대구광역시 달서구 와룡로 157</span>
              </li>
              <li>
                <span>전화번호</span>
                <span>053-247-0111</span>
              </li>
              <li>
                <span>사업자번호</span>
                <span>tkdjq</span>
              </li>
            </ul>
          </div>
        </li>
      </ul>
      <span className="frame-bar"></span>
      <div className="delivery-tip">
        <span className="order-title">배송 팁</span>
        <span className="price-class">
          <ul className="tip-price-list">
            <span>금액별</span>
            <li>
              <p>{"18,000원 이상"}</p>
              <p>{"+1,000원"}</p>
            </li>
          </ul>
        </span>

        <ul className="location-class">
          <span>지역별</span>

          <li>
            <span>내당1동,내당2.3동,두류1.2동,비산2.3동,평리2동,평리4동</span>
            <span>{"+0원"}</span>
          </li>
          <li>
            <span>
              감삼동,남산1동,남산2동,대명10동,대명1동,대명2동,대명5동,비산5동,성내2동,성내3동,죽전동,평리6동
            </span>
            <span>{"+1,500원"}</span>
          </li>
          <li>
            <span>
              고성동,대명11동,대명6동,대명9동,대봉2동,본동,본리동,봉덕1동,봉덕3동,비산7동,성내1동,송현1동,송현2동,용산1동,원대동,이천동,장기동
            </span>
            <span>{"+3,000원"}</span>
          </li>
        </ul>

        <ul className="night-delivery">
          <span>야간 배송</span>
          <li>
            <span></span>
            <span>{"+0원"}</span>
          </li>
        </ul>
      </div>
      <span className="frame-bar"></span>
      <div className="ceo-info">
        <span className="order-title">사업자정보</span>
        <table className="ceo-info-table">
          <tbody>
            <tr>
              <td>대표자명</td>
              <td>장익준</td>
            </tr>
            <tr>
              <td>상호명</td>
              <td>장익준 가게</td>
            </tr>
            <tr>
              <td>사업자주소</td>
              <td>대구광역시 달서구 야외음악당로 47길 105, 1층 14호(두류동)</td>
            </tr>
            <tr>
              <td>사업자번호</td>
              <td>750-12-01211</td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default JangbojaDetail;
