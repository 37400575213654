import "components/order/style/OrderDetail.css";
import { SHOP_CUSTOM_IMG_PATH } from "App-config";

const OrderContents = ({
  totalAmt,
  payIndex,
  basket,
  basketDeleteHandler,
  priceChangeHandler,
  pointHandler,
}) => {
  const MenuListComponent = () => {
    const mList = basket.rows.map((menu, index) => {
      console.log("menu", menu);
      const parcelPrice = menu.totalPrice > 50000 ? 0 : 4500;
      /* const MenuCateList = ({ mCateList }) => {
        return mCateList.map((cate) => {
          return (
            <>
              <div className="order_option">
                <p className="option_area">{cate.moc_name}</p>
              </div>
              {cate.optionArray.map((opt) => {
                return (
                  <li style={{ display: "none" }}>
                    <span className="opction-class"></span>
                    <div className="opciton-price-box">
                      <span className="opction-name">
                        <p>└</p>
                        {opt.mo_name}
                      </span>
                      <span>
                        <p>
                          {opt.mo_price
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                          <span className="font-weght-light">원</span>
                        </p>
                        <p>
                          {opt.mo_count}
                          <span className="font-weght-light">개</span>
                        </p>
                      </span>
                    </div>
                  </li>
                );
              })}
            </>
          );
        });
      }; */

      return (
        <>
          <div className="order-menu-name-box" data-tprice={menu.totalPrice}>
            <div
              style={{
                width: "100px",
                height: "100px",
                borderRadius: "8px",
                border: "1px solid #BDBDBD",
                overflow: "hidden",
                padding: "0.2em",
                boxSizing: "border-box",
              }}
            >
              <img
                style={{
                  width: "100%;",
                  height: "100%",
                  minHeight: "100%",
                  objectFit: "cover",
                  border: "none",
                }}
                src={
                  SHOP_CUSTOM_IMG_PATH +
                  menu.table_name?.replace("_", "") +
                  "/menu/" +
                  menu.m_img
                }
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                //justifyContent: "center",
                fontSize: "15px",
              }}
            >
              <div
                className="menu-name"
                style={{
                  textAlign: "left",
                }}
              >
                {menu.m_name}
              </div>

              <div
                className="menu-option"
                style={{
                  textAlign: "left",
                }}
              >
                &nbsp;
              </div>

              <div
                className="menu-price"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "0.3em",
                }}
              >
                {basket.s_idx != "12527" ? (
                  <>
                    <p>
                      {menu.optionCateArray.length > 0
                        ? menu.totalPrice
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        : menu.m_price
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      {""}
                      <span className="font-weght-light2">원</span>
                    </p>
                    /
                    <p>
                      {menu.m_count}
                      <span className="font-weght-light2">개</span>
                    </p>
                    <button
                      className="order-cancel-button"
                      onClick={() => {
                        basketDeleteHandler(menu.bm_idx);
                        pointHandler.pointReset();
                      }}
                      style={{ display: "none" }}
                    ></button>
                  </>
                ) : (
                  <>
                    <p>
                      <input
                        type="text"
                        style={{ textAlign: "right" }}
                        placeholder={menu.totalPrice}
                        onKeyDown={(event) => {
                          if (
                            !(
                              (event.keyCode >= 48 && event.keyCode <= 57) ||
                              event.keyCode === 8 ||
                              event.keyCode === 46
                            )
                          ) {
                            event.preventDefault();
                          }
                        }}
                        onChange={(event) => {}}
                        onBlur={(event) => {
                          event.target.value = event.target.value.replace(
                            /[^0-9]/g,
                            ""
                          );
                          priceChangeHandler(menu.bm_idx, event.target.value);
                          pointHandler.pointReset();
                        }}
                      />
                      <span className="font-weght-light2">원</span>
                    </p>
                    <p>
                      {menu.m_count}
                      <span className="font-weght-light2">개</span>
                    </p>
                    <button
                      className="order-cancel-button"
                      onClick={() => {
                        basketDeleteHandler(menu.bm_idx);
                        pointHandler.pointReset();
                      }}
                      style={{ display: "none" }}
                    ></button>
                  </>
                )}
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "0.3em",
                }}
              >
                <div>배송비</div>
                <div>
                  {parcelPrice
                    ? parcelPrice
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "원"
                    : "무료배송"}
                </div>
              </div>
            </div>
          </div>
        </>
      );
    });

    return <ul className="order-content-list">{mList}</ul>;
  };
  return (
    <div className="order-content">
      {/* <div className="underline"></div> */}
      <div className="wrap_frame">
        <div className="wrap_frame-wrap">
          <div className="order-content-title">
            <span className="order-title-light">주문상품</span>
            {/* <button className="drop-down"></button> */}
          </div>
          {basket && <MenuListComponent />}
        </div>
      </div>
    </div>
  );
};

export default OrderContents;
