import "components/common/style/EmailRefused.css";

const EmailRefused = () => {
  return (
    <section className="email-Refused">
      <div className="email-note">
        <h2>이메일정보무단수집거부</h2>
        <hr></hr>
        <span>
          <b>
            본 웹 사이트에 게시된 이메일 주소가 전자우편 수집 프로그램이나 그
            밖의 기술적 장치를 이용하여 무단으로 수집되는 것을 거부하며 이를
            위반 시 정보통신망법에 의해 형사 처벌됨을 유념하시기 바랍니다.
          </b>
        </span>
        <h3>정보통신망 이용촉진 및 정보호 등에 관한 법률</h3>
        <p>제50조의2(전자우편주소의 무단 수집행위 등 금지)</p>
        <p>
          ① 누구든지 인터넷 홈페이지 운영자 또는 관리자의 사전 동의 없이 인터넷
          홈페이지에서 자동으로 전자우편주소를 수집하는 프로그램이나 그 밖의
          기술적 장치를 이용하여 전자우편주소를 수집하여서는 아니 된다.
        </p>
        <p>
          ② 누구든지 제1항을 위반하여 수집된 전자우편주소를 판매·유통하여서는
          아니 된다.
        </p>
        <p>
          ③ 누구든지 제1항과 제2항에 따라 수집·판매 및 유통이 금지된
          전자우편주소임을 알면서 이를 정보 전송에 이용하여서는 아니 된다.
          제74조(벌칙)
        </p>
        <p>
          ① 다음 각 호의 어느 하나에 해당하는 자는 1년 이하의 징역 또는 1천만원
          이하의 벌금에 처한다.
        </p>
        <p>
          5. 제50조의2를 위반하여 전자우편주소를 수집·판매·유통하거나 정보
          전송에 이용한 자
        </p>
      </div>
    </section>
  );
};

export default EmailRefused;
