import { Link } from "react-router-dom";

import "components/common/style/Footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-inner">
        <div className="footer-left">
          <ul className="footer-left-list">
            <li>
              <p>
                <Link to="/userAgree" className="page-move">
                  이용약관
                </Link>
                <button
                  className="popup-show"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    window.open(
                      "/UserAgree",
                      "이용약관",
                      "width=580, height=600, top=30,resizable=no"
                    )
                  }
                >
                  이용약관
                </button>
              </p>
              <p>
                <Link to="/personalInfo" className="page-move">
                  개인정보처리방침
                </Link>
                <button
                  className="popup-show"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    window.open(
                      "/personalInfo",
                      "개인정보처리방침",
                      "width=580, height=600, top=30,resizable=no"
                    )
                  }
                >
                  개인정보처리방침
                </button>
              </p>
              <p>
                <Link to="/emailRefused" className="page-move">
                  이메일무단수집거부
                </Link>
                <button
                  className="popup-show"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    window.open(
                      "/emailRefused",
                      "이메일무단수집거부",
                      "width=580, height=600, top=30,resizable=no"
                    )
                  }
                >
                  이메일무단수집거부
                </button>
              </p>
              <p>
                <button
                  className="popup-show"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    window.open("https://988.co.kr/kiikey", "원격지원받기")
                  }
                >
                  원격지원프로그램
                </button>
              </p>
            </li>
            <li>
              <p>
                <Link
                  to="route"
                  target="_blank"
                  onClick={(event) => {
                    event.preventDefault();
                    window.open(
                      "https://www.facebook.com/%ED%82%A4%ED%82%A4Kiikey-347749546463944/",
                      "_blank"
                    );
                  }}
                >
                  <i className="fa-brands fa-facebook-f"></i>
                </Link>
              </p>
              <p>
                <Link
                  to="route"
                  target="_blank"
                  onClick={(event) => {
                    event.preventDefault();
                    window.open(
                      "https://www.instagram.com/kiikey_official/",
                      "_blank"
                    );
                  }}
                >
                  <i className="fa-brands fa-instagram"></i>
                </Link>
              </p>
              <p>
                <Link
                  to="route"
                  target="_blank"
                  onClick={(event) => {
                    event.preventDefault();
                    window.open(
                      "https://www.youtube.com/channel/UCamgbLiy4BksCmxnimlaWEg",
                      "_blank"
                    );
                  }}
                >
                  <i className="fa-brands fa-youtube"></i>
                </Link>
              </p>
            </li>
          </ul>
        </div>
        <div className="footer-right">
          <ul className="footer-right-list">
            <li>주식회사 이쁨 | 주소 : 대구광역시 서구 고성로65</li>
            <li>대표 : 김동주 | 개인정보보호책임자 : 조은비</li>
            <li>대표전화 : 1577-8596 / 010-2048-7649</li>
            <li
              style={{ cursor: "pointer" }}
              onClick={() =>
                window.open(
                  "http://www.ftc.go.kr/bizCommPop.do?wrkr_no=7148802177",
                  "사업자등록번호조회",
                  "width=580, height=600, top=30"
                )
              }
            >
              사업자등록번호: 714-88-02177 | 통신판매업신고: 2021-대구서구-0520
            </li>
          </ul>
          <span className="copyright">
            COPYRIGHT 2021. KIIKEY inc. ALL RIGHTS RESERVED.
          </span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
