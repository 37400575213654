import BorderItemNotFound from "components/common/BorderItemNotFound";

const PageComponent = ({ currentPage, pageList, setCurrentPage }) => {
  return (
    <ul className="daps-paging-list">
      {currentPage > 1 ? (
        <button
          className="peging-left-button"
          onClick={() => setCurrentPage(1)}
        />
      ) : (
        ""
      )}

      {pageList}

      {currentPage === pageList.length ? (
        ""
      ) : (
        <button
          className="peging-right-button"
          onClick={() => setCurrentPage(pageList.length)}
        />
      )}
    </ul>
  );
};
const Pagination = ({
  type,
  totalCount,
  limit,
  currentPage,
  setCurrentPage,
}) => {
  let pageList = [];
  for (let index = 0; index < totalCount / limit; index++) {
    pageList.push(
      <li
        style={
          currentPage === index + 1
            ? { backgroundColor: "var(--kk-color-Ymain)", color: "#fff" }
            : {}
        }
        key={index}
        onClick={() => setCurrentPage(index + 1)}
      >
        {index + 1}
      </li>
    );
  }
  return (
    <>
      {totalCount > 0 ? (
        <PageComponent
          currentPage={currentPage}
          pageList={pageList}
          setCurrentPage={setCurrentPage}
          totalCount={totalCount}
          limit={limit}
        />
      ) : (
        <BorderItemNotFound type={type} />
      )}

      {currentPage < totalCount / limit && (
        <button
          className="mobile-scroll-button"
          onClick={() => setCurrentPage(currentPage + 1)}
        >
          리스트 더 보기
        </button>
      )}
    </>
  );
};

export default Pagination;
