import React, { useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";

import LoginLogo from "src_assets/kiikey_logo_db.png";

import "components/account/style/Login.css";

const SigninWebView = ({
  isLogin,
  loginId,
  loginEvent,
  loginPw,
  validLoginPwCheck,
  validLoginIdCheck,
  closePopupEvent,
}) => {
  const navigate = useNavigate();

  const loginPwRef = useRef();

  useEffect(() => {
    if (isLogin) {
      // 뒤로가기로 페이지이동시 광고닫기
      closePopupEvent();
      navigate("/order");
    }
    if (loginId) {
      loginPwRef.current.focus();
    }
  }, []);

  return (
    <section className="login">
      <form noValidate onSubmit={loginEvent} className="login-form">
        <div className="login-logo">
          <img src={LoginLogo} className="kk-logo-large" alt="로그인로고" />
        </div>
        <div className="login-input">
          <span className="login-id">
            <label htmlFor="loingId">아이디</label>
            <input
              type="text"
              onChange={validLoginIdCheck}
              name="loginId"
              id="loginId"
              value={loginId}
            ></input>
          </span>
          <span className="login-password">
            <label htmlFor="loginPw">비밀번호</label>
            <input
              ref={loginPwRef}
              type="password"
              onChange={validLoginPwCheck}
              name="loginPw"
              id="loginPw"
              value={loginPw}
            ></input>
          </span>
        </div>
        <ul className="account-support">
          <li>
            <Link to="/account/idFind">아이디 찾기</Link>
          </li>
          <li>
            <Link to="/account/pwFind">비밀번호 찾기</Link>
          </li>
          <li>
            <Link to="/account/signupRoute">회원가입</Link>
          </li>
        </ul>
        <button type="submit" className="login-button">
          로그인
        </button>
      </form>
    </section>
  );
};

export default SigninWebView;
