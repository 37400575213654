import "components/order/style/OrderDetail.css";
import { useEffect, useRef, useState } from "react";

const OrderPayType = ({
  totalAmt,
  myPoint,
  usePoint,
  pointChangeHandler,
  userInfo,
  isNotMeet,
}) => {
  const [onlyPointFlag, setOnlyPointFlag] = useState(false);
  const [receiptType, setReceiptType] = useState(0);

  const [phoneNum1, setPhoneNum1] = useState("");
  const [phoneNum2, setPhoneNum2] = useState("");
  const [phoneNum3, setPhoneNum3] = useState("");

  const balChangeHandler = (type) => {
    if (type == 0) {
      document.getElementById("balg").innerText = "휴대폰번호로 발급";
    } else {
      document.getElementById("balg").innerText = "사업자번호로 발급";
    }
  };

  useEffect(() => {
    if (!totalAmt) return;
    if (totalAmt == usePoint) {
      setOnlyPointFlag(true);
    } else {
      setOnlyPointFlag(false);
    }
  }, [usePoint]);
  return (
    <>
      <div className="payment_frame">
        <div className="wrap_frame">
          <div className="payment">
            <span
              className="payment-title"
              style={{
                fontSize: "18px",
              }}
            >
              결제수단
            </span>

            <NewOrderUsePoint
              totalAmt={totalAmt}
              myPoint={myPoint}
              usePoint={usePoint}
              pointChangeHandler={pointChangeHandler}
              userInfo={userInfo}
            />
            <ul
              className="payment-list"
              style={{
                marginTop: "1em",
                display: onlyPointFlag ? "none" : "flex",
              }}
            >
              <input
                type="radio"
                id="payment-credit"
                name="payment-type"
                value="11"
                // defaultChecked
                //disabled="true"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  alert("준비중입니다.");
                  return;
                  const countInfo = document.querySelector(
                    ".payment-receipt-layer"
                  );
                  countInfo.style.display = "none";
                  //alert("준비중입니다");
                }}
              />
              <label htmlFor="payment-credit">
                <div>
                  <img />
                  <span>카드결제</span>
                </div>
              </label>
              <input
                type="radio"
                id="payment-phone"
                name="payment-type"
                value="31"
                //   disabled= "true"
                defaultChecked={true}
                onClick={() => {
                  const countInfo = document.querySelector(
                    ".payment-receipt-layer"
                  );
                  countInfo.style.display = "block";
                }}
              />
              <label htmlFor="payment-phone">
                <div>
                  <img />
                  <span>계좌이체</span>
                </div>
              </label>
              {/* 비활성화 */}
              <input
                type="radio"
                id="payment-meet"
                name="payment-type"
                value="2"
                disabled={isNotMeet}
              />
              <label
                htmlFor="payment-meet"
                style={{ display: !isNotMeet ? "" : "none" }}
              ></label>
              <input
                type="radio"
                id="payment-cash"
                name="payment-type"
                value="1"
                disabled={isNotMeet}
              />
              <label
                htmlFor="payment-cash"
                style={{ display: !isNotMeet ? "" : "none" }}
              ></label>
              {/* 비활성화  끝*/}
            </ul>
            <div id="countinfo" style={{ display: "none" }}>
              <p className="Precautions">
                {
                  <span className="font-weight">
                    {" "}
                    입금계좌:우리은행 1005-503-785077 백옥생코리아(주)
                  </span>
                }
              </p>
              <p className="Precautions">
                {
                  <span className="font-weight">
                    {" "}
                    반드시 주문자 이름으로 입금부탁드립니다
                  </span>
                }
              </p>
              <p className="Precautions">
                {
                  <span className="font-weight">
                    {" "}
                    입금 확인이 된 이후 출고처리가 진행됩니다
                  </span>
                }
              </p>
              <p className="Precautions">
                {
                  <span className="font-weight">
                    {" "}
                    포인트 사용 후 총 결제금액을 입금해 주세요
                  </span>
                }
              </p>
            </div>
            <p className="Precautions">
              {isNotMeet && (
                <>
                  {/* <span className="font-weight"> 결제 수단을 다시 선택</span>
                해주세요! */}
                </>
              )}
            </p>
          </div>
        </div>
      </div>

      <div
        className="payment-receipt-layer"
        style={{
          marginTop: "1em",
          borderRadius: "8px",
          border: "1px solid #E0E0E0",
          padding: "1em",
          display: onlyPointFlag ? "none" : "block",
        }}
      >
        <div className="payment-receipt-container">
          <label
            onChange={(e) => {
              console.log(e);
              const parent = e.currentTarget.parentNode;
              const target = e.target;
              parent.querySelector(".payment-receipt-detail").style.display =
                target.checked ? "block" : "none";
            }}
          >
            <input type="checkbox" />
            <div>
              <img />
              <span style={{ paddingTop: "0.3em" }}>현금영수증신청</span>
            </div>
            <div
              style={{
                paddingTop: "0.3em",
                borderRadius: "8px",
                border: "1px solid #BDBDBD",
                backgroundColor: "#BDBDBD",
                boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)",
                color: "#fff",
                fontSize: "15px",
                width: "70px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                boxSizing: "border-box",
              }}
            >
              확인
            </div>
          </label>
          <div className="payment-receipt-detail">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "1em",
              }}
            >
              <label>
                <input
                  type="radio"
                  name="receipt-type"
                  checked={receiptType == 0}
                  onChange={(e) => {
                    const target = e.currentTarget;
                    if (target.checked) {
                      setReceiptType(0);
                      balChangeHandler(0);
                    }
                  }}
                />
                <div>
                  <img />
                  <span>개인소득공제</span>
                </div>
              </label>

              <label>
                <input
                  type="radio"
                  name="receipt-type"
                  checked={receiptType == 1}
                  onChange={(e) => {
                    const target = e.currentTarget;
                    if (target.checked) {
                      setReceiptType(1);
                      balChangeHandler(1);
                    }
                  }}
                />
                <div>
                  <img />
                  <span>사업자지출증빙</span>
                </div>
              </label>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "0.3em",
                marginTop: "0.5em",
              }}
            >
              <div id="balg">휴대폰번호로 발급</div>
              <div
                className="payment-receipt-detail-phone"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "0.5em",
                }}
              >
                <input
                  length="3"
                  onInput={(e) => {
                    const target = e.currentTarget;
                    const value = target.value
                      .replace(/[^0-9]/g, "")
                      .slice(0, 3);

                    setPhoneNum1(value);
                  }}
                  style={{ width: "100%" }}
                  value={phoneNum1}
                />
                -
                <input
                  length="4"
                  onInput={(e) => {
                    const target = e.currentTarget;
                    const value = target.value
                      .replace(/[^0-9]/g, "")
                      .slice(0, 4);

                    setPhoneNum2(value);
                  }}
                  style={{ width: "100%" }}
                  value={phoneNum2}
                />
                -
                <input
                  length="4"
                  onInput={(e) => {
                    const target = e.currentTarget;
                    const value = target.value
                      .replace(/[^0-9]/g, "")
                      .slice(0, 4);

                    setPhoneNum3(value);
                  }}
                  style={{ width: "100%" }}
                  value={phoneNum3}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const NewOrderUsePoint = ({
  totalAmt,
  myPoint,
  usePoint,
  userInfo,
  pointChangeHandler,
}) => {
  const 전액사용 = useRef(null);
  const 포인트 = useRef(null);
  return (
    <div
      class="payment-order-use-point-container"
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "0.5em",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div>마이포인트</div>
        <div>
          <span>
            {(myPoint - usePoint)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
          </span>
          P
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "1em",
          whiteSpace: "nowrap",
        }}
      >
        <label
          className="payment-order-use-point-label"
          style={{
            width: "0",
            flexGrow: "1",
          }}
        >
          <input
            ref={포인트}
            id="point-use-input"
            onInput={(e) => {
              const target = e.currentTarget;
              let price =
                totalAmt + (0 < totalAmt && totalAmt <= 50000 ? 4500 : 0);
              let value = target.value;
              value = parseInt(value.replace(/[^0-9]/g, ""));

              if (!isNaN(value) && value > 0) {
                if (value > price) {
                  value = price;
                }

                if (value > myPoint) {
                  value = myPoint;
                }
                전액사용.current.innerText = "사용";
                //pointChangeHandler(-1, value);
              } else {
                전액사용.current.innerText = "전액사용";
                pointChangeHandler(-1, 0);
              }

              let showValue = value
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              //value = value + " P";

              target.value = showValue;
            }}
            defaultValue={0}
            //value={usePoint.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          />
          <span>P</span>
        </label>

        <div
          ref={전액사용}
          style={{
            borderRadius: "8px",
            background: "#BDBDBD",
            boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)",
            color: "#fff",
            fontFamily: "Inter",
            fontStyle: "normal",
            fontSize: "15px",
            fontWeight: "500",
            lineHeight: "1.5",
            padding: "0.3em 0.5em 0.1em",
            width: "70px",
            boxSizing: "border-box",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          onClick={(e) => {
            const target = e.currentTarget;
            if (target.innerText == "사용") {
              pointChangeHandler(
                -1,
                parseInt(포인트.current.value.replace(/[^0-9]/g, ""))
              );
              target.innerText = "취소";
            } else if (target.innerText == "전액사용") {
              let price =
                totalAmt + (0 < totalAmt && totalAmt <= 50000 ? 4500 : 0);
              if (myPoint < price) {
                price = myPoint;
              }
              pointChangeHandler(-1, price);
              포인트.current.value = price
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              target.innerText = "취소";
            } else if (target.innerText == "취소") {
              포인트.current.value = 0;
              pointChangeHandler(-1, 0);
              target.innerText = "전액사용";
            }
          }}
        >
          전액사용
        </div>
      </div>
    </div>
  );
};

export default OrderPayType;
