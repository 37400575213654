import { useState } from "react";

import { delScrap } from "service/ADSService";

import Pagination from "components/common/Pagination";

import "components/mykiikey/style/Adscrap.css";
import "components/mykiikey/style/Mykiikey.css";
import stateUtilityInstance from "../../TestClass";
import { useEffect } from "react";

const ADSscrap = ({
  isMobile,
  scrapList,
  setIsNotQuiz,
  setAD,
  setIsRunning,
  setScrapList,
  setWatchList,
}) => {
  const VIEW_LIMIT = 6;
  const [currentPage, setCurrentPage] = useState(1);
  const offset = (currentPage - 1) * VIEW_LIMIT;

  const scrapListLimit = isMobile
    ? scrapList.slice(0, offset + VIEW_LIMIT)
    : scrapList.slice(offset, offset + VIEW_LIMIT);

  const shareClickEvent = (item) => {
    alert("준비중인 기능입니다.");
  };

  const videoClickEvent = (item) => {
    setIsNotQuiz(true);
    setAD(item);
    setIsRunning(1);
  };

  const adInfoClickEvent = (item) => {
    alert("준비중인 기능입니다.");
  };

  const removeScrapClickEvent = (item) => {
    delScrap(item)
      .then((res) => {
        if (res.success) {
          alert(res.msg);
          setScrapList(res.data.scrapList);
          setWatchList(res.data.watchList);
        }
      })
      .catch((err) => {
        alert(err.msg);
        setScrapList(err.data?.scrapList);
        setWatchList(err.data?.watchList);
        console.error(err);
      });
  };

  useEffect (() => {
    stateUtilityInstance.setMobileHeaderTitle('광고 스크랩');
  })

  const liTagList = scrapListLimit.map((item, index) => {
    return (
      <li key={index}>
        <div className="adscrap-title-box">
          <span className="adscrap-title">{item.a_name}</span>
          <button
            className="scarp-list-delete"
            onClick={(e) => {
              e.preventDefault();
              removeScrapClickEvent(item);
            }}
          />
        </div>
        <div className="adscrap-info-box">
          <span className="adscrap-date">{item.sc_datetime}</span>
          <ul className="adscrap-button-list">
            <li>
              <button
                className="adscrap-share"
                onClick={() => shareClickEvent(item)}
              >
                공유
              </button>
            </li>
            <li>
              <button
                className="adscrap-video"
                onClick={() => videoClickEvent(item)}
              >
                영상
              </button>
            </li>
            <li>
              <button
                className="adscrap-adinfo"
                onClick={() => adInfoClickEvent(item)}
              >
                정보
              </button>
            </li>
          </ul>
        </div>
      </li>
    );
  });

  return (
    <div className="daps-frame">
      <ul className="daps-ul-list">{liTagList}</ul>
      <Pagination
        isMobile={isMobile}
        limit={VIEW_LIMIT}
        offset={offset}
        type={"scrap"}
        totalCount={scrapList.length}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </div>
  );
};

export default ADSscrap;
