import { useState, useEffect } from "react";

import { getPointHistoryList } from "service/AccountService";

import Pagination from "components/common/Pagination";

import dateimg from "src_assets/period.png";

import "components/mykiikey/style/Myinfo.css";
import "components/mykiikey/style/Mykiikey.css";
import "components/mykiikey/style/Pointhistory.css";
import stateUtilityInstance from "../../TestClass";

const Pointhistory = ({
  term,
  setTerm,
  pointHistoryList,
  setPointHistoryList,
  isMobile,
}) => {
  const VIEW_LIMIT = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const offset = (currentPage - 1) * VIEW_LIMIT;
  const pointHistoryListLimit = isMobile
    ? pointHistoryList.slice(0, offset + VIEW_LIMIT)
    : pointHistoryList.slice(offset, offset + VIEW_LIMIT);

  const liTagList = pointHistoryListLimit.map((item, index) => {
    return (
      <ul key={index} className="history">
        <li>{item.ph_datetime}</li>
        <li>{item.ph_content}</li>
        <li>{item.ph_point}</li>
        <li>{item.ph_current_point}</li>
      </ul>
    );
  });

  const TimestampArea = ({ startTime, endTime }) => {
    const sDate = new Date(startTime);
    const eDate = new Date(endTime);
    return (
      <div className="period">
        <img src={dateimg} alt="달력이미지"></img>
        <span>조회기간</span>
        <p>
          {sDate.getFullYear()}년 {sDate.getMonth() + 1}월 {sDate.getDate()}일
        </p>
        <p>~</p>
        <p>
          {eDate.getFullYear()}년 {eDate.getMonth() + 1}월 {eDate.getDate()}일
        </p>
      </div>
    );
  };

  useEffect(() => {
    stateUtilityInstance.setMobileHeaderTitle('포인트 사용 내역');
    getPointHistoryList({ term })
      .then((res) => {
        if (res.success) {
          setPointHistoryList(res.data);
        }
      })
      .catch(console.error);
  }, [term]);

  return (
    <div className="daps-frame">

      {liTagList.length > 0 && (
        <div className="lookup">
          <h3>조회기간</h3>
          <div className="lookup-list">
            <input
              type="radio"
              id="week"
              name="date"
              onClick={() => setTerm(1)}
              defaultChecked={term === 1 ? true : false}
            />
            <label htmlFor="week">일주일</label>
            <input
              type="radio"
              id="month"
              name="date"
              onClick={() => setTerm(2)}
              defaultChecked={term === 2 ? true : false}
            />
            <label htmlFor="month">한달</label>
            <input
              type="radio"
              id="sixmonth"
              name="date"
              onClick={() => setTerm(3)}
              defaultChecked={term === 3 ? true : false}
            />
            <label htmlFor="sixmonth">6개월</label>
          </div>
        </div>
      )}

      {liTagList.length > 0 && (
        <TimestampArea
          startTime={
            pointHistoryListLimit.length > 0 &&
            pointHistoryListLimit[0].startTime
          }
          endTime={
            pointHistoryListLimit.length > 0 && pointHistoryListLimit[0].endTime
          }
        />
      )}

      {liTagList.length > 0 && (
        <div className="use-list">
          <ul className="use-title">
            <li>일시</li>
            <li>내역</li>
            <li>입출금</li>
            <li>잔액</li>
          </ul>
          {liTagList}
        </div>
      )}

      <Pagination
        isMobile={isMobile}
        limit={VIEW_LIMIT}
        offset={offset}
        type={"pointHistory"}
        totalCount={pointHistoryList.length}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </div>
  );
};

export default Pointhistory;
