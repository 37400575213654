// 이상윤 220720 문의 따로 컴포넌트로 뺌
import "components/order/style/Beautydetail.css";

import empty from "src_assets/mascot-g.png";

const BeautyReview = ({ reviews }) => {
  const ReviewListComponent = () => {
    return reviews.map((review) => (
      <>
        {/* 유저가 적는 리뷰입니다. */}
        <div className="user">
          <div className="user-info">
            <span className="user-name">{review.mb_id}</span>
            <span>{review.r_datetime.split(" ")[0]}</span>
          </div>
          <div className="user-Questions">
            <span readOnly className="que-text">
              {review.r_content}
            </span>
          </div>
        </div>
        {/* 사장이 적는 리뷰입니다. */}
        {review.r_ceo_content && (
          <div className="store">
            <div className="user-info">
              <span className="user-name">
                <b>└ </b>사장님
              </span>
              <span>{review.r_ceo_comment_datetime}</span>
            </div>
            <div className="store-answer">
              <span readOnly className="que-text">
                {review.r_ceo_content}
              </span>
            </div>
          </div>
        )}
      </>
    ));
  };

  return (
    <section className="detail-section">
      {reviews && <ReviewListComponent />}
      {reviews && reviews.length < 1 && (
        <div className="empty-text-area">
          <div className="empty-imgg">
            <img src={empty} alt="empty"></img>
          </div>
          <div className="empty-text">
            <span>등록된 리뷰가 없습니다.</span>
          </div>
        </div>
      )}
    </section>
  );
};

export default BeautyReview;
