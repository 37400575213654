import "components/common/style/Header.css";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import stateUtilityInstance from "../../TestClass";
import {
  isKiikeyApp,
  IS_ANDROID,
  IS_IOS,
  callNoti,
  callPoint,
} from "App-config";
import { async } from "@firebase/util";
import database from "src_assets/database_1.png";
import notificationsActive from "src_assets/notifications-active.png";
import localMall from "src_assets/local-mall.png";
import line1 from "src_assets/line-1.svg";
import { call } from "service/ApiService";

const Header = () => {
  const navigate = useNavigate();
  //const title = stateUtilityInstance.getPropertyByName('title');

  const backBtnClickEvent = (e) => {
    console.log("클릭");
    //console.log(window.history.length);
    //console.dir(window.history);
    // let test = window.NativeInterface.historyBack();
    // console.log(test);
    // console.log(window.location.pathname);
    // console.dir(window.history.state);
    if (window.location.pathname == "/order/payresult") {
      navigate("/order", { replace: true });
      /* console.log("홈");
      if (isKiikeyApp) {
        if (IS_IOS) {
          window.webkit.messageHandlers.callBackHandler.postMessage({
            action: "goClose",
          });
        }
        if (IS_ANDROID) {
          if (window.location.pathname != "/order") {
            window.KiikeyWebView.goBack();
          }
        }
      } else {
        navigate("/order", { replace: true });
      } */
    }

    if (window.location.pathname == "/order") {
      debugger;
      if (isKiikeyApp) {
        if (IS_IOS) {
          //if (window.location.pathname == "/order") {
          window.webkit.messageHandlers.callBackHandler.postMessage({
            action: "goClose",
          });
          //}
        }
        if (IS_ANDROID) {
          //if (window.location.pathname == "/order") {
          window.HeadApp.goBack();
          //}
        }
      }
      return;
    }

    // if(window.history.state && window.location.pathname == '/order/shop' && window.history.state.idx < 2){
    //   navigate('/order', {replace : true});
    // }

    // if(document.referrer.indexOf('php') != -1){
    //   navigate('/order', {replace : true});
    // }

    if (isKiikeyApp) {
      if (IS_IOS) {
        //if (window.location.pathname == "/order") {
        window.webkit.messageHandlers.callBackHandler.postMessage({
          action: "goClose",
        });
        //}
      }
      if (IS_ANDROID) {
        //if (window.location.pathname == "/order") {
        window.HeadApp.goBack();
        //}
      }
    } else {
      navigate("/order", { replace: true });
    }

    /* if (window.history.state && window.history.state.idx > 0) {
    } else {
      debugger;
    } */
  };
  const goNoti = () => {
    if (isKiikeyApp) {
      if (IS_IOS) {
        window.webkit.messageHandlers.callBackHandler.postMessage({
          action: "setRes",
        });
      }
      if (IS_ANDROID) {
        window.HeadApp.setNoti();
      }
    }
  };
  const goPoint = () => {
    callPoint();
  };
  const goBasket = () => {
    if (window.location.pathname == "/order/confirm") return;

    navigate("/order/confirm");
  };
  return (
    <div className="header-mobile-top">
      <div
        className="header-mobile-top-button"
        onClick={backBtnClickEvent}
        style={{ display: "none" }}
      >
        <button></button>
      </div>
      <div className="header-mobile-top-text">
        <span id="mobileTitle"></span>
      </div>
      <img
        className="database"
        alt="Database"
        src={database}
        onClick={(e) => goPoint(e)}
      />
      <img
        className="notifications-active"
        onClick={(e) => goNoti(e)}
        alt="Notifications active"
        src={notificationsActive}
      />
      <div className="overlap" onClick={(e) => goBasket(e)}>
        <img className="local-mall" alt="Local mall" src={localMall} />
        <div className="text-wrapper-3"></div>
      </div>
      <img
        className="line"
        alt="Line"
        src={line1}
        style={{
          height: "2px",
          left: "0",
          position: "absolute",
          bottom: "0",
          width: "100%",
          zIndex: "999",
          objectFit: "cover",
          padding: "0% 5%",
          boxSizing: "border-box",
        }}
      />
    </div>
  );
};

export default Header;
