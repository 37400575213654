import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { checkPlatform, PHONE_REG } from "App-config";

import { auth, signInWithPhoneNumber } from "service/firebase";
import { duplicatePhoneCheck, findLoginId } from "service/AccountService";

import AuthFindIdInputComponent from "components/account/AuthFindIdInputComponent";
import AuthFindIdComponent from "components/account/AuthFindIdComponent";

import "components/account/style/IdFind.css";

const IdFind = ({ setIdFindResultId, setIdFindValidCheck }) => {
  const navigate = useNavigate();

  const userPhoneInput = useRef();

  const [authView, setAuthView] = useState(false);
  const [authInputView, setAuthInputView] = useState(false);

  const isButtonView = !authView && !authInputView ? true : false;

  const phoneInputDisabled = (isDisable) => {
    userPhoneInput.current.disabled = isDisable;
  };

  const phoneAuthHandler = async (e) => {
    e.preventDefault();
    let _userPhone = userPhoneInput.current.value.trim();
    if (!_userPhone || !RegExp(PHONE_REG).test(_userPhone)) {
      userPhoneInput.current.focus();
      return;
    }

    _userPhone = _userPhone.replace("-", "");
    _userPhone = _userPhone.replace("-", "");

    let pType = "4";
    const rType = "3";
    if (checkPlatform() === "mobile") {
      pType = "3";
    }
    const isCheck = await duplicatePhoneCheck({ _userPhone, pType, rType })
      .then((res) => {
        console.log(res);
        return true;
      })
      .catch((err) => {
        alert(err.msg);
        console.error(err);
        return false;
      });
    if (!isCheck) return;

    if (_userPhone) {
      _userPhone = _userPhone.replace("010", "+8210");
    }

    phoneInputDisabled(true);
    setAuthView(true);

    signInWithPhoneNumber(auth, _userPhone, window.recaptchaVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        console.log("signInWithPhoneNumber 1", confirmationResult);
        setAuthView(false);
      })
      .catch((error) => {
        console.log("signInWithPhoneNumber 2", error);
      })
      .finally(() => {
        console.log("signInWithPhoneNumber 3");
      });
  };

  useEffect(() => {
    userPhoneInput.current.focus();
  }, []);

  const submitAction = (e) => {
    e.preventDefault();
    let _userPhone = userPhoneInput.current.value.trim();

    _userPhone = _userPhone.replace("-", "");
    _userPhone = _userPhone.replace("-", "");

    findLoginId({ userPhone: _userPhone })
      .then((res) => {
        if (res.success) {
          setIdFindValidCheck(true);
          setIdFindResultId(res.data.mb_id);
          navigate("/account/idResult");
        }
      })
      .catch((err) => {
        phoneInputDisabled(false);
        setAuthInputView(false);
        alert(err.msg);
        console.error(err);
      });
  };

  return (
    <section className="id-find">
      <form className="id-find-form">
        <div className="form-title">아이디 찾기</div>
        <div className="id-input-area">
          <span className="id-note">
            아이디를 찾기 위해서 이름 및 생년월일이 필요합니다.
          </span>
          <div className="id-input">
            <span>
              <label htmlFor="userPhone">휴대전화</label>
              <input
                ref={userPhoneInput}
                type="tel"
                id="userPhone"
                placeholder="예) 010-1111-0000"
              />
            </span>

            <AuthFindIdInputComponent
              isView={authInputView}
              setAuthInputView={setAuthInputView}
              phoneInputDisabled={phoneInputDisabled}
              submitAction={submitAction}
            />
            <AuthFindIdComponent
              userPhoneInput={userPhoneInput.current}
              isView={authView}
              setAuthView={setAuthView}
              setAuthInputView={setAuthInputView}
              phoneInputDisabled={phoneInputDisabled}
            />
          </div>
        </div>
        {isButtonView && (
          <button className="join-btn-comfirm" onClick={phoneAuthHandler}>
            인증 요청
          </button>
        )}
      </form>
    </section>
  );
};

export default IdFind;
