import ShopMenuFood from "components/order/ShopMenuFoodComponent";

import ShopInfoComponentJustInfo from "components/order/ShopInfoComponentJustInfo";
import ShopReview from "components/order/ShopReview";

import "components/order/style/MenuIntro.css";
import "components/order/style/ShopMenuFood.css";
import "components/order/style/ShopMenuGrade.css";

// 가게 메뉴 탭 쪽 따로 컴포넌트로 뺌
const ShopMenuInfoJustInfo = ({
    shopInfo,
    menuList,
    menuRef,
    changeTab,
    setChangeTab,
    isCustomTable,
    setNavNumber,
    basketClickEvent,
}) => {
    return (
        <div className="shop-menu-info">
            {changeTab === 2 && (
                <>
                    <ShopInfoComponentJustInfo shopInfo={shopInfo} />
                </>
            )}
        </div>
    );
};

export default ShopMenuInfoJustInfo;
