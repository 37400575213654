import { useState, useEffect } from "react";

import { SHOP_CUSTOM_IMG_PATH } from "App-config";

import "components/order/style/categoryPart/CateTypeCategorySearch.css";

// import cateimg from "src_assets/order_test_img.png";

const CateTypeCategorySearch = ({
  shopMenu,
  setIsModalView,
  setModalData,
  searchKey,
  setSearchKey,
  searchMenu,
  setSearchMenu,
}) => {
  const [topCateList, setTopCateList] = useState();
  const [middleCateList, setMiddleCateList] = useState();
  const [botCateList, setBotCateList] = useState();

  const [cateName, setCateName] = useState("");
  const [menuList, setMenuList] = useState();

  useEffect(() => {
    setTopCateList(<TopCateListComponent />);
  }, []);

  const cateClickEvent = (e) => {
    e.currentTarget
      .closest("ul")
      .querySelectorAll("li")
      .forEach((ele) => {
        ele.className = "";
      });
    e.currentTarget.className = "active";
  };

  const TopCateListComponent = () => {
    return shopMenu.map((item, index) => (
      <li
        key={index}
        onClick={(e) => {
          cateClickEvent(e);
          const middleLiTagList = document.querySelectorAll(
            ".category-summary-list li"
          );
          if (middleLiTagList)
            middleLiTagList.forEach((ele) => {
              ele.className = "";
            });
          setMiddleCateList(
            <MiddleCateListComponent middleMenu={item.cateMRows} />
          );
          setBotCateList();
          setMenuList();
          setSearchKey("");
          setSearchMenu();
        }}
      >
        <span>{item.mc_name}</span>
      </li>
    ));
  };

  const MiddleCateListComponent = ({ middleMenu }) => {
    return middleMenu.map((item, index) => (
      <li
        key={index}
        onClick={(e) => {
          cateClickEvent(e);
          const botLiTagList = document.querySelectorAll(
            ".category-summary-cate-list li"
          );
          if (botLiTagList)
            botLiTagList.forEach((ele) => {
              ele.className = "";
            });
          setBotCateList(<BotCateListComponent botMenu={item.cateSRows} />);
          setMenuList();
          setSearchKey("");
          setSearchMenu();
        }}
      >
        <span>{item.mc_name}</span>
      </li>
    ));
  };

  const BotCateListComponent = ({ botMenu }) => {
    return botMenu.map((item, index) => (
      <li
        key={index}
        onClick={(e) => {
          cateClickEvent(e);
          setCateName(item.mc_name);
          setMenuList(<MenuListComponent menu={item.m_menus} />);
          setSearchKey("");
          setSearchMenu();
        }}
      >
        <span>{item.mc_name}</span>
      </li>
    ));
  };

  const MenuListComponent = ({ menu }) => {
    const minusClickEvent = (e) => {
      const target = e.target.nextElementSibling;
      let val = parseInt(target.innerText);
      if (isNaN(val)) val = 0;
      target.innerText = val > 0 ? val - 1 : val;
    };

    const plusClickEvent = (e) => {
      const target = e.target.previousElementSibling;
      let val = parseInt(target.innerText);
      if (isNaN(val)) val = 0;
      target.innerText = val + 1;
    };

    return menu.map((item, index) => (
      <li
        key={index}
        data-midx={item.m_idx}
        data-price={item.m_price}
        data-mname={item.m_name}
      >
        <div
          className="list-item-img"
          onClick={() => {
            try {
              setModalData(JSON.parse(item.m_multi_img));
              setIsModalView(true);
            } catch (error) {}
          }}
        >
          <img
            src={SHOP_CUSTOM_IMG_PATH + item.m_img}
            onError={defaultImgEvent}
            alt="리스트 이미지"
          ></img>
        </div>
        <div className="list-item-info">
          <div
            className="list-item-category-name"
            onClick={() => {
              try {
                setModalData(JSON.parse(item.m_multi_img));
                setIsModalView(true);
              } catch (error) {}
            }}
          >
            <span>{item.m_name}</span>
            <span>{item.m_price}원</span>
          </div>
          <div className="item-count-box">
            <div className="count">
              <button className="count-btn" onClick={minusClickEvent}>
                -
              </button>
              <p className="count-num">0</p>
              <button className="count-btn" onClick={plusClickEvent}>
                +
              </button>
            </div>
          </div>
        </div>
      </li>
    ));
  };

  const defaultImgEvent = (e) => {
    e.target.remove();
  };

  return (
    <section className="categoty-search-frame">
      <span className="order-title">카테고리 검색</span>
      {topCateList && (
        <div className="category-search-list-box">
          <ul className="category-search-list">{topCateList}</ul>
        </div>
      )}

      {searchMenu ? (
        <ul className="category-item-list">
          <span className="category-item-list-title">검색 : {searchKey}</span>
          <MenuListComponent menu={searchMenu} />
        </ul>
      ) : (
        <>
          {middleCateList && (
            <div className="category-summary-list-box">
              <ul className="category-summary-list">{middleCateList}</ul>
            </div>
          )}

          {botCateList && (
            <div className="category-summary-cate-list-box">
              <ul className="category-summary-cate-list">{botCateList}</ul>
            </div>
          )}

          {menuList && (
            <ul className="category-item-list">
              <span className="category-item-list-title">{cateName}</span>
              {menuList}
            </ul>
          )}
        </>
      )}
    </section>
  );
};

export default CateTypeCategorySearch;
