import nowloading from "src_assets/Nowloading.gif";

import "components/common/style/Loading.css";

function Loading() {
  return (
    <section className="loading-section">
      <div className="loading-box">
        <img src={nowloading} alt="로딩이미지"></img>
      </div>
    </section>
  );
}

export default Loading;
