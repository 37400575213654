import { useNavigate } from "react-router-dom";

import "components/account/style/IdResult.css";

const IdResult = ({
  setIdFindValidCheck,
  idFindResultId,
  setIdFindResultId,
  setLoginId,
}) => {
  const navigate = useNavigate();

  const onSubmitClickEvent = (e) => {
    e.preventDefault();

    setIdFindValidCheck(false);
    setIdFindResultId("");
    setLoginId(idFindResultId);

    if (e.target.value === "passFind") {
      navigate("/account/pwFind");
    } else {
      navigate("/account/signin");
    }
  };
  return (
    <section className="id-result">
      <form className="id-result-form">
        <div className="form-title">아이디 찾기 결과</div>
        <div className="id-result-area">
          <span className="form-note">
            고객님의 정보와 일치하는 아이디 입니다.
          </span>
          <div className="result-text">
            <span>
              <p>{idFindResultId}</p>
            </span>
          </div>
        </div>
        <button
          type="submit"
          className="ps-button"
          onClick={onSubmitClickEvent}
          value={"passFind"}
        >
          비밀번호 찾기
        </button>
        <button
          type="submit"
          className="login-button"
          onClick={onSubmitClickEvent}
          value={"login"}
        >
          로그인
        </button>
      </form>
    </section>
  );
};

export default IdResult;
