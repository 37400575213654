import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getOrderDetail } from "service/OrderService";
import { SHOP_CUSTOM_IMG_PATH } from "App-config";
import stateUtilityInstance from "TestClass";

const OrderReceipt = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [orderDetail, setOrderDetail] = useState(null);

  useEffect(() => {
    stateUtilityInstance.setMobileHeaderTitle("현금영수증", true);
    if (params.odIdx) {
      getOrderDetail({ od_idx: params.odIdx })
        .then((res) => {
          if (!!res.data) {
            console.log(res.data);
            setOrderDetail(res.data);
          }
        })
        .catch((err) => {
          console.error(err);
          navigate("/mykiki/orderList");
        });
    }
  }, []);

  const getSecretPhoneNumber = (phone) => {
    const secretPhone = phone.replace(/(\d{3})(\d{4})(\d{4})/, "$1****$3");
    return secretPhone;
  };

  const getDateToString = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hour = date.getHours();
    const minute = date.getMinutes();
    const second = date.getSeconds();

    const formattedDate = `${year}/${month.toString().padStart(2, "0")}/${day.toString().padStart(2, "0")} ${hour.toString().padStart(2, "0")}:${minute.toString().padStart(2, "0")}:${second.toString().padStart(2, "0")}`;
    return formattedDate;
  };

  const getOrderName = () => {
    let newArr = [];
    orderDetail.rows.forEach((menu, index) => {
      if (menu.m_name) {
        newArr.push(menu.m_name);
      }
    });

    return (
      newArr[0] + (newArr.length > 1 ? " 외 " + (newArr.length - 1) + "건" : "")
    );
  };

  if (!orderDetail) return <></>;

  return (
    <div style={mainContainerStyle}>
      <div>
        <div style={mainLabelStyle}>현금영수증 발행정보</div>
        <div style={contentContainerStyle}>
          <div style={contentRowStyle}>
            <div>신청자 식병정보</div>
            <div>{getSecretPhoneNumber(orderDetail.mbInfo.mb_phone)}</div>
          </div>

          <div style={contentRowStyle}>
            <div>거래유형</div>
            <div>현금결제(소득공제)</div>
          </div>

          <div style={contentRowStyle}>
            <div>거래일시</div>
            <div>
              {getDateToString(new Date(orderDetail.order.od_datetime))}
            </div>
          </div>

          <div style={contentRowStyle}>
            <div>승인번호</div>
            <div>{params.odIdx}</div>
          </div>
        </div>
      </div>

      <div>
        <div style={mainLabelStyle}>구매정보</div>
        <div style={contentContainerStyle}>
          <div style={contentRowStyle}>
            <div>주문번호</div>
            <div>{orderDetail.order.m_od_idx}</div>
          </div>

          <div style={contentRowStyle}>
            <div>상품명</div>
            <div>{getOrderName()}</div>
          </div>
        </div>
      </div>

      <div>
        <div style={mainLabelStyle}>결제금액정보</div>
        <div style={contentContainerStyle}>
          <div style={contentRowStyle}>
            <div>공급가액</div>
            <div>
              {Math.floor((parseInt(orderDetail.order.sp_product_amt) / 10) * 9)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              원
            </div>
          </div>

          <div style={contentRowStyle}>
            <div>부가세</div>
            <div>
              {Math.floor(parseInt(orderDetail.order.sp_product_amt) / 10)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              원
            </div>
          </div>

          <div style={contentRowStyle}>
            <div>합계금액</div>
            <div style={{ color: "#f00" }}>
              {orderDetail.order.sp_product_amt
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              원
            </div>
          </div>
        </div>
      </div>

      <div>
        <div style={mainLabelStyle}>가맹점정보</div>
        <div style={contentContainerStyle}>
          <div style={contentRowStyle}>
            <div>상호</div>
            <div>정산바이오텍㈜</div>
          </div>

          <div style={contentRowStyle}>
            <div>사업자등록번호</div>
            <div>791-81-02307</div>
          </div>
        </div>
      </div>

      <div>
        <div style={mainLabelStyle}>이용상점정보</div>
        <div style={contentContainerStyle}>
          <div style={contentRowStyle}>
            <div>판매자상호</div>
            <div>{orderDetail.order.s_origin}㈜</div>
          </div>

          <div style={contentRowStyle}>
            <div>대표자명</div>
            <div>이미영</div>
          </div>

          <div style={contentRowStyle}>
            <div>사업자등록번호</div>
            <div>811-88-01391</div>
          </div>

          <div style={contentRowStyle}>
            <div>사업장소재지</div>
            <div style={{ textAlign: "right" }}>
              서울시 강동구 올림픽로 744
              <br /> 강남우정빌딩
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mainContainerStyle = {
  width: "100%",
  height: "100%",
  overflow: "auto",
  padding: "1em",
  boxSizing: "border-box",

  display: "flex",
  flexDirection: "column",
  gap: "1em",

  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: "500",
  lineHeight: "1.5",
  fontSize: "15px",
  color: "#000",
};

const mainLabelStyle = {
  fontSize: "18px",
  fontWeight: "700",
  borderBottom: "2px solid #BDBDBD",
  padding: "0.2em 0",
};

const contentContainerStyle = {
  display: "flex",
  flexDirection: "column",
  padding: "1em 1em 1em 2em",
};

const contentRowStyle = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "flex-start",
  gap: "1em",

  fontSize: "13px",
};

export default OrderReceipt;
