import { useNavigate } from "react-router-dom";

import { USER_DTO } from "App-config";
import game_title from "src_assets/hiddencatch_gameOver_title.png";
import game_finish from "src_assets/game_finish.png";
import reset_game from "src_assets/reset_game.png";
import close_btn from "src_assets/close_btn.png";

import "components/main/style/GameReadyModal.css";
import { useEffect } from "react";

function GameFinishModal({ data, isAnswer, closeGamePopupEvent, isApp, scriptHandler, hiddenCatchGame, requestGameResetEvent }) {
  const navigate = useNavigate();
  const HCG_IDX = data.g_idx;

  var game = true;

  const closePopup = (e) => {
    e.preventDefault();
    closeGamePopupEvent("closeBtn", 0);
  };

  const closeWebView = (e) => {
    scriptHandler(e, "closeGame");
  };

  const resetGame = () => {
    requestGameResetEvent(null);
    closeGamePopupEvent("closeBtn", 0);
    if(game) {
      hiddenCatchGame();  
      setTimeout(function(){
        game = true;
      }, 2000);
    }
  }


  useEffect(() => {
    if(isApp) {
      document.querySelector('.foot-btn').style.display = 'none';
      document.querySelector('.app-foot-btn').style.display = 'flex';
    }
  }, []);

  return (
    <div className="game-modal">
      {isApp ? <div className="closeWebViewFinish"><img src={close_btn} onClick={closeWebView}/></div> : ""}
      <div className="game-finish-head">
        <img src={game_title} />
      </div>
      <form id="finish-res-modal" className="game-form">
        <div className="game-finish-answer">
          <span>{isAnswer ? "성공" : "실패"}</span>
        </div>
        <ul className="game-finish-list">
          <li>
            <span>지급포인트</span>
            <span className="game-colon">:</span>
            <span className="game-data">
              {isAnswer
                ? parseInt(data.g_reward_price) + parseInt(data.g_price)
                : data.g_price}
              P
            </span>
          </li>
          <li>
            <span>게임 포인트 지급은 준비중입니다. </span>
            
          </li>
        </ul>
      </form>
      <div className="game-finish-foot">
        <div className="foot-btn">
          <img src={game_finish} onClick={closePopup} className="finish-btn"/>
        </div>
        <div className="app-foot-btn" >
          <img src={reset_game} onClick={resetGame} className="gameReset"/>
          <img src={game_finish} onClick={closeWebView} className="finish-btn"/>
        </div>
      </div>
    </div>
  );
}

export default GameFinishModal;
