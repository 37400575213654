import ZeroStarImg from "src_assets/star/0star.png";
import OneStarImg from "src_assets/star/1star.png";
import TwoStarImg from "src_assets/star/2star.png";
import ThreeStarImg from "src_assets/star/3star.png";
import FourStarImg from "src_assets/star/4star.png";
import FiveStarImg from "src_assets/star/5star.png";
import ZeroHalfStarImg from "src_assets/star/0.5star.png";
import OneHalfStarImg from "src_assets/star/1.5star.png";
import TwoHalfStarImg from "src_assets/star/2.5star.png";
import ThreeHalfStarImg from "src_assets/star/3.5star.png";
import FourHalfStarImg from "src_assets/star/4.5star.png";

const StarCnt = ({ totalCnt, reviewCnt, isAvg, avg }) => {
  let star = 0;
  if (isAvg) {
    star = Math.round(avg * 1 * 2) / 2;
  } else {
    star = Math.round(((totalCnt * 1) / (reviewCnt * 1)) * 2) / 2;
  }

  if (isNaN(star)) star = 0;

  switch (star) {
    case 1:
      return <img src={OneStarImg} alt="별 이미지"></img>;
    case 2:
      return <img src={TwoStarImg} alt="별 이미지"></img>;
    case 3:
      return <img src={ThreeStarImg} alt="별 이미지"></img>;
    case 4:
      return <img src={FourStarImg} alt="별 이미지"></img>;
    case 5:
      return <img src={FiveStarImg} alt="별 이미지"></img>;
    case 0.5:
      return <img src={ZeroHalfStarImg} alt="별 이미지"></img>;
    case 1.5:
      return <img src={OneHalfStarImg} alt="별 이미지"></img>;
    case 2.5:
      return <img src={TwoHalfStarImg} alt="별 이미지"></img>;
    case 3.5:
      return <img src={ThreeHalfStarImg} alt="별 이미지"></img>;
    case 4.5:
      return <img src={FourHalfStarImg} alt="별 이미지"></img>;
    default:
      return <img src={ZeroStarImg} alt="별 이미지"></img>;
  }
};

export default StarCnt;
