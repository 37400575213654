import { DaumPostcodeEmbed  } from "react-daum-postcode";
import "components/common/style/postmodal.css";
import { KAKAO_ADDR_API_URL } from "App-config";

const KakaoAddrPopup = (props) => {
  const complete = (data) =>{
    let fullAddress = data.address;
    let extraAddress = '';

    if (data.addressType === 'R') {
        if (data.bname !== '') {
            extraAddress += data.bname;
        }
        if (data.buildingName !== '') {
            extraAddress += (extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName);
        }
        fullAddress += (extraAddress !== '' ? ` (${extraAddress})` : '');
    }
    console.log(data)
    console.log(fullAddress)
    console.log(data.zonecode)

    document.getElementById("address-input").value=fullAddress;
}


  return (
    <div >
    <DaumPostcodeEmbed   className="postmodal"
                autoClose onComplete={complete} />
    </div>
   
  )

};

export default KakaoAddrPopup;
