import { useEffect, useState } from "react";

import { getAgreeList } from "service/AccountService";

import "components/common/style/PersonalInfo.css";

const Personaltext = ({ htmlStr }) => {
  return (
    <div
      className="personal-info-box"
      dangerouslySetInnerHTML={{ __html: htmlStr }}
    />
  );
};

const PersonalInfo = () => {
  const [useAgree, setUseAgree] = useState("");

  useEffect(() => {
    getAgreeList().then((res) => {
      res.data.forEach((item) => {
        if (item.docsSubject === "개인정보 처리방침") {
          setUseAgree(item.docsContent);
        }
      });
    });
  }, []);
  return (
    <section className="personal-info">
      <div className="personal-area">
        <h2>개인정보 처리방침</h2>
        <Personaltext htmlStr={useAgree} />
      </div>
    </section>
  );
};

export default PersonalInfo;
