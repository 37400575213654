import "components/order/style/categoryPart/CateTypeShopSearch.css";
import { useRef } from "react";

const CateTypeSearch = ({ searchEvent }) => {
  const keyword = useRef();

  return (
    <section className="shop-search-frame">
      <span className="order-title">매장 내 검색</span>
      <div className="category-search-box">
        <input
          ref={keyword}
          type="text"
          id="category-search-input"
          placeholder="검색하세요"
          onKeyDown={(e) => {
            if (e.code === "Enter") searchEvent(keyword.current.value);
          }}
        />
        <button
          className="category-search-button"
          onClick={() => {
            searchEvent(keyword.current.value);
          }}
        />
      </div>
    </section>
  );
};

export default CateTypeSearch;
