import { useRef } from "react";
import { useMediaQuery } from "react-responsive";

import TimerComponent from "components/common/TimerComponenet";

const AuthFindIdInputComponent = ({
  isPrivacy,
  isView,
  setAuthInputView,
  phoneInputDisabled,
  submitAction,
}) => {
  const isMobile = useMediaQuery({ maxWidth: "768px" });

  const authInputRef = useRef();

  const authClickHandler = (e) => {
    if (e) {
      e.preventDefault();
    }
    console.log(authInputRef.current.value);
    window.confirmationResult
      .confirm(authInputRef.current.value)
      .then(() => {
        // alert("인증성공");
        phoneInputDisabled(true);
        setAuthInputView(false);
        submitAction(e);
      })
      .catch((err) => {
        console.error(err);
        alert("인증실패");
      });
  };

  let customStyleInput = isPrivacy
    ? {
        width: "385px",
        height: "50px",
        lineHeight: "50px",
        border: "1px solid transparent",
        borderBottom: "1px solid #090909",
        boxSizing: "border-box",
        fontSize: "16px",
        paddingLeft: "20px",
        marginBottom: "10px",
      }
    : {};
  let customStyleButton = isPrivacy
    ? {
        display: "initial",
        position: "relative",
        width: "83px",
        height: "35px",
        lineHeight: "35px",
        marginTop: "6px",
        left: "-90px",
        textAlign: "center",
        border: "1px solid transparent",
        boxSizing: "border-box",
        backgroundColor: "#e2e2e2",
        fontSize: "16px",
        color: "#0e0e0e",
        borderRadius: "20px",
        cursor: "pointer",
      }
    : {};

  if (isMobile && isPrivacy) {
    customStyleInput = {
      width: "300px",
      maxWidth: "300px",
      height: "50px",
      lineHeight: "50px",
      border: "1px solid transparent",
      borderBottom: "1px solid #090909",
      boxSizing: "border-box",
      fontSize: "14px",
      paddingLeft: "20px",
      marginBottom: "10px",
    };
    customStyleButton = {
      display: "initial",
      position: "absolute",
      left: "240px",
      width: "83px",
      height: "35px",
      lineHeight: "35px",
      marginTop: "6px",
      textAlign: "center",
      border: "1px solid transparent",
      boxSizing: "border-box",
      backgroundColor: "#e2e2e2",
      fontSize: "16px",
      color: "#0e0e0e",
      borderRadius: "20px",
      cursor: "pointer",
    };
  }

  if (isView) {
    return (
      <>
        <span>
          <TimerComponent
            isView
            callBackFnc={() => {
              console.log("타이머종료");
              phoneInputDisabled(false);
              setAuthInputView(false);
            }}
            styleClass={{
              width: "120px",
              height: "100%",
              lineHeight: "50px",
              fontSize: "18px",
            }}
          />
          <input
            id=""
            style={customStyleInput}
            type="text"
            placeholder="SMS 인증번호를 입력해주세요."
            ref={authInputRef}
          />
        </span>
        <span>
          <button
            onClick={authClickHandler}
            style={customStyleButton}
            className="join-btn-comfirm"
          >
            확인
          </button>
        </span>
      </>
    );
  }
  return "";
};

export default AuthFindIdInputComponent;
