import "components/order/style/ShopEmpty.css";

import emptyimg from "src_assets/mascot-g.png";

const ShopEmpty = () => {
  return (
    <section className="shop-empty-frame">
      <div className="shop-empty-area">
        <div className="empty-img">
          <img src={emptyimg} alt="빈이미지"></img>
        </div>
        <span className="empty-text">내 주변에 해당하는 상점이 없어요!</span>
      </div>
    </section>
  );
};

export default ShopEmpty;
