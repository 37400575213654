import { useEffect } from "react";

import GameReadyModal from "components/main/GameReadyModal";
import GamePlayModal from "components/main/GamePlayModal";
import GameFinishModal from "components/main/GameFinishModal";
import AppGameComponent from "components/main/AppGameComponent";


import AnswerModal from "components/main/AnswerModal";

// 게임팝업 css
import "components/main/style/VideoPopup.css";
import { useState } from "react";


const GamePopup = ({
  hcgData,
  isMobile,
  isApp,
  setUser,
  closeGamePopupEvent,
  isGameRunning,
  hiddenCatchGame,
  scriptHandler
}) => {

  const [isAnswer, setIsAnswer] = useState(null);


  window.onpopstate = function(e) {
    if(isApp) {
      scriptHandler(e, "closeGame");
    }
    closeGamePopupEvent('closeBtn', 0);
  }

  useEffect(() => {
    // 모달창 떳을때 스크롤 막기
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "visible";
    };
  }, []);

  useEffect(() => {
    if(isAnswer != null) {
      // 서버통신

      // isAnswer의 데이터가 변경되면 실행되는거임
      closeGamePopupEvent(null, 3);
    }
  }, [isAnswer])

  const requestGameResetEvent = (reqAnswer) => {
    setIsAnswer(reqAnswer);
  };

  const requestAnswerEvent = (res, reqAnswer) => {
    if(res) {
      setUser(res.data);
    }
    setIsAnswer(reqAnswer);
  };
  if (isGameRunning === 1) {
    return (
      <GameReadyModal
        closeGamePopupEvent={closeGamePopupEvent}
        data={hcgData}
        user={setUser}
        isApp={isApp}
        hiddenCatchGame={hiddenCatchGame}
        scriptHandler={scriptHandler}
      />
    );
  } else if (isGameRunning === 2) {
    return (
      <GamePlayModal
        closeGamePopupEvent={closeGamePopupEvent}
        data={hcgData}
        isApp={isApp}
        isMobile={isMobile}
        requestAnswerEvent={requestAnswerEvent}
        scriptHandler={scriptHandler}
      />
    );
  } else if (isGameRunning === 3) {
    // 게임 결과에따른 포인트 지급 결과
    return (
      <GameFinishModal
        data={hcgData}
        isAnswer={isAnswer}
        closeGamePopupEvent={closeGamePopupEvent}
        isApp={isApp}
        scriptHandler={scriptHandler}
        hiddenCatchGame={hiddenCatchGame}
        requestGameResetEvent={requestGameResetEvent}
      />
    );
  } 
};

export default GamePopup;
