import "components/common/style/Loading.css";
import { useEffect, useRef, useState } from "react";
import kiikeyloding from "src_assets/loading.svg";

export function NewLoading({ tabState, index }) {
  if (!tabState) tabState = 0;

  if (!index) index = 0;

  const [dots, setDots] = useState("");
  const savedCallback = useRef();

  // let id =setInterval(()=>{
  //   let tmp_dot = "";
  //   for(let i = 0; dots.length%3; i++){
  //     tmp_dot = tmp_dot + "."
  //   }
  //   setDots(tmp_dot);
  //   console.log('loop');
  // },1000);
  //return()=>clearInterval(id);

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    if (tabState == index) {
      const tick = () => {
        savedCallback.current();
      };

      const timer = setInterval(tick, 500);
      return () => clearInterval(timer);
    }
  }, [tabState]);

  const callback = () => {
    if (dots.length > 2) {
      setDots("");
    } else {
      setDots((dots) => dots + ".");
    }
  };

  return (
    <>
      <section className="newloading-section">
        {/* <div>asdasd</div> */}
        <div className="new-loading">
          <img src={kiikeyloding} alt="Loading" style={{ width: "100%", height: "100%" }}></img>
          <span className="empty-text">로딩중{dots}</span>
        </div>
      </section>
    </>
  );
}

export function CycleBarLoading() {
  return (
    <>
      <section className="newloading-section">
        {/* <div>asdasd</div> */}
        <div className="cycleBar-loading" />
      </section>
    </>
  );
}
