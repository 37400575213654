import { useEffect, useState } from "react";
import {
    Route,
    Routes,
    useLocation,
    useNavigate,
    useParams,
} from "react-router-dom";

import { serverErrorHandler, SHOP_C2_IDX_MAP, SHOP_IMG_PATH } from "App-config";

import {
    getShopInfo,
    getShopMenuList,
    setBasket,
    delBasket,
} from "service/OrderService";

import FollowBasket from "components/order/FollowBasket";
import FollowBasketMobile from "components/order/FollowBasketMobile";
import StarCnt from "components/common/StarCountComponent";
import Beautydetail from "components/order/beautyPart/Beautydetail";
import ShopMenuInfoJustInfo from "components/order/ShopMenuInfoPrintJustInfo";
import CateTypeBeautyDate from "components/order/categoryPart/CateTypeBeautyDate";

import timeImg from "src_assets/date-time_b.png";

import "components/order/style/MenuIntro.css";
import "components/order/style/ShopMenuList.css";
import "components/order/style/ShopMenuGrade.css";
import { useRef } from "react";
import { throttleFunction } from "../../App-config";
import stateUtilityInstance from "../../TestClass";
import { CycleBarLoading, NewLoading } from "components/common/NewLoading";
import ProductDetail from "./product/ProductDetail";


const ShopComponentJustInfo = ({
    isMobile,
    totalAmt,
    setTotalAmt,
    basketList,
    setBasketList,
    //shopInfo,
    //setShopInfo,
    menuRef,
    basketCount,
    basketReload,
}) => {
    const [navState, setNavNumber] = useState({
        navNumber: 0,
        navScroll: 0,
    });

    const navigate = useNavigate();

    const params = useParams();
    const { state } = useLocation();

    const calendarRef = useRef();

    const [selectDate, setSelectDate] = useState();

    const [menuList, setMenuList] = useState();
    const [shopInfo, setShopInfo] = useState();
    const [changeTab, setChangeTab] = useState(2);

    const [isLike, setIsLike] = useState(false);
    const [isSchedule, setIsSchedule] = useState(false);

    const [isCustomTable, setIsCustomTable] = useState(false);
    const [scroll_position_list, setScrollPostionList] = useState([]);

    //var scroll_position_list;
    var offset = window.innerHeight / 5;

    //console.log("offset : " + offset);
    const set_scroll_position_list = (data) => {
        console.dir(data);
        var scroll_position_list = data.map((value, index) => {
            var positon =
                window.scrollY +
                document.getElementById("" + value.mc_idx)?.getBoundingClientRect().top;
            //console.log(positon);

            // if(positon){
            return {
                id: value.mc_idx,
                y_pos: positon ? positon - offset : 0,
            };
            //}
        });

        console.dir(scroll_position_list);
        setScrollPostionList(scroll_position_list);
        //setScrollPostionList(list);
        //console.dir(scroll_position_list);
        // const position_list = {
        //   id : data.mc_idx,
        //   position : {data.}
        // }
    };

    const handleScroll = () => {
        var y = window.scrollY;
        //console.log('y좌표' + y);
        //console.log("offset : " + offset);

        let FocusBtn;
        var navSpan = document.getElementById("navScroll");
        for (let i = 0; i < scroll_position_list.length; i++) {
            let navBtn = document.getElementById(
                "navBtn" + scroll_position_list[i].id
            );

            if (scroll_position_list[i].y_pos == 0) {
                set_scroll_position_list(menuList);
                return;
            }

            //console.log('id:' + scroll_position_list[i].id);
            // if(!navBtn){
            //   //set_scroll_position_list(menuList);
            //   return;
            // }

            navBtn.style.backgroundColor = "#f2f2f2";
            //navBtn.style.backgroundColor = '#f2f2f2';
            // if(y < scroll_position_list[0].y_pos){
            //   navBtn.style.backgroundColor = '#f2f2f2';
            // }

            if (y > scroll_position_list[i].y_pos) {
                if (scroll_position_list[i + 1]) {
                    if (y < scroll_position_list[i + 1].y_pos) {
                        //console.log('paint');
                        //navBtn.style.backgroundColor = 'red';

                        // var x = navSpan.scrollLeft;

                        // const navState = {
                        //   navNumber: scroll_position_list[i].id,
                        //   navScroll: x + navBtn.getBoundingClientRect().x - 50,
                        // };

                        // navBtn.style.backgroundColor = "var(--kk-color-Ymain)";

                        // navSpan.scrollTo({
                        //   left: navState.navScroll,
                        //   //behavior:'smooth',
                        // });

                        FocusBtn = navBtn;

                        //setNavNumber(navState);
                        //return;
                    } else {
                        navBtn.style.backgroundColor = "#f2f2f2";
                        navBtn.children[0].style.color = "#545454";
                    }
                } else {
                    //console.log('paint');
                    //navBtn.style.backgroundColor = 'red';

                    // var x = navSpan.scrollLeft;

                    // const navState = {
                    //   navNumber: scroll_position_list[i].id,
                    //   navScroll: x + navBtn.getBoundingClientRect().x - 50,
                    // };

                    // //setNavNumber(navState);

                    // navBtn.style.backgroundColor = "var(--kk-color-Ymain)";

                    // navSpan.scrollTo({
                    //   left: navState.navScroll,
                    //   //behavior:'smooth',
                    // });

                    FocusBtn = navBtn;
                }
                //document.getElementById('navBtn'+)
            } else {
                navBtn.style.backgroundColor = "#f2f2f2";
                navBtn.children[0].style.color = "#545454";
            }
        }
        if (FocusBtn) {
            FocusBtn.style.backgroundColor = "var(--kk-color-Ymain)";
            FocusBtn.children[0].style.color = "white";
            navSpan.scrollTo({
                left: navSpan.scrollLeft + FocusBtn.getBoundingClientRect().x - 50,
                //behavior:'smooth',
            });
        }
    };
    useEffect(() => {
        if (state) {
            if (state.shopInfo) {
                if (
                    state.shopInfo.c2_idx == SHOP_C2_IDX_MAP["beauty"] ||
                    state.shopInfo.c2_idx == SHOP_C2_IDX_MAP["clothes"] ||
                    state.shopInfo.c2_idx == SHOP_C2_IDX_MAP["movie"]
                ) {
                    setIsCustomTable(true);
                } else {
                    setIsCustomTable(false);
                }
                getShopMenuList(state.shopInfo.s_idx)
                    .then((res) => {
                        if (res.data.length > 0) {
                            setMenuList(res.data);
                            set_scroll_position_list(res.data);
                            //set_scroll_position_list(res.data);
                            // setScrollPostionList(()=>{
                            //   menuList.map((data,index)=>{

                            //   })
                            // })
                        } else {
                            // 메뉴가 없는 매장처리
                            setMenuList({});
                        }
                    })
                    .catch((err) => {
                        serverErrorHandler(err);
                    });
                // 지호 220706 가게정보보기
                getShopInfo(state.shopInfo.s_idx)
                    .then((res) => {
                        if (res.data.length !== null) {
                            stateUtilityInstance.setMobileHeaderTitle(res.data.s_name);

                            let reviewCnt = res.data.total_cnt;
                            if (!reviewCnt) {
                                reviewCnt = 0;
                            }

                            let reviewStar = res.data.total_star;
                            if (!reviewStar) {
                                reviewStar = 0;
                            }

                            let reviewAvg =
                                Math.round(((reviewStar * 1) / (reviewCnt * 1)) * 2) / 2;
                            if (!reviewAvg) {
                                reviewAvg = 0;
                            }

                            res.data.reviewCnt = reviewCnt;
                            res.data.reviewStar = reviewStar;
                            res.data.reviewAvg = reviewAvg;

                            setShopInfo(res.data);
                            setIsLike(res.data.s_is_scrap > 0);
                        } else {
                            // 메뉴가 없는 매장처리
                        }
                    })
                    .catch((err) => {
                        serverErrorHandler(err);
                    });
            }
        } else {
            navigate("/order");
        }
    }, []);

    useEffect(() => {
        console.log("스크롤 useEffect");
        const throttle = throttleFunction(handleScroll, 300);
        window.addEventListener("scroll", throttle);
        return () => {
            window.removeEventListener("scroll", throttle);
        };
    }, [scroll_position_list]);


    const shopElement = (
        <section className="shop-menu-list-frame">
            <div className="shop-menu-list-inner">
                <div className="thumbnailimg-box">
                    {shopInfo?.s_img ? (
                        <img
                            src={SHOP_IMG_PATH + shopInfo.s_img}
                            alt="기본 이미지"
                            loading="lazy"
                        />
                    ) : (
                        // <img src={""} alt="기본 이미지" />
                        ""
                    )}
                </div>
                <div className="shop-menu-list">
                    <div className="shop-menu-title">
                        <h2>{shopInfo?.s_name}</h2>
                        {shopInfo?.c2_idx == 20 && (
                            <h5 style={{ textAlign: "center", marginTop: 10 }}>
                                {shopInfo?.s_ad.split("\r\n").map((tag) => {
                                    return <p>{tag}</p>;
                                })}
                            </h5>
                        )}
                    </div>
                    <Routes>
                        <Route path="detail" element={<Beautydetail />} />
                        <Route
                            path=""
                            element={
                                <ShopMenuInfoJustInfo
                                    shopInfo={shopInfo}
                                    menuList={menuList}
                                    menuRef={menuRef}
                                    changeTab={changeTab}
                                    setChangeTab={setChangeTab}
                                    isCustomTable={isCustomTable}
                                    setNavNumber={setNavNumber}
                                />
                            }
                        />
                    </Routes>
                </div>
            </div>
        </section>
    );

    return (
        <>
            {shopInfo && menuList ? (
                shopElement
            ) : (
                <div>
                    <NewLoading tabState={0} index={0} />
                </div>
            )}
        </>
    );
};

export default ShopComponentJustInfo;
