import { useState } from "react";
 
import Pagination from "components/common/Pagination";
 
import "components/mykiikey/style/Mykiikey.css";
import "components/mykiikey/style/InquireList.css";
import { REVIEW_IMG_PATH } from "App-config";
import { deleteInquire } from "service/AccountService";
 
const InquireList = ({ inquireList, setInquireList, isMobile }) => {
 const [inquireDetail, setInquireDetail] = useState(null);
 const VIEW_LIMIT = 5;
 const [currentPage, setCurrentPage] = useState(1);
 const offset = (currentPage - 1) * VIEW_LIMIT;
 
 const inquireListLimit = isMobile
   ? inquireList.slice(0, offset + VIEW_LIMIT)
   : inquireList.slice(offset, offset + VIEW_LIMIT);
 
 // 문의 삭제로 수정
 const inquireDeleteClickEvent = (data) => {
   deleteInquire({ iqIdx: data.iq_idx })
     .then((res) => {
       if (res.success) {
         setInquireList(
           inquireList.filter((inquire) => inquire.iq_idx != data.iq_idx)
         );
       }
     })
     .catch(console.error);
 };
 
 const liTagList = inquireListLimit.map((item, index) => {
   return (
     <li key={index}>
       <div className="inquire-list-head">
         <span className="inquire-list-name">{item.iq_content}</span>
       </div>
       <div className="inquire-list-date">
         <span>{item.iq_datetime}</span>
         <ul className="inquire-list-body">
           <li>
             <button
               className="inquire-check-button"
               onClick={() => setInquireDetail(item)}
             >
               문의확인
             </button>
           </li>
           <li className="inquire-button">
             <button
               className="inquire-delete-button"
               onClick={() => inquireDeleteClickEvent(item)}
             >
               문의삭제
             </button>
           </li>
         </ul>
       </div>
     </li>
   );
 });

 const InquireAnswer = ({ inquireDetail }) => {
  return (
      <div className="order-history">
        <p>문의답변</p>
        <span>
          {inquireDetail.iq_answer == "" ||
            inquireDetail.iq_answer == null
            ? ""
            : inquireDetail.iq_answer.length > 50
              ? inquireDetail.r_ceo_ciq_answerontent.substr(0, 50) + "..."
              : inquireDetail.iq_answer}
        </span>
      </div>
  );
 }

 
 const InquireModal = () => {

   return (
     <div className="inquire-modal-frame">
       <div className="inquire-modal">
         <div className="inquire-title">
           <p>문의확인</p>
           <button onClick={() => setInquireDetail(null)}>X</button>
         </div>
         <div className="inquire-text">{inquireDetail.iq_datetime}</div>
         <div className="order-history">
           <p>문의내역</p>
           <span>{inquireDetail.iq_content}</span>
         </div>
         {inquireDetail.iq_answer != "" ? <InquireAnswer inquireDetail={inquireDetail} /> : ""}
 
 
       </div>
     </div>
   );
 };
 
 return (
   <div className="daps-frame">
     <div className="daps-title-box">문의내역</div>
     <div className="daps-ul-list">{liTagList}</div>
     <Pagination
       isMobile={isMobile}
       limit={VIEW_LIMIT}
       offset={offset}
       type={"inquireList"}
       totalCount={inquireList.length}
       currentPage={currentPage}
       setCurrentPage={setCurrentPage}
     />
     {inquireDetail && <InquireModal />}
   </div>
 );
};
 
export default InquireList;
 
