import {
  ORDER_JBG_SHOP_API_PATH
} from "App-config";
import { call, customAlert } from "service/ApiService";

export const selectJbgShopInfo = async (formData) => {
  const response = await call(ORDER_JBG_SHOP_API_PATH, "POST", formData, true);
  return response;
};

export const selectJbgMenu = async (formData) => {
  const response = await call(ORDER_JBG_SHOP_API_PATH, "POST", formData, true);
  return response;
};

export const selectJbgMenuSearch = async (formData) => {
  const response = await call(ORDER_JBG_SHOP_API_PATH, "POST", formData, true);
  return response;
};