import { useEffect, useState } from "react";

import { SHOP_IMG_PATH } from "App-config";
import { getShopScrapList } from "service/AccountService";

import StarCnt from "components/common/StarCountComponent";
import Pagination from "components/common/Pagination";

import shoptestimg from "src_assets/order_test_img.png";
import timeicon from "src_assets/time_icon.png";

import "components/mykiikey/style/Myinfo.css";
import "components/mykiikey/style/Mykiikey.css";
import "components/mykiikey/style/Favoritestore.css";
import stateUtilityInstance from "../../TestClass";
import { useNavigate } from "react-router-dom";
import { NewLoading } from "components/common/NewLoading";

const Favoritestore = ({
  favoriteStoreList,
  setFavoriteStoreList,
  isMobile,
}) => {
  const navigate = useNavigate();

  const VIEW_LIMIT = 6;
  const [currentPage, setCurrentPage] = useState(1);
  const offset = (currentPage - 1) * VIEW_LIMIT;
  const favoriteStoreListLimit = isMobile
    ? favoriteStoreList.slice(0, offset + VIEW_LIMIT)
    : favoriteStoreList.slice(offset, offset + VIEW_LIMIT);

  const [isLoad,setIsLoad] = useState(false);

  useEffect(() => {
    stateUtilityInstance.setMobileHeaderTitle('찜한가게');
    getShopScrapList()
      .then((res) => {
        if (res.success) {
          setIsLoad(true);
          setFavoriteStoreList(res.data);
        }
      })
      .catch(console.error);
  }, []);

  const liTagList = favoriteStoreListLimit.map((item, index) => {
    const isRest = item.is_rest * 1;
    const isRun = item.is_run * 1;
    return (
      <li
        key={index}
        onClick={() => {
          navigate("/order/shop", { state: { shopInfo: item } });
        }}
      >
        <div className="store-img">
          {item.s_img ? (
            <img src={SHOP_IMG_PATH + item.s_img} alt="가게 이미지" />
          ) : (
            <img src={shoptestimg} alt="가게 이미지" />
          )}
          {(isRest > 0 || isRun < 1) && (
            <p className="menu-preparing">준비중</p>
          )}
        </div>
        <div className="store-detail-box">
          <div className="store-detail">
            <span className="store-info">
              <p className="store-name">{item.s_name}</p>
              <p className="store-grade">
                <StarCnt isAvg={true} avg={item.review_avg} />
                {Math.round(item.review_avg * 1 * 2) / 2}({item.r_star_total})
              </p>
            </span>
            <span className="store-state">
              {item.s_is_packing > 0 && <p>포장</p>}
              {item.s_is_delivery > 0 && <p>배송</p>}
              {item.s_is_store > 0 && <p>매장</p>}
              {item.s_is_parcel > 0 && <p>택배</p>}
            </span>
          </div>
          <div className="store-price">
            <span>
              <img src={timeicon} alt="시간 이미지"></img>
              <p>{item.s_delivery_time}분 |</p>
              <p>
                배송비 {item.min_t_price}원 ~{item.max_t_price}원
              </p>
            </span>
          </div>
        </div>
      </li>
    );
  });

  const resultView = (
    <>
    {favoriteStoreList.length > 0 && (
        <ul className="store-area">
          <div className="town-title">
            {/* <div className="name">
              <img src={tagimg} alt="태그 이미지"></img>
              <p>대구광역시 수성구</p>
            </div>
            <div className="favorite-number">
              <p>찜</p>
              <img src={heartimg} alt="하트 이미지"></img>
              <span>{favoriteStoreList.length}</span>
            </div> */}
          </div>

          {liTagList}
        </ul>
      )}

      <Pagination
        isMobile={isMobile}
        limit={VIEW_LIMIT}
        offset={offset}
        type={"favoriteStore"}
        totalCount={favoriteStoreList.length}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </>
  );
  return (
    <div className="daps-frame">

      {isLoad ? 
      resultView
      :
      <NewLoading />
      }
    </div>
  );
};

export default Favoritestore;
