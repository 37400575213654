import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { checkPassword } from "service/AccountService";

import "components/mykiikey/style/AccountCheck.css";
import "components/mykiikey/style/Mykiikey.css";
import stateUtilityInstance from "../../TestClass";

const AccountCheck = ({ setPassCheck, setUserInfo }) => {
  const navigate = useNavigate();
  const passRef = useRef("");

  useEffect(() => {
    stateUtilityInstance.setMobileHeaderTitle('계정확인');
    passRef.current.focus();
  }, []);

  const passwordCheckEvent = () => {
    checkPassword(passRef.current.value)
      .then((res) => {
        if (res.success) {
          setPassCheck(true);
          setUserInfo(res.data);

          navigate("/mykiki/privacy");
        }
      })
      .catch((err) => {
        alert(err.msg);
        console.error(err);
      });
  };
  return (
    <div className="daps-frame">
      <div className="account-check">
        <span> 개인 정보를 변경하기 위해 계정확인이 필요합니다.</span>
        <div className="account-input">
          <span>비밀번호</span>
          <input
            ref={passRef}
            type="password"
            placeholder="비밀번호 입력"
            id="password-check"
            onKeyUp={(e) => {
              if (e.key === "Enter") passwordCheckEvent();
            }}
          />
          <button className="info-change-comfirm" onClick={passwordCheckEvent}>
            확인
          </button>
        </div>
      </div>
    </div>
  );
};

export default AccountCheck;
