import Mascot from "src_assets/mascot-bg.png";

const BorderItemNotFound = ({ type }) => {
  let title = "스크랩한 광고가 없어요!";
  let desc = "광고를 시청하고 스크랩을 해보세요!";
  if (type === "adwatched") {
    title = "시청한 광고가 없어요!";
    desc = "광고를 시청하고 포인트를 받아가세요!";
  } else if (type === "notice") {
    title = "등록된 공지사항이 없습니다.";
    desc = "";
  } else if (type === "reviewList") {
    title = "작성한 리뷰가 없습니다.";
    desc = "";
  } else if (type === "inquireList") {
    title = "작성한 문의가 없습니다.";
    desc = "";
  } else if (type === "ticketList") {
    title = "영화티켓 구매내역이 없습니다.";
    desc = "";
  } else if (type === "favoriteStore") {
    title = "찜한 가게가 없어요!";
    desc = "";
  } else if (type === "userQna") {
    title = "";
    desc = "";
  } else if (type === "pointHistory") {
    title = "포인트 변동 내역이 없어요!";
    desc = "광고를 시청하고 포인트를 받아가세요!";
  } else if (type === "orderHistory") {
    title = "주문내역이 없습니다.";
    desc = "";
  } else if (type === "giftCoupon") {
    title = "보유 기프티콘이 없습니다.";
    desc = "";
  } else if (type ==="barcodeUseList"){
    title = "사용내역이 없습니다.";
    desc = "";
  }
  return (
    <div className="daps-empty-view">
      <div className="mascot-img-box">
        <img src={Mascot} alt="마스코트 이미지"></img>
      </div>
      <div className="empty-note">
        <span className="empty-main-title">{title}</span>
        <span className="empty-sub-title">{desc}</span>
      </div>
      <div className="guide-box"></div>
    </div>
  );
};

export default BorderItemNotFound;
