import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import {
  checkPlatform,
  PHONE_REG,
  isKiikeyApp,
  IS_ANDROID,
  IS_IOS,
} from "App-config";

import { auth, signInWithPhoneNumber } from "service/firebase";
import {
  duplicatePhoneCheck,
  fcmAuthLog,
  phoneAuth,
} from "service/AccountService";

import AuthFindIdInputComponent from "components/account/AuthFindIdInputComponent";
import AuthFindIdComponent from "components/account/AuthFindIdComponent";
import stateUtilityInstance from "../../TestClass";

import "components/account/style/IdFind.css";
import Header from "components/common/Header";

const JustAuth = ({ setIdFindValidCheck }) => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const userPhoneInput = useRef();

  const [authView, setAuthView] = useState(false);
  const [authInputView, setAuthInputView] = useState(false);

  const isButtonView = !authView && !authInputView ? true : false;

  const phoneInputDisabled = (isDisable) => {
    userPhoneInput.current.disabled = isDisable;
  };

  useEffect(() => {
    stateUtilityInstance.setMobileHeaderTitle("인증");
  });

  const phoneAuthHandler = async (e) => {
    e.preventDefault();
    let _userPhone = userPhoneInput.current.value.trim();
    if (!_userPhone) {
      alert(
        "등록된 번호가 없습니다.\n개인 정보 변경에서 휴대폰번호를 등록해주세요."
      );
      return;
    }

    if (!RegExp(PHONE_REG).test(_userPhone)) {
      alert(
        "유효하지않은 전화번호 입니다.\n개인 정보 변경에서 휴대폰번호를 변경해주세요."
      );
      return;
    }

    _userPhone = _userPhone.replace("-", "");
    _userPhone = _userPhone.replace("-", "");

    let pType = "4";
    const rType = "5";
    if (checkPlatform() === "mobile") {
      if (isKiikeyApp) {
        if (IS_IOS) {
          pType = "6";
        } else if (IS_ANDROID) {
          pType = "7";
        } else {
          pType = "3";
        }
      } else {
        pType = "3";
      }
    }

    const isCheck = await duplicatePhoneCheck({ _userPhone, pType, rType })
      .then((res) => {
        console.log(res);
        return true;
      })
      .catch((err) => {
        alert(err.msg);
        console.error(err);
        return false;
      });
    if (!isCheck) return;

    if (_userPhone) {
      _userPhone = _userPhone.replace("010", "+8210");
    }

    phoneInputDisabled(true);
    setAuthView(true);

    signInWithPhoneNumber(auth, _userPhone, window.recaptchaVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        console.log("signInWithPhoneNumber 1", confirmationResult);
        setAuthView(false);
      })
      .catch((error) => {
        fcmAuthLog({ _userPhone, error, rType, pType })
          .then((res) => {})
          .catch((err) => {});
        console.log("signInWithPhoneNumber 2", error);
      })
      .finally(() => {
        console.log("signInWithPhoneNumber 3");
      });
  };

  /* useEffect(() => {
    userPhoneInput.current.focus();
  }, []); */

  const submitAction = (e) => {
    e.preventDefault();
    let _userPhone = userPhoneInput.current.value.trim();

    _userPhone = _userPhone.replace("-", "");
    _userPhone = _userPhone.replace("-", "");

    phoneAuth()
      .then((res) => {
        if (res.success) {
          setIdFindValidCheck(true);
          navigate("/account/JustAuthResult");
        }
      })
      .catch((err) => {
        //phoneInputDisabled(false);
        setAuthInputView(false);
        alert(err.msg);
        console.error(err);
      });
  };

  useEffect(() => {
    if (!state) {
      alert("잘못된 경로 접근하셨습니다.");
      navigate("/order");
      return;
    }
  }, [state]);

  return (
    <>
      <Header />
      <section className="id-find">
        <form className="id-find-form">
          <div className="form-title">휴대전화 인증</div>
          <div className="id-input-area">
            <span className="id-note">
              현재 등록된 번호로 휴대전화인증을 진행합니다.
              <p className="just-note">
                (전화번호 변경시 마이이쁨 - 개인 정보 변경을 이용해주세요.)
              </p>
            </span>
            <div className="id-input">
              <span>
                <label htmlFor="userPhone">휴대전화</label>
                <input
                  ref={userPhoneInput}
                  type="tel"
                  id="userPhone"
                  value={state?.mbPhone}
                  disabled="true"
                  placeholder="예) 010-1111-0000"
                />
              </span>

              <AuthFindIdInputComponent
                isView={authInputView}
                setAuthInputView={setAuthInputView}
                phoneInputDisabled={phoneInputDisabled}
                submitAction={submitAction}
              />
              <AuthFindIdComponent
                userPhoneInput={userPhoneInput.current}
                isView={authView}
                setAuthView={setAuthView}
                setAuthInputView={setAuthInputView}
                phoneInputDisabled={phoneInputDisabled}
              />
            </div>
          </div>
          {isButtonView && (
            <button className="join-btn-comfirm" onClick={phoneAuthHandler}>
              인증 요청
            </button>
          )}
        </form>
      </section>
    </>
  );
};

export default JustAuth;
