// 이상윤 220720 문의 따로 컴포넌트로 뺌
import { useRef } from "react";

import { setInquire } from "service/OrderService";

import "components/order/style/Beautydetail.css";

import empty from "src_assets/mascot-g.png";

const BeautyQuestions = ({ shopInfo, menuInfo, inquires, setInquires }) => {
  const contentRef = useRef();

  const QuestionListComponent = () => {
    return inquires.map((inquire) => (
      <>
        {/* 유저가 적는 리뷰입니다. */}
        <div className="user">
          <div className="user-info">
            <span className="user-name">{inquire.mb_id}</span>
            <span>{inquire.iq_datetime.split(" ")[0]}</span>
          </div>
          <div className="user-Questions">
            <span readOnly className="que-text">
              {inquire.iq_content}
            </span>
          </div>
        </div>
        {/* 사장이 적는 리뷰입니다. */}
        {inquire.iq_ceo_content && (
          <div className="store">
            <div className="user-info">
              <span className="user-name">
                <b>└ </b>사장님
              </span>
              <span>{inquire.iq_ceo_datetime.split(" ")[0]}</span>
            </div>
            <div className="store-answer">
              <span readOnly className="que-text">
                {inquire.iq_ceo_content}
              </span>
            </div>
          </div>
        )}
      </>
    ));
  };

  const submitClickEvent = () => {
    const contents = contentRef.current.value.trim();

    if (!contents || contents == "") {
      alert("문의 내용을 입력해주세요.");
      return;
    }

    setInquire({
      s_idx: shopInfo.s_idx,
      m_idx: menuInfo.m_idx,
      iq_content: contents,
      table_name: shopInfo.table_name,
    })
      .then((res) => {
        if (res.success) {
          if (res.data) {
            let newInquires = [res.data, ...inquires];
            setInquires(newInquires);
            contentRef.current.value = "";
          }
        }
      })
      .catch(console.error);
  };

  return (
    <section className="detail-section">
      {inquires && <QuestionListComponent />}
      {inquires && inquires.length < 1 && (
        <div className="empty-text-area">
          <div className="empty-imgg">
            <img src={empty} alt="empty"></img>
          </div>
          <div className="empty-text">
            <span>등록된 문의가 없습니다.</span>
          </div>
        </div>
      )}
      <div className="review-writing">
        <input
          ref={contentRef}
          type="text"
          id="review-weiting-input"
          placeholder="문의사항을 등록해주세요"
        ></input>
        <button className="review-weiting-confirm" onClick={submitClickEvent}>
          <p>문의</p>
          <p>등록</p>
        </button>
      </div>
    </section>
  );
};

export default BeautyQuestions;
