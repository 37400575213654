import React from "react";
import ReactDOM from "react-dom/client";

import AppRouter from "router/AppRouter";

import "index.css";

import reportWebVitals from "reportWebVitals";

export function histroyBack(){
  window.history.back();
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  //<React.StrictMode>
    <AppRouter />
  //</React.StrictMode>
);

reportWebVitals();
