import "components/mykiikey/style/Mykiikey.css";
import "components/mykiikey/style/ReviewWriting.css";
import stateUtilityInstance from "../../TestClass";
import { useEffect } from "react";
import { useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { writeReview } from "service/OrderService";

const ReviewWriting = ({ reviewList, setReviewList }) => {
  const navigate = useNavigate();

  const starRef = useRef();
  const contentsRef = useRef();
  const fileRef1 = useRef();
  const fileRef2 = useRef();
  const fileRef3 = useRef();
  const fileRef4 = useRef();

  const { state } = useLocation();

  const [fileImage1, setFileImage1] = useState("");
  const [fileImage2, setFileImage2] = useState("");
  const [fileImage3, setFileImage3] = useState("");
  const [fileImage4, setFileImage4] = useState("");

  const starClickEvent = (e) => {
    starRef.current.querySelectorAll("input").forEach((ele, index) => {
      if (e.target.checked) {
        if (index < e.target.value) {
          ele.checked = true;
        }
      } else {
        if (index >= e.target.value) {
          ele.checked = false;
        }
      }
    });
  };

  const submitClickEvent = () => {
    let maxCnt = "";
    starRef.current.querySelectorAll("input").forEach((ele, index) => {
      if (ele.checked) {
        maxCnt = ele.value;
      }
    });
    if (!maxCnt) {
      if (!window.confirm("별 점수가 0점입니까")) {
        return;
      }
      maxCnt = "0";
    }

    const formData = new FormData();

    formData.append("c3_idx", state.c3_idx);
    formData.append("m_idx", state.midx);
    formData.append("od_idx", state.od_idx);
    formData.append("type", !!state.type ? state.type : "");
    formData.append("rcnt", state.r_cnt);

    formData.append("s_idx", state.s_idx);
    formData.append("r_content", contentsRef.current.value);
    formData.append("r_star", maxCnt);
    formData.append("action", "writeReview");
    formData.append("isModify", 0);

    formData.append("files[]", fileRef1.current.files[0]);
    formData.append("files[]", fileRef2.current.files[0]);
    formData.append("files[]", fileRef3.current.files[0]);
    formData.append("files[]", fileRef4.current.files[0]);

    writeReview(formData)
      .then((res) => {
        if (res.success) {
          setReviewList([res.data, ...reviewList]);
          //navigate("/mykiki/orderList",{replace:true});
          window.history.go(-window.history.length);

        }
      })
      .catch((err) => {
        navigate(-1);
      });
  };

  // 파일 저장
  const saveFileImage = (e) => {
    const target = e.target.getAttribute("id").replace("camera_", "");
    if (target == 1) {
      setFileImage1(URL.createObjectURL(fileRef1.current.files[0]));
    } else if (target == 2) {
      setFileImage2(URL.createObjectURL(fileRef2.current.files[0]));
    } else if (target == 3) {
      setFileImage3(URL.createObjectURL(fileRef3.current.files[0]));
    } else if (target == 4) {
      setFileImage4(URL.createObjectURL(fileRef4.current.files[0]));
    }
  };

  useEffect (() => {
    stateUtilityInstance.setMobileHeaderTitle('리뷰남기기');
  })

  return (
    <div className="daps-frame">
      <ul className="food-review-area">
        <li className="review-title">
          <p>리뷰 남기기</p>
        </li>
        <li>
          <div className="store-name">
          <p>{state.productName == undefined || state.productName == null ? state?.s_name : state?.productName}</p>

          </div>
        </li>
        <li>
          <div className="star-check" ref={starRef}>
            <input
              type="checkbox"
              id="one-star"
              value="1"
              onChange={starClickEvent}
            />
            <label htmlFor="one-star" />
            <input
              type="checkbox"
              id="two-star"
              value="2"
              onChange={starClickEvent}
            />
            <label htmlFor="two-star" />
            <input
              type="checkbox"
              id="three-star"
              value="3"
              onChange={starClickEvent}
            />
            <label htmlFor="three-star" />
            <input
              type="checkbox"
              id="four-star"
              value="4"
              onChange={starClickEvent}
            />
            <label htmlFor="four-star" />
            <input
              type="checkbox"
              id="five-star"
              value="5"
              onChange={starClickEvent}
            />
            <label htmlFor="five-star" />
          </div>
        </li>
        <li>
          <div className="review-box">
            <textarea id="text" ref={contentsRef}></textarea>
          </div>
        </li>
        <li>
          <ul className="camera-box">
            <li>
              <input
                type="file"
                id="camera_1"
                accept="image/*"
                onChange={saveFileImage}
                ref={fileRef1}
              />
              <label htmlFor="camera_1">
                {fileImage1 && <img alt="리뷰 이미지" src={fileImage1} />}
                <button
                  onClick={() => {
                    URL.revokeObjectURL(fileImage1);
                    fileRef1.current.value = "";
                    setFileImage1("");
                  }}
                  className={fileImage1 ? "img-delete block" : "img-delete"}
                />
              </label>
            </li>
            <li>
              <input
                type="file"
                id="camera_2"
                accept="image/*"
                onChange={saveFileImage}
                ref={fileRef2}
              />
              <label htmlFor="camera_2">
                {fileImage2 && <img alt="리뷰 이미지" src={fileImage2} />}
                <button
                  onClick={() => {
                    URL.revokeObjectURL(fileImage2);
                    fileRef2.current.value = "";
                    setFileImage2("");
                  }}
                  className={fileImage2 ? "img-delete block" : "img-delete"}
                />
              </label>
            </li>
            <li>
              <input
                type="file"
                id="camera_3"
                accept="image/*"
                onChange={saveFileImage}
                ref={fileRef3}
              />
              <label htmlFor="camera_3">
                {fileImage3 && <img alt="리뷰 이미지" src={fileImage3} />}
                <button
                  onClick={() => {
                    URL.revokeObjectURL(fileImage3);
                    fileRef3.current.value = "";
                    setFileImage3("");
                  }}
                  className={fileImage3 ? "img-delete block" : "img-delete"}
                />
              </label>
            </li>
            <li>
              <input
                type="file"
                id="camera_4"
                accept="image/*"
                onChange={saveFileImage}
                ref={fileRef4}
              />
              <label htmlFor="camera_4">
                {fileImage4 && <img alt="리뷰 이미지" src={fileImage4} />}
                <button
                  onClick={() => {
                    URL.revokeObjectURL(fileImage4);
                    fileRef4.current.value = "";
                    setFileImage4("");
                  }}
                  className={fileImage4 ? "img-delete block" : "img-delete"}
                />
              </label>
            </li>
          </ul>
        </li>
        <li>
          <div className="review-save">
            <button className="review-save-button" onClick={submitClickEvent}>
              저장
            </button>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default ReviewWriting;
