import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import testimg from "src_assets/item_img.png";

import Pagination from "components/common/Pagination";
// import KakaoAddrPopup from "components/common/KakaoAddrPopup";
import {
  getOrderHistory,
} from "service/OrderService";

import "components/mykiikey/style/Orderlist.css";
import "components/mykiikey/style/Mykiikey.css";
import stateUtilityInstance from "../../TestClass";

import { NewLoading } from "components/common/NewLoading";
import { getBarcodeHistory } from "service/AccountService";

// 지호 220707 주문내역 ziho_ohl
// 2022-07-17 주문내역 가져오기 페이징처리, 로직변경
const BarcodeUseList = ({}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [barcodeHistoryList,setBarcodeHistoryList] = useState([]);

  const [isLoad, setIsLoad] = useState(false);

  const VIEW_LIMIT = 6;
  const [currentPage, setCurrentPage] = useState(1);
  const offset = (currentPage - 1) * VIEW_LIMIT;
  const barcodeHistoryListLimit = barcodeHistoryList.slice(0, offset + VIEW_LIMIT)
    

  const UseTime = ({ use_time }) => {
      return (
        <>
          <p className="use-date">{use_time}</p>
        </>
      );
  };

  const UsePrice = ({ use_price, use_type }) => {
    return (
      <>
        <p className="use-price">{(use_type == 3 ? "" : "- ")}{use_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} 원</p>
      </>
    );
  };

  const UseType = ({ use_type }) => {
    return (
      <>
        <p className="use-type">{(use_type == 2 ? "결제" : "취소")}</p>
      </>
    );
  };

  const liTagList = barcodeHistoryListLimit.map((history, index) => {
    // const order_day = new Date(orderHistory.od_datetime);
    const order_day = new Date(history.br_datetime.replace(" ", "T"));

    // 259200000 : unix timestamp millisecond 3day
    console.log(history.br_item);
    const title = history.br_item?.length > 1 ? history.br_item[0].name + "외 " + (history.br_item.length-1) + "건" : history.br_item[0].name 
    
    return (
      <li key={index}>
        <div className="barcode-use-list-head">
          <div className="barcode-use-list-state">
            <span className="shop-name">{title}</span>
          </div>
        </div>
        <span className="barcode-use-info">
          {/* 현재 시간과 비교 */}
          <UseTime
            use_time={history.br_datetime}
          />
          <UseType
            use_type={history.br_type}
          />
          <UsePrice
            use_price={Math.abs(history.br_price)}
            use_type={history.br_type}
          />
        </span>
        <div className="order-list-function">
        </div>
      </li>
    );
  });

  useEffect(() => {
    stateUtilityInstance.setMobileHeaderTitle("사용내역");
    if(location.state){
      console.log(location.state.barcode.b_code);
      getBarcodeHistory({barcode : location.state.barcode.b_code})
      .then((res)=>{
        console.log(res);
        setIsLoad(true);
        if(res.success){
          setBarcodeHistoryList(res.data);
        }
      })
      .catch((err)=>{
        console.log(err);
      });
    }
    
  }, [location.state]);

  const resultView = (
    <>
      <ul className="daps-ul-list">
        {/* 지호 220707 주문내역 데이터 로딩 */}
        {liTagList}
      </ul>
      {/* <button className="info-chagne-confirm">저장</button> */}
      <Pagination
        limit={VIEW_LIMIT}
        offset={offset}
        type={"barcodeUseList"}
        totalCount={barcodeHistoryList.length}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </>
  );

  return (
    <div className="daps-frame">
      {isLoad ? resultView : <NewLoading tabState={0} index={0} />}
    </div>
  );
};

export default BarcodeUseList;
