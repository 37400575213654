/* global kakao */
import { useEffect } from "react";

import "components/order/style/ShopInfo.css";

import { getPrintList } from "service/OrderService";

const { kakao, navigator } = window;

const KakaoMap = ({ lat, lng, userLocation }) => {
  useEffect(() => {
    let container = document.getElementById("map");
    let options;

    if (userLocation && navigator.geolocation) {
      // GeoLocation을 이용해서 접속 위치를 얻어옵니다
      navigator.geolocation.getCurrentPosition(function (position) {
        var myLat = position.coords.latitude; // 위도
        var myLng = position.coords.longitude; // 경도

        options = {
          center: new kakao.maps.LatLng(myLat, myLng),
          level: 8,
        };

        let map = new kakao.maps.Map(container, options);
        //let openInfowindow = null;

        let selectMarker = null;
        var selectMarkerSrc = "https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/markerStar.png";
        var selectMarkerSize = new kakao.maps.Size(29, 42);
        var selectMarkerZIndex = null;

        getPrintList({
          mLocation: {
            x: myLng
            , y: myLat
          }
        }).then((res) => {
          if (res.success) {
            res.data.forEach(element => {
              var position = new kakao.maps.LatLng(element.y, element.x);

              let marker = new kakao.maps.Marker({
                position: position
                , clickalbe: true
              });

              marker.setMap(map);

              /* var customOverlay = new kakao.maps.CustomOverlay({
                map: map,
                clickable: true,
                content: `
                  <div style="
                    background-color: white;
                    border: 1px solid;
                    border-radius: 10px;
                    padding: 5px;
                    box-sizing: border-box;
                    line-height: 1.2rem;">
                    <p>${element.설치장소.trim()}</p>
                    <p>(${element.도로명.trim()})</p>
                  </div>
                  `,
                position: position,
                xAnchor: 0.5,
                yAnchor: 1.8,
                zIndex: 3
              });

              customOverlay.setVisible(false); */

              kakao.maps.event.addListener(marker, 'click', function () {
                // 마커 위에 인포윈도우를 표시합니다
                /* if (openInfowindow) {
                  openInfowindow.setVisible(false);
                }

                openInfowindow = customOverlay;
                customOverlay.setVisible(true); */

                if (selectMarker) {
                  selectMarker.setImage(null)
                  marker.setZIndex(selectMarkerZIndex);
                }

                marker.setImage(new kakao.maps.MarkerImage(selectMarkerSrc, selectMarkerSize))
                marker.setZIndex(999)
                selectMarker = marker

                document.querySelector("#loc1").innerHTML = element.설치장소.trim()
                document.querySelector("#loc2").innerHTML = element.도로명.trim()

              });
            });
          }
        }).catch((err) => {
          console.log(err);
        });

        // 마커와 인포윈도우를 표시합니다
        var imageSrc = "https://developers.google.com/static/maps/documentation/javascript/images/default-marker.png?hl=ko";
        var imageSize = new kakao.maps.Size(24, 35);
        var markerImage = new kakao.maps.MarkerImage(imageSrc, imageSize)
        let marker = new kakao.maps.Marker({ image: markerImage });
        marker.setPosition(new kakao.maps.LatLng(myLat, myLng));
        marker.setMap(map);
      });

    } else { // HTML5의 GeoLocation을 사용할 수 없을때 마커 표시 위치와 인포윈도우 내용을 설정합니다
      options = {
        center: new kakao.maps.LatLng(lat, lng),
        level: 5,
      };

      let map = new kakao.maps.Map(container, options);
      let marker = new kakao.maps.Marker();

      marker.setPosition(new kakao.maps.LatLng(lat, lng));
      marker.setMap(map);
    }


  }, []);

  return (
    <div id="map" className="map-api-print" style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
      loading ...
    </div>
  );
};

export default KakaoMap;
