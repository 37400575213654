import { useState, useEffect } from "react";
import ReactBarcode from "react-jsbarcode";
import { useNavigate } from "react-router-dom";
import SwipeableViews from "react-swipeable-views";
import "components/mykiikey/style/MyWallet.css";
import {
  barcodeRefund,
  createPrivateBarcode,
  checkPassword,
  getBarcodeData,
  getMyWallet,
} from "service/AccountService";
import stateUtilityInstance from "TestClass";
import historyIcon from "src_assets/mywallet/historyIcon.svg";
import chargePointIcon from "src_assets/mywallet/chargePointIcon.svg";
import reissuanceIcon from "src_assets/mywallet/reissuanceIcon.svg";
import plusBtnIcon from "src_assets/mywallet/plusBtnIcon.svg";
import {
  isKiikeyApp,
  IS_ANDROID,
  IS_IOS,
  serverErrorHandler,
} from "App-config";

const MyWallet = ({ userInfo }) => {
  const [myBarcodeList, setMyBarcodeList] = useState([]);
  const [myPrivateBarcode, setMyPrivateBarcode] = useState();
  const [tabIndex, setTabIndex] = useState(
    stateUtilityInstance.getPropertyByName("selectedMyWalletTabIndex")
  );

  const loadWalletData = () => {
    getMyWallet()
      .then((res) => {
        if (res.success) {
          console.log(res.data);
          let barcodeDataList = res.data;
          const params = new URLSearchParams(window.location.search);

          if (params && params.get("b_code")) {
            let newBarcode = barcodeDataList.find(
              (data) => data.b_code == params.get("b_code")
            );
            if (newBarcode) {
              barcodeDataList = barcodeDataList.filter(
                (data) => data.b_code != params.get("b_code")
              );
              barcodeDataList = [newBarcode, ...barcodeDataList];
            }
          }

          if (res.data.length > 0 && tabIndex == 0) setTabIndex(1);

          setMyPrivateBarcode(barcodeDataList.shift());
          setMyBarcodeList(barcodeDataList);
        }
      })
      .catch((err) => {
        console.log(err);

        serverErrorHandler(err);
      });
  };
  useEffect(() => {
    stateUtilityInstance.setMobileHeaderTitle("내 지갑");

    let ele = document.querySelector(".mykiikey-frame");
    ele.style.marginBottom = 0;

    loadWalletData();
  }, []);

  const handleChangeIndex = (index) => {
    //window.scrollTo(0,0);
    //setTitle(state?.c3_list[index].c3_name)
    //console.dir(state);
    //console.dir(setTitle);
    //console.log(state?.c3_list[index].c3_name);
    //document.getElementById('mobileTitle').innerText = state?.c3_list[index].c3_name;
    //ori_index = index;
    stateUtilityInstance.setPropertyValue("selectedMyWalletTabIndex", index);
    setTabIndex(index);
  };

  return (
    <div
      className="my-wallet"
      style={{
        height: window.innerHeight - 70,
      }}
    >
      <section className="my-wallet-barcode-header">
        <div>
          <span className="barcode-header-main-text">이쁨 바코드</span>
          <span className="barcode-header-sub-text">
            쉽고 간편한 이쁨 바코드 결제!
          </span>
        </div>
      </section>
      <SwipeableViews
        resistance
        index={tabIndex}
        onChangeIndex={handleChangeIndex}
        style={{
          width: "80%",
          padding: "0 10% 0 10%",
        }}
        slideStyle={
          {
            //padding :'0 10px 0 5px'
          }
        }
      >
        <CreateBarcodeView
          setMyBarcodeList={setMyBarcodeList}
          setTabIndex={setTabIndex}
        />
        {myPrivateBarcode && <BarcodeView barcode={myPrivateBarcode} />}
        {myBarcodeList &&
          myBarcodeList.map((data) => {
            return <BarcodeView barcode={data} />;
          })}
      </SwipeableViews>
    </div>
  );
};

const CreateBarcodeView = ({ setMyBarcodeList, setTabIndex }) => {
  const navigate = useNavigate();

  const createBarcodeBtnClick = (e) => {
    navigate("/order/inputConfirm", { state: { is_private: false } });
    createPrivateBarcode({})
      .then((res) => {
        if (res.success) {
          console.log("바코드 생성");
          console.log(res.data);
          setMyBarcodeList((data) => [res.data, ...data]);
          setTabIndex(1);
        }
      })
      .catch((err) => {
        console.error(err);
        alert("바코드 생성에러");
      });
  };

  return (
    <section className="my-wallet-barcode-view-area">
      <section className="my-wallet-barcode-area">
        <div className="barcode-content">
          <button onClick={createBarcodeBtnClick}>
            <img src={plusBtnIcon} />
          </button>
        </div>
      </section>

      <section className="my-wallet-barcode-warning-area">
        {/* <span>미사용 바코드의 환불은 구매 후 3일 이내에 가능합니다.</span> */}
        <span>생성된 바코드의 잔여 포인트를 환급 신청시</span>
        <span>계정의 광고 포인트로 환급받을 수 있습니다.</span>
      </section>
    </section>
  );
};

const BarcodeView = ({ barcode }) => {
  const navigate = useNavigate();
  const [barcodeData, setBarcodeData] = useState(barcode);
  const [passwordCheck, setPasswordCheck] = useState(null);

  useEffect(() => {
    const nativeEventCallback = (event) => {
      //alert('event receive from Native' + barcode.b_code);
      getBarcodeData({ barcode: barcodeData.b_code })
        .then((res) => {
          if (res.success) {
            setBarcodeData(res.data);
          }
        })
        .catch((err) => {
          console.log(err);
          window.location.reload();
        });
    };

    window.addEventListener(
      "javascriptFunction" + barcode.b_code,
      nativeEventCallback
    );

    return () => {
      window.removeEventListener(
        "javascriptFunction" + barcode.b_code,
        nativeEventCallback
      );
    };
  }, [barcodeData]);

  useEffect(() => {
    if (passwordCheck) {
      const temp = document.createElement("div");
      temp.classList.add("password-check-modal-frame");

      temp.innerHTML = `
                    <div class="password-check-modal-wrap">
                        <div class="password-check-modal">
                            <div class="title">
                                <p>비밀번호 확인</p>
                            </div>
                            <div class="input">
                                <span>비밀번호</span>
                                <input id="pwCheck" type="password" />
                            </div>
                            <div class="button">
                            </div>
                        </div>
                    </div>
            `;

      document.body.append(temp);

      const cancel = document.createElement("button");
      cancel.innerHTML = "취소";
      cancel.classList.add("cancel");
      cancel.onclick = () => {
        setPasswordCheck(null);
      };

      const submit = document.createElement("button");
      submit.innerHTML = "확인";
      submit.classList.add("submit");
      submit.onclick = () => {
        const pw = document.getElementById("pwCheck").value;

        checkPassword(pw)
          .then((res) => {
            if (res.success) {
              createPrivateBarcode({ barcode: barcodeData.b_code })
                .then((res) => {
                  if (res.success) {
                    alert("바코드가 변경되었습니다.");
                    setBarcodeData(res.data);
                    setPasswordCheck(null);
                  } else {
                    alert(res.msg);
                  }
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          })
          .catch((err) => {
            document.getElementById("pwCheck").value = "";
            alert(err.msg);
            console.error(err);
          });
      };

      document
        .querySelector(
          ".password-check-modal-frame .password-check-modal-wrap .password-check-modal .button"
        )
        .appendChild(cancel);
      document
        .querySelector(
          ".password-check-modal-frame .password-check-modal-wrap .password-check-modal .button"
        )
        .appendChild(submit);
    } else {
      if (document.querySelector(".password-check-modal-wrap")) {
        const data = document
          .getElementsByClassName("password-check-modal-frame")[0]
          .remove();
      }
    }
  }, [passwordCheck]);

  // const PasswordCheckModal = () => {
  //     return (
  //         <div className="password-check-modal-frame">
  //             <div className="password-check-modal-wrap">
  //                 <div className="password-check-modal">
  //                     <div className="title">
  //                         <p>비밀번호 확인</p>
  //                     </div>
  //                     <div className="input">
  //                         <span>비밀번호</span>
  //                         <input id="pwCheck" type="password" />
  //                     </div>
  //                     <div className="button">
  //                         <button className="cancel" onClick={() => setPasswordCheck(null)}>취소</button>
  //                         <button className="submit" onClick={reissuanceClickEvent}>확인</button>
  //                     </div>
  //                 </div>
  //             </div>
  //         </div>
  //     );
  // };

  const chargePointClickEvent = (e) => {
    navigate("/order/inputConfirm", { state: { is_private: true } });
  };

  const refundClickEvent = (e) => {
    barcodeRefund({ barcode: barcodeData.b_code })
      .then((res) => {
        if (res.success) {
          alert("환급이 완료되었습니다.");

          document
            .querySelector(".barcode-text-private")
            .closest(".barcode-unit-area")
            .querySelector("div .barcode-price").innerText =
            res.data[0].b_price
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "원";

          setBarcodeData(res.data[1]);
        } else {
          alert(res.msg);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const reissuanceClickEvent = (e) => {
  //     const pw = document.getElementById('pwCheck').value

  //     checkPassword(pw)
  //         .then((res) => {
  //             if (res.success) {
  //                 reissuanceBarcode({ barcode: barcodeData.b_code })
  //                     .then((res) => {
  //                         if (res.success) {
  //                             alert("바코드가 변경되었습니다.")
  //                             setBarcodeData(res.data);
  //                             setPasswordCheck(null);
  //                         }
  //                         else {
  //                             alert(res.msg);
  //                         }
  //                     })
  //                     .catch((err) => {
  //                         console.log(err);
  //                     });
  //             }
  //         })
  //         .catch((err) => {
  //             document.getElementById('pwCheck').value = "";
  //             alert(err.msg);
  //             console.error(err);
  //         });
  // }

  const barcodeTextView = barcodeData.b_code
    .toString()
    .toUpperCase()
    .replace(/\B(?=(\w{4})+(?!\w))/g, " ")
    .split(" ")
    .map((text) => {
      return (
        <>
          <span>{text}</span>
        </>
      );
    });

  return (
    <section className="my-wallet-barcode-view-area">
      <section className="my-wallet-barcode-area">
        <div className="barcode-content-wrap">
          <div className="barcode-content">
            <div className="barcode-unit-area">
              <div>
                <span className="barcode-price-unit">
                  {barcodeData.is_private ? "포인트" : "잔액"}
                </span>
                <span className="barcode-price">
                  {barcodeData.b_price
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  원
                </span>
              </div>
              <div>
                {barcodeData.is_private ? (
                  <span className="barcode-text-private">바코드</span>
                ) : (
                  <span className="barcode-text-public">바코드</span>
                )}
              </div>
            </div>

            <ReactBarcode
              value={barcodeData.b_code}
              options={{
                format: "code128",
                // width : 1,
                // height : 60,
                displayValue: false,
              }}
              renderer="canvas"
            />

            <div className="barcode-text-area">{barcodeTextView}</div>
          </div>
        </div>
      </section>

      <section className="my-wallet-barcode-btn-area">
        <div
          onClick={() => {
            navigate("/mykiki/barcodeUseList", {
              state: { barcode: barcodeData },
            });
          }}
        >
          <img src={historyIcon} />
          <span>사용내역</span>
        </div>
        {barcodeData.is_private && (
          <div onClick={chargePointClickEvent}>
            <img src={chargePointIcon} />
            <span>충전</span>
          </div>
        )}
        {barcodeData.is_private == true ? (
          <div onClick={() => setPasswordCheck(true)}>
            <img src={reissuanceIcon} />
            <span>재발급</span>
          </div>
        ) : (
          <div onClick={refundClickEvent}>
            <img src={reissuanceIcon} />
            <span>환급</span>
          </div>
        )}
      </section>
      {/* {passwordCheck && <PasswordCheckModal />} */}
    </section>
  );
};

export default MyWallet;
