import { useState } from "react";

import Pagination from "components/common/Pagination";

import "components/mykiikey/style/Mykiikey.css";
import "components/mykiikey/style/ReviewList.css";
import stateUtilityInstance from "../../TestClass";
import { REVIEW_IMG_PATH } from "App-config";
import { deleteReview } from "service/AccountService";
import { useEffect } from "react";

const ReviewList = ({ reviewList, setReviewList, isMobile }) => {
  const [reviewDetail, setReviewDetail] = useState(null);
  const VIEW_LIMIT = 5;
  const [currentPage, setCurrentPage] = useState(1);
  const offset = (currentPage - 1) * VIEW_LIMIT;

  const reviewListLimit = isMobile
    ? reviewList.slice(0, offset + VIEW_LIMIT)
    : reviewList.slice(offset, offset + VIEW_LIMIT);

  const reviewDeleteClickEvent = (data) => {
    deleteReview({ rIdx: data.r_idx })
      .then((res) => {
        if (res.success) {
          setReviewList(
            reviewList.filter((review) => review.r_idx != data.r_idx)
          );
        }
      })
      .catch(console.error);
  };

  const liTagList = reviewListLimit.map((item, index) => {
    return (
      <li key={index}>
        <div className="review-list-head">
          <span className="review-list-name">{item.sp_product_nm}</span>
        </div>
        <div className="review-list-date">
          <span>{item.r_datetime}</span>
          <ul className="review-list-body">
            <li>
              <button
                className="review-check-button"
                onClick={() => setReviewDetail(item)}
              >
                리뷰확인
              </button>
            </li>
            <li className="review-button">
              <button
                className="review-delete-button"
                onClick={() => reviewDeleteClickEvent(item)}
              >
                리뷰삭제
              </button>
            </li>
          </ul>
        </div>
      </li>
    );
  });

  const ReviewModal = () => {
    const OneImgs = () => {
      return (
        <div className="review-img-one">
          <img src={REVIEW_IMG_PATH + reviewDetail.r_imgs[0]} alt="이미지" />
        </div>
      );
    };

    const TwoImgs = () => {
      return (
        <div className="review-img-two">
          <div className="section-two-one">
            <img
              src={REVIEW_IMG_PATH + reviewDetail.r_imgs[0]}
              alt="이미지"
            ></img>
          </div>
          <div className="section-two-two">
            <img
              src={REVIEW_IMG_PATH + reviewDetail.r_imgs[1]}
              alt="이미지"
            ></img>
          </div>
        </div>
      );
    };

    const ThreeImgs = () => {
      return (
        <div className="review-img-three">
          <div className="section-three-top">
            <img
              src={REVIEW_IMG_PATH + reviewDetail.r_imgs[0]}
              alt="이미지"
            ></img>
          </div>
          <div className="section-three-bottom">
            <div className="three-bottom-one">
              <img
                src={REVIEW_IMG_PATH + reviewDetail.r_imgs[1]}
                alt="이미지"
              ></img>
            </div>
            <div className="three-bottom-two">
              <img
                src={REVIEW_IMG_PATH + reviewDetail.r_imgs[2]}
                alt="이미지"
              ></img>
            </div>
          </div>
        </div>
      );
    };

    const FourImgs = () => {
      return (
        <div className="review-img-four">
          <div className="section-four-top">
            <div className="four-top-one">
              <img
                src={REVIEW_IMG_PATH + reviewDetail.r_imgs[0]}
                alt="이미지"
              ></img>
            </div>
            <div className="four-top-two">
              <img
                src={REVIEW_IMG_PATH + reviewDetail.r_imgs[1]}
                alt="이미지"
              ></img>
            </div>
          </div>
          <div className="section-four-bottom">
            <div className="four-bottom-one">
              <img
                src={REVIEW_IMG_PATH + reviewDetail.r_imgs[2]}
                alt="이미지"
              ></img>
            </div>
            <div className="four-bottom-two">
              <img
                src={REVIEW_IMG_PATH + reviewDetail.r_imgs[3]}
                alt="이미지"
              ></img>
            </div>
          </div>
        </div>
      );
    };


    const CeoContent = ({ reviewDetail }) => {
      return (
        <div className="order-history">
          <p>사장님답글</p>
          <span>
            {reviewDetail.r_ceo_content == "" ||
            reviewDetail.r_ceo_content == null
              ? ""
              : reviewDetail.r_ceo_content.length > 50
              ? reviewDetail.r_ceo_content.substr(0, 50) + "..."
              : reviewDetail.r_ceo_content}
          </span>
        </div>
      );
    }

    return (
      <div className="review-modal-frame">
        <div className="review-modal">
          <div className="review-title">
            <p>리뷰확인</p>
            <button onClick={() => setReviewDetail(null)}>X</button>
          </div>
          {reviewDetail.r_imgs.length === 1 && <OneImgs />}
          {reviewDetail.r_imgs.length === 2 && <TwoImgs />}
          {reviewDetail.r_imgs.length === 3 && <ThreeImgs />}
          {reviewDetail.r_imgs.length === 4 && <FourImgs />}
          <div className="review-text">{reviewDetail.r_content}</div>
          <div className="order-history">
            <p>주문내역</p>
            <span>{reviewDetail.m_names}</span>
          </div>
          {reviewDetail.r_ceo_content == "" || reviewDetail.r_ceo_content == null ? "" : <CeoContent reviewDetail={reviewDetail} />}

        </div>
      </div>
    );
  };

  useEffect (() => {
    stateUtilityInstance.setMobileHeaderTitle('리뷰내역');
  })
  
  return (
    <div className="daps-frame">
      <div className="daps-ul-list">{liTagList}</div>
      <Pagination
        isMobile={isMobile}
        limit={VIEW_LIMIT}
        offset={offset}
        type={"reviewList"}
        totalCount={reviewList.length}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
      {reviewDetail && <ReviewModal />}
    </div>
  );
};

export default ReviewList;
