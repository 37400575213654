import "components/common/style/NotFoundPage.css";

const NotFoundPage = () => {
  return (
    <section className="notfoundpage-frame">
      <div className="notfoundpage-inner"></div>
    </section>
  );
};

export default NotFoundPage;
