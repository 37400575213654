import { useState } from "react";

import { setScrap } from "service/ADSService";

import Pagination from "components/common/Pagination";

import "components/mykiikey/style/Adwatched.css";
import "components/mykiikey/style/Mykiikey.css";
import stateUtilityInstance from "../../TestClass";
import { useEffect } from "react";

const Adwatched = ({ isMobile, watchList, setScrapList, setWatchList }) => {
  const VIEW_LIMIT = 6;
  const [currentPage, setCurrentPage] = useState(1);
  const offset = (currentPage - 1) * VIEW_LIMIT;

  const watchListLimit = isMobile
    ? watchList.slice(0, offset + VIEW_LIMIT)
    : watchList.slice(offset, offset + VIEW_LIMIT);

  const scarpClickEvent = (item) => {
    setScrap(item)
      .then((res) => {
        if (res.success) {
          alert(res.msg);
          setScrapList(res.data.scrapList);
          setWatchList(res.data.watchList);
        }
      })
      .catch((err) => {
        alert(err.msg);
        setScrapList(err.data?.scrapList);
        setWatchList(err.data?.watchList);
        console.error(err);
      });
  };

  useEffect (() => {
    stateUtilityInstance.setMobileHeaderTitle('광고 시청 리스트');
  })

  const liTagList = watchListLimit.map((item, index) => {
    return (
      <li key={index}>
        <div className="adwatched-title">
          <p>{item.a_name}</p>
        </div>
        <div className="adwatched-info">
          <p>{item.ah_datetime}</p>
          {item.isScrap ? (
            ""
          ) : (
            <button
              className="adwatched-button"
              onClick={(e) => {
                scarpClickEvent(item);
              }}
            >
              광고스크랩
            </button>
          )}
        </div>
      </li>
    );
  });

  return (
    <div className="daps-frame">
      <ul className="daps-ul-list">{liTagList}</ul>
      <Pagination
        isMobile={isMobile}
        limit={VIEW_LIMIT}
        offset={offset}
        type={"adwatched"}
        totalCount={watchList.length}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </div>
  );
};

export default Adwatched;
