import { useEffect } from "react";

import { auth, RecaptchaVerifier } from "service/firebase";

import { RECAPTCHA_ID } from "App-config";

const AuthComponent = ({
  isView,
  setAuthView,
  setAuthInputView,
  phoneInputDisabled,
}) => {
  useEffect(() => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      RECAPTCHA_ID,
      {
        size: "normal",
        callback: function (response) {
          // onSolvedRecaptcha();
          console.log("auth 11");
          setAuthInputView(true);
        },
        "expired-callback": function () {
          console.log("auth 22");
          setAuthView(false);
          phoneInputDisabled(false);
        },
      },
      auth
    );
  }, []);

  return (
    <tr style={{ height: "auto", display: isView ? "" : "none" }}>
      <td className="large-td">
        <div id={RECAPTCHA_ID} />
      </td>
    </tr>
  );
};

export default AuthComponent;
