import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  delBasket,
  getBasket,
  setBuyBasket,
  getMemberInfo,
} from "service/OrderService";

import "components/order/style/OrderHistory.css";
import "components/order/style/OrderDetail.css";
import "components/order/style/ShopMenuFood.css";
import { async } from "@firebase/util";

import { SHOP_CUSTOM_IMG_PATH } from "App-config";
import stateUtilityInstance from "TestClass";

const OrderConfirm = ({ basketReload, userInfo, setUserInfo }) => {
  const navigate = useNavigate();

  let deleteShopPrice = 0;
  const [checkedInputs, setCheckedInputs] = useState({});
  const [checkRadio, setCheckRadio] = useState({});
  const [cAmt, setcAmt] = useState(0);
  const [cOAmt, setcOAmt] = useState(0);
  const [totalCheck, setTotalCheck] = useState(false);
  let totalAmt = 0;
  // .toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  const totalCheckBoxHandler = (e) => {
    const target = e.currentTarget;

    const check = document.querySelectorAll(".order-menu-info-label input");

    if (target.checked) {
      let _checkedInputs = { ...checkedInputs };
      for (const ele of check) {
        if (!ele.checked) {
          ele.checked = true;
          const bm_idx = ele.dataset.bmidx;
          _checkedInputs = {
            ..._checkedInputs,
            [`${bm_idx}`]: true,
          };
        }
      }
      setCheckedInputs(_checkedInputs);
      setTotalCheck(true);
      setPayBasketCount(totalBasketCount);
      setBasketCount(totalBasketCount);
      setcAmt(cOAmt);
    } else {
      let _checkedInputs = { ...checkedInputs };
      for (const ele of check) {
        if (ele.checked) {
          ele.checked = false;
          const bm_idx = ele.dataset.bmidx;
          _checkedInputs = {
            ..._checkedInputs,
            [`${bm_idx}`]: false,
          };
        }
      }
      setCheckedInputs(_checkedInputs);
      setTotalCheck(false);
      setPayBasketCount(0);
      setBasketCount(0);
      setcAmt(0);
    }
  };

  const changeHandler = (checked, id, e, status, menuCnt) => {
    let target;

    let price = 0;
    let cnt = 0;
    if (status) {
      target = e.target
        .closest(".order-way-box")
        .querySelectorAll(".order-content-list")
        [menuCnt].querySelectorAll(".menu-price p");

      price = target[0]?.innerText.replace("원", "").replaceAll(",", "");
      cnt = target[1]?.innerText.replace("개", "").replaceAll(",", "");
    } else {
      const orderMenuNameBox = e.target.closest(".order-menu-name-box");
      const priceEl = orderMenuNameBox.querySelector(".order-menu-info-price");
      const cntEl = orderMenuNameBox.querySelector(".count-num");
      target = [priceEl, cntEl];

      price = target[0]?.innerText.replace("원", "").replaceAll(",", "");
      cnt = target[1]?.innerText.replace("개", "").replaceAll(",", "");
    }
    // if(!target[0]){
    //   navigate(-2);
    // }

    setCheckedInputs({
      ...checkedInputs,
      [`${id}`]: checked,
    });

    if (status) {
      deleteShopPrice = price * cnt;
      console.log("del:" + deleteShopPrice);
      console.log("price:" + price);
      console.log("cnt:" + cnt);
      setcAmt(cAmt * 1 - deleteShopPrice);
    } else {
      console.log("price:" + price);
      console.log("cnt:" + cnt);
      setcAmt(checked ? cAmt * 1 + price * cnt : cAmt * 1 - price * cnt);
    }

    setPayBasketCount(
      checked ? payBasketCount + cnt * 1 : payBasketCount - cnt * 1
    );

    setBasketCount(checked ? basketCount + 1 : basketCount - 1);

    const total = document.querySelectorAll(".order-menu-info-label");
    const check = document.querySelectorAll(
      ".order-menu-info-label input:checked"
    );

    const totalCheckEl = document.querySelector(
      ".order-select-container label input"
    );
    if (total.length == check.length) {
      setTotalCheck(true);
    } else {
      setTotalCheck(false);
    }
  };

  const handleChange = (e, checked, cnt) => {
    let id = e.target.id;
    setCheckRadio({ ...checkRadio, [`${cnt}`]: id });
  };
  const minusClickEvent = (e, index) => {
    const target = e.target.nextElementSibling;
    let val = parseInt(target.innerText);
    if (isNaN(val)) val = 0;
    target.innerText = val > 0 ? val - 1 : val;

    if (val && basketCount > 0) {
      //setBasketCount(basketCount - 1);
    }

    if (!val) return;

    const menuContainer = document.querySelector(
      `.order-history-frame .order-menu-name-box.id-${index}`
    );

    const menuPriceEl = menuContainer.querySelector(".menu-price");
    const pacelPriceEl = menuContainer.querySelector(".pacel-price");
    const resultPriceEl = menuContainer.querySelector(".result-price");

    const price = parseInt(target.dataset.price) * parseInt(target.innerText);
    const pacelPrice = isNaN(pacelPriceEl.innerText)
      ? 0
      : parseInt(pacelPriceEl.innerText.replace("원", "").replaceAll(",", ""));

    menuPriceEl.innerHTML =
      price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "원";

    resultPriceEl.innerHTML =
      (price - pacelPrice).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
      "원";

    /* const prePrice = e.target.previousElementSibling;
    prePrice.innerText =
      parseInt(target.dataset.price) * parseInt(target.innerText);
    prePrice.innerText = prePrice.innerText
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    console.log(prePrice.innerText); */

    const checked = menuContainer.querySelector(".order-menu-checkbox").checked;

    if (checked) {
      /* const amt = document.querySelectorAll(".tAmt");
      amt.forEach((el) => {
        el.innerText =
          parseInt(amt[0].innerText.replace("원", "").replaceAll(",", "")) -
          parseInt(target.dataset.price);
        el.innerText = amt[0].innerText
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }); */
      setcAmt(cAmt * 1 - parseInt(target.dataset.price));
      setPayBasketCount(payBasketCount - 1);
      /* amt[0].innerText =
        parseInt(amt[0].innerText.replace("원", "").replaceAll(",", "")) -
        parseInt(target.dataset.price);
      amt[0].innerText = amt[0].innerText
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      amt[1].innerText = amt[0].innerText
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ","); */
      //setcAmt(cAmt * 1 -  parseInt(target.dataset.price));
    }
  };

  const plusClickEvent = (e, maxCnt, index) => {
    //return;
    const target = e.target.previousElementSibling;
    console.log(target);
    let val = parseInt(target.innerText);
    if (isNaN(val)) val = 0;
    if (!!maxCnt) {
      if (val >= maxCnt) {
        console.log("최대 옵션선택 초과");
        return false;
      }
    }

    //setBasketCount(basketCount + 1);
    target.innerText = val + 1;

    const menuContainer = document.querySelector(
      `.order-history-frame .order-menu-name-box.id-${index}`
    );

    const menuPriceEl = menuContainer.querySelector(".menu-price");
    const pacelPriceEl = menuContainer.querySelector(".pacel-price");
    const resultPriceEl = menuContainer.querySelector(".result-price");

    const price = parseInt(target.dataset.price) * parseInt(target.innerText);
    const pacelPrice = isNaN(pacelPriceEl.innerText)
      ? 0
      : parseInt(pacelPriceEl.innerText.replace("원", "").replaceAll(",", ""));

    menuPriceEl.innerHTML =
      price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "원";

    resultPriceEl.innerHTML =
      (price - pacelPrice).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
      "원";

    /* const prePrice =
      e.target.previousElementSibling.previousElementSibling
        .previousElementSibling;
    prePrice.innerText =
      parseInt(target.dataset.price) * parseInt(target.innerText);
    console.log(target.dataset.price);
    console.log(target.innerText);
    prePrice.innerText = prePrice.innerText
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    console.log(
      e.target.previousElementSibling.previousElementSibling
        .previousElementSibling
    );
    console.log(e.target.parentElement.previousElementSibling); */
    const chekcEl = menuContainer.querySelector(".order-menu-checkbox");
    const checked = menuContainer.querySelector(".order-menu-checkbox").checked;

    if (checked) {
      const amt = document.querySelectorAll(".tAmt");

      /* amt.forEach((el) => {
        el.innerText =
          parseInt(amt[0].innerText.replace("원", "").replaceAll(",", "")) +
          parseInt(target.dataset.price);
        el.innerText = amt[0].innerText
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }); */
      setcAmt(cAmt * 1 + parseInt(target.dataset.price));
      setPayBasketCount(payBasketCount + 1);

      /* amt[0].innerText =
        parseInt(amt[0].innerText.replace("원", "").replaceAll(",", "")) +
        parseInt(target.dataset.price);
      amt[1].innerText = amt[0].innerText
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      amt[1].innerText = amt[0].innerText
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ","); */
      //  setcAmt(cAmt * 1 +  parseInt(target.dataset.price));
    }
  };
  const [basketList, setBasketList] = useState(null);
  const [totalBasketCount, setTotalBasketCount] = useState(0);
  const [basketCount, setBasketCount] = useState(0);
  const [payBasketCount, setPayBasketCount] = useState(0);
  const [basketStatus, setBasketStatus] = useState(false);

  const basketLoad = () => {
    setBasketStatus(false);
    getBasket()
      .then((res) => {
        if (res.success) {
          if (res.data.length < 1) {
            alert("장바구니가 비어있습니다.");
            //navigate(-1);
            navigate("/order", { replace: true });
            //setBasketList([]);
            //setPayBasketCount(0);
            //setBasketStatus(false);
          } else {
            console.dir(res.data);
            setBasketList(res.data);
            var bkCnt = 0;
            var baketcnt = 0;
            res.data.forEach((ele) => {
              ele.rows.forEach((row) => {
                baketcnt += 1;
                bkCnt += row.m_count;
              });
            });
            setTotalBasketCount(baketcnt);
            setBasketCount(baketcnt);
            setPayBasketCount(bkCnt);
            setBasketStatus(true);
          }
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
  useEffect(() => {
    basketLoad();
    stateUtilityInstance.setMobileHeaderTitle("장바구니", true);
  }, []);

  useEffect(() => {
    let checkedItems = {};
    let radioObj = {};
    let totalAmt = 0;
    if (basketStatus) {
      let basketItems = basketList;
      for (let i = 0; i < basketItems.length; i++) {
        let basketItem = basketItems[i];
        basketItem.rows.forEach((item) => {
          checkedItems[`${item.bm_idx}`] = true;
          totalAmt += item.totalPrice * 1;
        });
        radioObj[`${i}`] =
          basketItem.s_is_delivery * 1 == 1
            ? "delivery-way" + i
            : basketItem.s_is_packing * 1 == 1
              ? "wrap-way" + i
              : basketItem.s_is_parcel * 1 == 1
                ? "parcel-way" + i
                : basketItem.c2_idx * 1 == 20
                  ? "ticket-way" + i
                  : basketItem.c2_idx * 1 == 21
                    ? "gift-way" + i
                    : "";
      }
      setCheckedInputs(checkedItems);
      setTotalCheck(true);
      setcAmt(totalAmt);
      setcOAmt(totalAmt);
      setCheckRadio(radioObj);
    }
  }, [basketStatus]);

  const newBasketDeleteHandler = () => {
    const p = Object.keys(checkedInputs).map(
      (bm_idx) =>
        new Promise((resolve) => {
          if (!checkedInputs[bm_idx]) return resolve();
          delBasket({ bmIdx: bm_idx })
            .then((res) => {
              resolve();
            })
            .catch((err) => {
              console.error(err);
              resolve();
            });
        })
    );

    Promise.all(p).then(() => {
      basketLoad();
    });
  };

  const basketDeleteHandler = (e, arg, flag) => {
    let rqArg = {};
    if (flag) {
      rqArg.sIdx = arg;
    } else {
      rqArg.bmIdx = arg;
    }
    delBasket(rqArg)
      .then((res) => {
        let targetSidx = 0;
        let afterLength = 0;

        let newBasketList = [...basketList];
        if (flag) {
          // 샵 전체 지우기
          for (let i = 0; i < newBasketList.length; i++) {
            const basketShop = newBasketList[i];
            if (basketShop.s_idx == arg) {
              newBasketList.splice(i, 1);
              targetSidx = basketShop.s_idx;
              afterLength = newBasketList.length;
              break;
            }
          }
          if (!afterLength) {
            navigate(-1);
          }
          // 샵의 메뉴가격 초기화
          const checkTargetList = e.target
            .closest(".order-way-box")
            .querySelectorAll('[name^="order-menu-checkbox"]:checked');
          let cnt = 0;
          checkTargetList.forEach((checkTarget) => {
            checkTarget.checked = false;
            changeHandler(
              checkTarget.checked,
              checkTarget.dataset.bmidx,
              e,
              true,
              cnt
            );
            cnt++;
          });
        } else {
          // 메뉴를 장바구니에서 지우기
          for (let i = 0; i < newBasketList.length; i++) {
            const basketShop = newBasketList[i];
            for (let j = 0; j < basketShop.rows.length; j++) {
              const rows = basketShop.rows[j];
              if (rows.bm_idx == arg) {
                newBasketList[i].rows.splice(j, 1);
                targetSidx = basketShop.s_idx;
                afterLength = newBasketList[i].rows.length;
                break;
              }
            }
          }
          // 메뉴를 지운 후 해당 매장의 메뉴가 없으면 매장전체 삭제
          if (afterLength < 1) {
            for (let i = 0; i < newBasketList.length; i++) {
              const basketShop = newBasketList[i];
              if (basketShop.s_idx == targetSidx) {
                newBasketList.splice(i, 1);
                break;
              }
            }
            if (newBasketList.length < 1) {
              navigate(-1);
            }
          }
          // 메뉴가격 초기화
          const checkTarget = e.target
            .closest(".order-menu-name-box")
            .querySelector('[name^="order-menu-checkbox"]:checked');
          if (checkTarget) {
            checkTarget.checked = false;
            changeHandler(checkTarget.checked, checkTarget.id, e);
          }
        }
        //

        setBasketList(newBasketList);
      })
      .catch((err) => {
        navigate(-1);
        console.error(err);
      });
    basketReload();
  };

  // 상점마다 접는기능
  const folderClickEvent = (e) => {
    e.target.parentElement.nextSibling.classList.toggle("folder");
  };

  // // 메뉴체크
  // const menuOptionCheckHandler = (e, menu) => {
  //   const tgList = document.querySelectorAll(".tAmt");
  //   const cAmt = tgList[0].innerText.replace("원", "").replaceAll(",", "");
  //   if (e.target.checked) {
  //     tgList.forEach((ele) => {
  //       ele.innerText =
  //         (cAmt * 1 + menu.totalPrice)
  //           .toString()
  //           .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "원";
  //     });
  //   } else {
  //     tgList.forEach((ele) => {
  //       ele.innerText =
  //         (cAmt * 1 - menu.totalPrice)
  //           .toString()
  //           .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "원";
  //     });
  //     const radioTarget = e.target
  //       .closest(".order-list-wrap")
  //       .querySelector('[name^="order-way-radio"]:checked');
  //     if (radioTarget) {
  //       radioTarget.checked = false;
  //     }
  //   }
  // };

  const DelivType = ({ shop, cnt }) => {
    return (
      <div className="order-way-select">
        {shop.used_schedule > 0 ? (
          <>
            <input
              type="radio"
              name={"order-way-radio" + cnt}
              id={"schedule-way" + cnt}
              value="4"
              onChange={(e) => {
                handleChange(e, e.currentTarget.checked, cnt);
              }}
              checked={checkRadio[cnt] == "schedule-way" + cnt ? true : false}
            />
            <label htmlFor={"schedule-way" + cnt} />
          </>
        ) : (
          <>
            {shop.s_idx == 10363 && ( // 기프티콘 샵 s_idx
              <>
                <input
                  type="radio"
                  name={"order-way-radio" + cnt}
                  id={"gift-way" + cnt}
                  value="7"
                  onChange={(e) => {
                    handleChange(e, e.currentTarget.checked, cnt);
                  }}
                  checked={checkRadio[cnt] == "gift-way" + cnt ? true : false}
                />
                <label htmlFor={"gift-way" + cnt} />
              </>
            )}
            {shop.s_idx == 10228 && (
              <>
                <input
                  type="radio"
                  name={"order-way-radio" + cnt}
                  id={"ticket-way" + cnt}
                  value="6"
                  onChange={(e) => {
                    handleChange(e, e.currentTarget.checked, cnt);
                  }}
                  checked={checkRadio[cnt] == "ticket-way" + cnt ? true : false}
                />
                <label htmlFor={"ticket-way" + cnt} />
              </>
            )}
            {shop.s_is_delivery > 0 && (
              <>
                <input
                  type="radio"
                  name={"order-way-radio" + cnt}
                  id={"delivery-way" + cnt}
                  value="2"
                  onChange={(e) => {
                    handleChange(e, e.currentTarget.checked, cnt);
                  }}
                  checked={
                    checkRadio[cnt] == "delivery-way" + cnt ? true : false
                  }
                />
                <label htmlFor={"delivery-way" + cnt} />
              </>
            )}
            {shop.s_is_packing > 0 && (
              <>
                <input
                  type="radio"
                  name={"order-way-radio" + cnt}
                  id={"wrap-way" + cnt}
                  value="1"
                  onChange={(e) => {
                    handleChange(e, e.currentTarget.checked, cnt);
                  }}
                  checked={checkRadio[cnt] == "wrap-way" + cnt ? true : false}
                />
                <label htmlFor={"wrap-way" + cnt} />
              </>
            )}

            {shop.s_is_parcel > 0 && (
              <>
                <input
                  type="radio"
                  name={"order-way-radio" + cnt}
                  id={"parcel-way" + cnt}
                  value="5"
                  onChange={(e) => {
                    handleChange(e, e.currentTarget.checked, cnt);
                  }}
                  checked={checkRadio[cnt] == "parcel-way" + cnt ? true : false}
                />
                <label htmlFor={"parcel-way" + cnt} />
              </>
            )}
          </>
        )}
      </div>
    );
  };

  const MenuOptionCate = ({ optCateList }) => {
    return optCateList.map((optCate, index) => {
      return (
        <li key={optCate.moc_idx + index} style={{ display: "none" }}>
          <span className="opction-class">{optCate.moc_name}</span>
          <MenuOptionList optList={optCate.optionArray} />
        </li>
      );
    });
  };
  const MenuOptionList = ({ optList }) => {
    return optList.map((opt, index) => {
      return (
        <div className="opciton-price-box" key={opt.mo_idx + index}>
          <span className="opction-name">
            <p>└</p>
            {opt.mo_name}
          </span>
          <span>
            <p>
              {opt.mo_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}원
            </p>
            <p>{opt.mo_count}개</p>
          </span>
        </div>
      );
    });
  };

  const MenuList = ({ menuList }) => {
    const mList = menuList.map((menu, index) => {
      return (
        <ul className="order-content-list" key={menu.m_idx + index}>
          <div className="order-menu-name-box">
            <span
              className="menu-function"
              style={{ fontWeight: "100", fontSize: "6px", width: "100%" }}
            >
              <input
                type="checkbox"
                id={"menu-check" + menu.m_idx + index}
                name={"order-menu-checkbox" + index}
                className="order-menu-checkbox"
                // value={menu.m_idx}
                data-bmidx={menu.bm_idx}
                onChange={(e) => {
                  changeHandler(e.currentTarget.checked, menu.bm_idx, e);
                }}
                checked={checkedInputs[menu.bm_idx] ? true : false}
              />
              <label htmlFor={"menu-check" + menu.m_idx + index}></label>
              <span className="menu-name">{menu.m_name}</span>
            </span>
            <span className="menu-price">
              <p>
                {menu.optionCateArray.length > 0
                  ? menu.totalPrice
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  : menu.m_price *
                    menu.m_count
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                원
              </p>
              <button
                className="count-btn"
                onClick={minusClickEvent}
                style={{ backgroundImage: "none" }}
              >
                -
              </button>
              <p className="count-num" data-price={menu.m_price}>
                {menu.m_count}
              </p>
              개
              <button
                className="count-btn"
                onClick={plusClickEvent}
                style={{ backgroundImage: "none" }}
              >
                +
              </button>
              <button
                className="order-cancel-button"
                onClick={(e) => basketDeleteHandler(e, menu.bm_idx)}
              ></button>
            </span>
          </div>
          {menu.optionCateArray.length > 0 && (
            <MenuOptionCate optCateList={menu.optionCateArray} />
          )}
        </ul>
      );
    });
    return mList;
  };

  const GiftGuide = () => {
    const giftGuide = basketList.map((shop, index) => {
      if (shop.s_idx == "10363") {
        return (
          <>
            <p>미사용 기프티콘의 환불은 구매 후 5일 이내에 가능합니다.</p>
            <p>기프티콘의 유효기간은 30일이며 유효기간 종료 후</p>
            <p>환불/기간연장은 불가능합니다.</p>
          </>
        );
      }
    });
    return giftGuide;
  };

  const ShopComponent = () => {
    const shopList = basketList.map((shop, index) => {
      return (
        <div className="order-way-box" key={shop.s_idx}>
          <div className="shop-folder">
            <div className="shop-name">
              <span className="order-title">{shop.s_name}</span>
              <button
                className="black-btn"
                onClick={(e) => basketDeleteHandler(e, shop.s_idx, true)}
              ></button>
            </div>
            <button className="drop" onClick={folderClickEvent}></button>
          </div>
          <div className="order-list-house">
            <div className="order-list-wrap" data-sidx={shop.s_idx}>
              <DelivType shop={shop} cnt={index} />
              <div className="order-content">
                <MenuList menuList={shop.rows} />
                <button
                  className="add-menu"
                  onClick={() => {
                    menuAddClickHandler(shop);
                  }}
                >
                  + 쇼핑 계속하기
                </button>
              </div>
            </div>
            <div className="bottomline"></div>
          </div>
        </div>
      );
    });
    return shopList;
  };

  const menuAddClickHandler = (shop) => {
    /*     if (shop.c2_idx == 21) { // 기프티콘 샵 메뉴 추가시 상품 화면으로 이동
      
    } else {
      navigate("/order/shop", { state: { shopInfo: shop } });
    } */

    navigate("/order");
  };

  const paymentClickHandler = async () => {
    const targetList = document.querySelectorAll(
      ".order-menu-info-label input:checked"
    );
    if (targetList.length < 1) {
      alert("결제하실 상품을 선택해주세요.");
      return;
    }
    let buyList = [];
    for (let i = 0; i < targetList.length; i++) {
      const ele = targetList[i];
      /* const odOption = ele
        .closest(".order-list-wrap")
        .querySelector('[name^="order-way-radio"]:checked')?.value;
      if (!odOption) {
        ele.closest(".order-way-box").scrollIntoView();
        //alert("옵션을3 선택해주세요.");
        // return;
      } */
      const sIdx = ele.closest(".order-list-wrap").dataset.sidx;

      if (sIdx == "10363") {
        // 기프티콘 샵 s_idx

        console.log("start", userInfo.mb_auth);
        const res = await getMemberEventHandler();
        console.log("res :: ", res);
        if (res.mb_auth <= 0) {
          console.log(4);
          if (window.confirm("기프티콘 주문은 휴대폰 인증 후 가능합니다.")) {
            navigate("/account/JustAuth", {
              state: {
                mbId: res.mb_id,
                mbPhone: res.mb_phone,
              },
            });
            return;
          } else {
            return;
          }
        }
      }

      const bmIdx = ele.dataset.bmidx;
      const mCount = ele
        .closest(".order-menu-name-box")
        .querySelector(".count-num").innerText;
      // console.log(mCount);
      buyList.push({
        sIdx: sIdx,
        //odOption: odOption,
        bmIdx: bmIdx,
        mCount: mCount,
      });
    }

    setBuyBasket({ dataList: buyList })
      .then((res) => {
        navigate("/order/payment");
      })
      .catch((err) => {
        alert(err);
        console.error(err);
      });
  };

  const getMemberEventHandler = () => {
    return getMemberInfo()
      .then((res) => {
        console.log(1);
        setUserInfo(res);
        console.log(2);
        console.log(3);
        return res;
      })
      .catch((err) => {
        console.error("payment: err :: ", err);
        //에러처리필요
      });
  };

  const orderItemInfoStyle = {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    gap: "0.2em",
  };

  const orderItemPriceStyle = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",

    color: "#000",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontSize: "15px",
    fontWeight: "500",
    lineHeight: "150%",
  };

  const paymentDetailPriceStyle = {
    width: "100%",
    textAlign: "right",
  };

  return (
    <section className="order-history-frame">
      <span className="order-frame-title oft-btm">장바구니</span>
      <div className="order-mainInfo">
        <div className="order-total-count">총 {totalBasketCount} 개</div>
        <div className="order-select-container">
          <label>
            <input
              type="checkbox"
              onChange={(e) => totalCheckBoxHandler(e)}
              checked={totalCheck}
            />
            <div>
              <img />
              <span>전체선택</span>
            </div>
          </label>
          <div
            style={{
              padding: "0.3em 0.5em 0.2em",
              color: "#FFF",
              background: "#BDBDBD",
              borderRadius: "8px",
              fontSize: "15px",
              fontFamily: "Inter",
              fontWeight: "500",
              fontStyle: "normal",
              lineHeight: "150%",
            }}
            onClick={() => newBasketDeleteHandler()}
          >
            선택삭제
          </div>
        </div>
      </div>
      {basketList && basketList.length && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1em",
            margin: "1em 0",
          }}
        >
          {basketList.map((shop) => {
            return shop.rows.map((menu, index) => {
              const menuPrice =
                menu.optionCateArray.length > 0
                  ? menu.totalPrice
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  : (menu.m_price * menu.m_count)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              const pacelPrice =
                parseInt(menuPrice.replaceAll(",", "")) <= 50000 ? 4500 : 0;

              const resultPrice =
                parseInt(menuPrice.replaceAll(",", "")) + parseInt(pacelPrice);

              return (
                <div
                  className={`order-menu-name-box id-${index} order-list-wrap`}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    borderRadius: "8px",
                    border: "1px solid #E0E0E0",
                    overflow: "hidden",
                  }}
                  data-sidx={shop.s_idx}
                >
                  <div style={{ padding: "1em 1em 0.2em" }}>
                    <label className="order-menu-info-label">
                      <input
                        type="checkbox"
                        id={"menu-check" + menu.m_idx + index}
                        name={"order-menu-checkbox" + index}
                        className="order-menu-checkbox"
                        data-bmidx={menu.bm_idx}
                        onChange={(e) => {
                          changeHandler(
                            e.currentTarget.checked,
                            menu.bm_idx,
                            e
                          );

                          const target = e.currentTarget;
                          const parent =
                            target.parentNode.parentNode.parentNode;
                          const 상품금액 = parent.querySelector(".menu-price");
                          const 배송비 = parent.querySelector(".pacel-price");
                          const 주문금액 =
                            parent.querySelector(".result-price");
                          if (!target.checked) {
                            상품금액.innerText = "0원";
                            배송비.innerText = "0원";
                            주문금액.innerText = "0원";
                          } else {
                            상품금액.innerText = `${menuPrice}원`;
                            배송비.innerText = pacelPrice
                              ? `${pacelPrice}원`
                              : "무료배송";
                            주문금액.innerText = `${resultPrice
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}원`;
                          }
                        }}
                        checked={checkedInputs[menu.bm_idx] ? true : false}
                        style={{ display: "none" }}
                      />

                      <div style={orderItemInfoStyle}>
                        <img className="order-menu-checkbox-img" />
                        <div
                          style={{
                            display: "grid",
                            gridTemplateColumns: "auto 1fr",
                            justifyItems: "center",
                            gap: "0.5em",
                            width: "100%",
                          }}
                        >
                          <div
                            style={{
                              width: "100px",
                              height: "100px",
                              borderRadius: "8px",
                              border: "1px solid #BDBDBD",
                              overflow: "hidden",
                              padding: "0.2em",
                              boxSizing: "border-box",
                              gridColumn: "1 / 2",
                            }}
                          >
                            <img
                              style={{
                                width: "100%;",
                                height: "100%",
                                minHeight: "100%",
                                objectFit: "cover",
                                border: "none",
                              }}
                              src={
                                SHOP_CUSTOM_IMG_PATH +
                                menu.table_name.replace("_", "") +
                                "/menu/" +
                                menu.m_img
                              }
                            />
                          </div>

                          <div style={{ width: "100%" }}>
                            <div>{menu.m_name}</div>
                            {/* <div></div> */}
                          </div>

                          <div
                            className="item-count-box"
                            style={{ scale: "0.8", gridColumn: "1 / 2" }}
                          >
                            <div className="count">
                              <button
                                className="count-btn"
                                onClick={(e) => minusClickEvent(e, index)}
                              >
                                -
                              </button>
                              <div
                                id="countNumber"
                                className="count-num"
                                data-price={menu.m_price}
                              >
                                {menu.m_count}
                              </div>
                              <button
                                className="count-btn"
                                onClick={(e) => plusClickEvent(e, 99, index)}
                              >
                                +
                              </button>
                            </div>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              width: "100%",
                              textAlign: "right",
                            }}
                          >
                            <div className="order-menu-info-price">
                              {menu.m_price
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              원
                            </div>
                          </div>
                        </div>
                      </div>
                    </label>
                  </div>
                  <div style={{ padding: "1em", background: "#E0E0E0" }}>
                    <div style={orderItemPriceStyle}>
                      <div>상품금액</div>
                      <div className="menu-price">{menuPrice}원</div>
                    </div>
                    <div style={orderItemPriceStyle}>
                      <div>배송비</div>
                      <div className="pacel-price">
                        {pacelPrice ? `${pacelPrice}원` : "무료배송"}
                      </div>
                    </div>
                    <div style={{ ...orderItemPriceStyle, display: "none" }}>
                      <div>포인트사용</div>
                      <div className="point-price">{0}원</div>
                    </div>
                    <div style={{ ...orderItemPriceStyle, fontWeight: "700" }}>
                      <div>주문금액</div>
                      <div className="result-price">
                        {resultPrice
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        원
                      </div>
                    </div>
                  </div>
                </div>
              );
            });
          })}
        </div>
      )}
      {/* {basketList && <ShopComponent />} */}

      <div className="final-confirmation">
        <div className="payment-result">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              fontSize: "18px",
            }}
          >
            <div>주문결제금액</div>
            <div className="tAmt">
              {
                (totalAmt =
                  0 < cAmt && cAmt <= 50000
                    ? (cAmt + 4500)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    : cAmt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))
              }
              원
            </div>
          </div>

          <div
            className="payment-detail-info"
            style={{
              display: "grid",
              gridTemplateColumns: "auto 1fr",
              justifyContent: "center",
              paddingLeft: "2em",
            }}
          >
            <div>주문상품수</div>
            <div className="payment-menu-count" style={paymentDetailPriceStyle}>
              {basketCount}개
            </div>

            <div>상품금액</div>
            <div className="payment-menu-prcie" style={paymentDetailPriceStyle}>
              {cAmt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}원
            </div>

            <div>배송비</div>
            <div
              className="payment-pacel-prcie"
              style={paymentDetailPriceStyle}
            >
              {0 < cAmt && cAmt <= 50000 ? 4500 : 0}원
            </div>

            <div>포인트사용</div>
            <div style={paymentDetailPriceStyle}>0원</div>
          </div>

          {/* <span className="total-payment-result">
            <p>총 결제금액</p>
            <p className="tAmt">
              {cAmt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}원
            </p>
          </span> */}
        </div>
        <div className="kiikey-guide">
          <p>
            이쁨은 통신판매 중개자이며 통신판매의 당사자가 아닙니다. 따라서
            이쁨은 상품거래 정보 및 거래에 책임을 지지 않습니다.
          </p>
          {basketList && <GiftGuide />}
        </div>
        <button className="payment-button" onClick={paymentClickHandler}>
          <span className="tAmt">
            {
              (totalAmt =
                cAmt > 50000
                  ? cAmt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  : (cAmt + 4500)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ","))
            }
            원
          </span>{" "}
          결제하기
        </button>
      </div>
    </section>
  );
};

export default OrderConfirm;
