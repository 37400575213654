import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  checkPlatform,
  EIGHT_BIRTH_REG,
  NOT_SPECIAL_CODE_REG,
  PHONE_REG,
  USER_NAME_MAX_LENGTH,
  USER_NAME_MIN_LENGTH,
  USER_NAME_REG,
  USER_PASSWORD_MIN_LENGTH,
} from "App-config";

import { auth, signInWithPhoneNumber } from "service/firebase";
import { duplicatePhoneCheck, idCheck, signup } from "service/AccountService";

import KakaoAddrPopup from "components/common/KakaoAddrPopup";
import AuthComponent from "components/account/AuthComponent";
import AuthInputComponent from "components/account/AuthInputComponent";

import "components/account/style/CreateAccount.css";

const ValidComponent = ({ msg }) => {
  if (msg) {
    return (
      <tr>
        <td></td>
        <td className="input-note">{msg}</td>
      </tr>
    );
  }
  return "";
};

const CreateValid = {
  userNameMSG: "이름은 2~10자의 영문,한글만 입력해주세요.",
  userPhoneMSG: "휴대전화 번호는 010-2048-7649 형식으로 입력해주세요.",
  userIdMSG: "아이디는 영문자 시작, 숫자조합을 하여 4~25자를 입력해주세요.",
  userPwMSG: "비밀번호를 8자이상 입력해주세요.",
  userPwduplicateMSG: "비밀번호 확인과 일치하지 않습니다.",
  userBirthMSG: "생년월일 양식이 맞지않습니다. 예 : 20121010",
  userGenderMSG: "성별을 선택해주세요.",
  userAddrMSG: "주소를 입력해주세요.",
  userAddrDetailMSG: "상세주소를 입력해주세요.",
};

const Signup = ({ setLoginId }) => {
  const navigate = useNavigate();

  const userNameInput = useRef();
  const userPhoneInput = useRef();
  const userIdInput = useRef();
  const userPwInput = useRef();
  const userPwduplicateInput = useRef();
  const userBirthInput = useRef();
  const userGenderInput = useRef();
  const userAddrBtn = useRef();
  const userAddrDetailInput = useRef();

  const [userNameMSG, setUserNameMSG] = useState("");
  const [userPhoneMSG, setUserPhoneMSG] = useState("");
  const [userIdMSG, setUserIdMSG] = useState("");
  const [userPwMSG, setUserPwMSG] = useState("");
  const [userPwduplicateMSG, setUserPwduplicateMSG] = useState("");
  const [userBirthMSG, setUserBirthMSG] = useState("");
  const [userGenderMSG, setUserGenderMSG] = useState("");
  const [userAddrMSG, setUserAddrMSG] = useState("");
  const [userAddrDetailMSG, setUserAddrDetailMSG] = useState("");

  const [authView, setAuthView] = useState(false);
  const [authInputView, setAuthInputView] = useState(false);

  const [isIdCheck, setIsIdCheck] = useState(false);
  const [gender, setGender] = useState(null);
  const [addr, setAddr] = useState("");

  const phoneInputDisabled = (isDisable) => {
    userPhoneInput.current.disabled = isDisable;
  };

  const phoneAuthHandler = async (e) => {
    e.preventDefault();
    let _userPhone = userPhoneInput.current.value.trim();
    if (!_userPhone || !RegExp(PHONE_REG).test(_userPhone)) {
      setUserPhoneMSG(CreateValid.userPhoneMSG);
      userPhoneInput.current.focus();
      return;
    }

    _userPhone = _userPhone.replace("-", "");
    _userPhone = _userPhone.replace("-", "");

    let pType = "4";
    const rType = "1";
    if (checkPlatform() === "mobile") {
      pType = "3";
    }
    //통신로직
    const isCheck = await duplicatePhoneCheck({ _userPhone, pType, rType })
      .then((res) => {
        console.log(res);
        return true;
      })
      .catch((err) => {
        alert(err.msg);
        console.error(err);
        return false;
      });
    if (!isCheck) return;

    if (_userPhone) {
      _userPhone = _userPhone.replace("010", "+8210");
    }

    phoneInputDisabled(true);
    setAuthView(true);

    signInWithPhoneNumber(auth, _userPhone, window.recaptchaVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        console.log("signInWithPhoneNumber 1", confirmationResult);
        setAuthView(false);
      })
      .catch((error) => {
        console.log("signInWithPhoneNumber 2", error);
      })
      .finally(() => {
        console.log("signInWithPhoneNumber 3");
      });
  };

  const formDataValidCheck = () => {
    let _userName = userNameInput.current.value.trim();
    let _userPhone = userPhoneInput.current.value.trim();
    let _userId = userIdInput.current.value.trim();
    let _userPw = userPwInput.current.value.trim();
    let _userPwduplicate = userPwduplicateInput.current.value.trim();
    let _userBirth = userBirthInput.current.value.trim();
    let _userGender = gender;
    let _addr = addr.trim();
    let _userAddrDetail = userAddrDetailInput.current.value.trim();

    if (
      !_userName ||
      RegExp(USER_NAME_REG).test(_userName) ||
      _userName.length < USER_NAME_MIN_LENGTH ||
      _userName.length > USER_NAME_MAX_LENGTH
    ) {
      setUserNameMSG(CreateValid.userNameMSG);
      userNameInput.current.focus();
      return;
    }
    if (!_userPhone || !RegExp(PHONE_REG).test(_userPhone)) {
      setUserPhoneMSG(CreateValid.userPhoneMSG);
      userPhoneInput.current.focus();
      return;
    }
    if (!isIdCheck) {
      setUserIdMSG("아이디 중복확인이 필요합니다.");
      userIdInput.current.focus();
      return;
    }
    if (RegExp(NOT_SPECIAL_CODE_REG).test(_userId)) {
      setUserIdMSG(CreateValid.userIdMSG);
      userIdInput.current.focus();
      return;
    }

    if (!_userPw) {
      setUserPwMSG(CreateValid.userPw);
      userPwInput.current.focus();
      return;
    }
    if (
      !_userPwduplicate ||
      userPwInput.current.value !== userPwduplicateInput.current.value
    ) {
      setUserPwduplicateMSG(CreateValid.userPwduplicateMSG);
      userPwduplicateInput.current.focus();
      return;
    }
    if (!_userBirth || !RegExp(EIGHT_BIRTH_REG).test(_userBirth)) {
      setUserBirthMSG(CreateValid.userBirthMSG);
      userBirthInput.current.focus();
      return;
    }
    if (!gender || gender < 1) {
      setUserGenderMSG(CreateValid.userGenderMSG);
      userGenderInput.current.focus();
      return;
    }
    if (!_addr) {
      setUserAddrMSG(CreateValid.userAddrMSG);
      userAddrBtn.current.focus();
      return;
    }
    if (!_userAddrDetail) {
      setUserAddrDetailMSG(CreateValid.userAddrDetailMSG);
      userAddrDetailInput.current.focus();
      return;
    }

    return {
      userName: _userName,
      userPhone: _userPhone,
      userId: _userId,
      userPw: _userPw,
      userBirth: _userBirth,
      userGender: _userGender,
      userAddr: _addr,
      userAddrDetail: _userAddrDetail,
    };
  };

  const onSubmitEvent = (e) => {
    e.preventDefault();

    const userDTO = formDataValidCheck();
    if (!userDTO) return;

    signup(userDTO)
      .then((res) => {
        if (res.success) {
          setLoginId(userDTO.userId);
          navigate("/account/signin");
          return;
        }
        console.error("여기타면안됨", res);
      })
      .catch((err) => {
        console.error("signup err", err);
        if (err?.msg) {
          alert(err.msg);
        }
      });
  };

  const idCheckEvent = (e) => {
    e.preventDefault();
    if (!userIdInput.current.value) {
      setUserIdMSG(CreateValid.userIdMSG);
      userIdInput.current.focus();
      return;
    }
    idCheck(userIdInput.current.value)
      .then((res) => {
        if (res.success) {
          refChangeEvent();
          setIsIdCheck(true);
        }
      })
      .catch((err) => {
        setIsIdCheck(false);
        console.error(err);
      });
  };

  const addrHandleEvent = (fullAddr) => {
    setAddr(fullAddr);
    setUserAddrMSG("");
  };

  const idChangeEvent = () => {
    setIsIdCheck(false);
  };

  const pwCheckEvent = () => {
    if (userPwInput.current.value.length < USER_PASSWORD_MIN_LENGTH) {
      setUserPwduplicateMSG(CreateValid.userPwMSG);
    } else if (
      userPwInput.current.value === userPwduplicateInput.current.value
    ) {
      refChangeEvent();
    } else {
      setUserPwduplicateMSG(CreateValid.userPwduplicateMSG);
    }
  };

  const refChangeEvent = () => {
    setUserNameMSG("");
    setUserPhoneMSG("");
    setUserIdMSG("");
    setUserPwMSG("");
    setUserPwduplicateMSG("");
    setUserBirthMSG("");
    setUserGenderMSG("");
    setUserAddrMSG("");
    setUserAddrDetailMSG("");
  };

  const genderChangeEvent = (e) => {
    setGender(e.currentTarget.value);
    setUserGenderMSG("");
  };

  return (
    <section className="create-account-frame">
      <form className="create-account-form" onSubmit={onSubmitEvent}>
        <div className="form-title">회원가입</div>
        <table className="join-table">
          <tbody>
            <tr>
              <td>이름</td>
              <td className="small-td">
                <input
                  id="userName"
                  onChange={refChangeEvent}
                  ref={userNameInput}
                  type="text"
                  placeholder="예) 홍길동"
                  className="join-input-style"
                />
              </td>
            </tr>
            <ValidComponent msg={userNameMSG} />
            <tr>
              <td>휴대전화</td>
              <td className="small-td">
                <input
                  id="userPhone"
                  onChange={refChangeEvent}
                  ref={userPhoneInput}
                  type="tel"
                  placeholder="예) 010-1111-0000"
                  className="join-input-style"
                />
              </td>
              <td>
                {!userPhoneInput?.current?.disabled && (
                  <button
                    onClick={phoneAuthHandler}
                    className="join-button-large-size join-btn-style createbutton"
                  >
                    인증
                  </button>
                )}
                {/* {userPhoneInput?.current?.disabled && (
                  <button
                    disabled={true}
                    className="join-button-large-size join-btn-style createbutton"
                  >
                    인증완료
                  </button>
                )} */}
              </td>
            </tr>
            <AuthInputComponent
              isView={authInputView}
              setAuthInputView={setAuthInputView}
              phoneInputDisabled={phoneInputDisabled}
            />
            <AuthComponent
              userPhoneInput={userPhoneInput.current}
              isView={authView}
              setAuthView={setAuthView}
              setAuthInputView={setAuthInputView}
              phoneInputDisabled={phoneInputDisabled}
            />
            <ValidComponent msg={userPhoneMSG} />
            <tr>
              <td>ID</td>
              <td className="small-td">
                <input
                  id="userId"
                  onChange={idChangeEvent}
                  ref={userIdInput}
                  type="text"
                  placeholder="영문과 숫자만 입력이 가능합니다."
                  className="join-input-style"
                />
              </td>
              <td>
                <button
                  onClick={idCheckEvent}
                  className="join-button-large-size join-btn-style createbutton"
                >
                  중복확인
                </button>
              </td>
            </tr>

            <ValidComponent msg={userIdMSG} />

            <tr>
              <td>비밀번호</td>
              <td className="large-td">
                <input
                  id="userPw"
                  onChange={pwCheckEvent}
                  ref={userPwInput}
                  type="password"
                  placeholder="비밀번호를 입력해주세요."
                  className="join-input-style-pw"
                />
              </td>
            </tr>

            <ValidComponent msg={userPwMSG} />

            <tr>
              <td>비밀번호 확인</td>
              <td className="large-td">
                <input
                  id="userPwduplicate"
                  onChange={pwCheckEvent}
                  ref={userPwduplicateInput}
                  type="password"
                  placeholder="비밀번호를 다시 입력해주세요."
                  className="join-input-style-pw"
                />
              </td>
            </tr>

            <ValidComponent msg={userPwduplicateMSG} />

            <tr>
              <td>생년월일</td>
              <td className="large-td">
                <input
                  id="userBirth"
                  onChange={refChangeEvent}
                  ref={userBirthInput}
                  type="tel"
                  placeholder="생년월일 (8자리)"
                  className="join-input-style"
                />
              </td>
            </tr>

            <ValidComponent msg={userBirthMSG} />

            <tr>
              <td></td>
              <td className="gender-td" ref={userGenderInput}>
                <input
                  type="radio"
                  onChange={genderChangeEvent}
                  value="1"
                  id="gender-male"
                  name="gender"
                />
                <label htmlFor="gender-male"></label>
                <input
                  type="radio"
                  onChange={genderChangeEvent}
                  value="2"
                  id="gender-female"
                  name="gender"
                />
                <label htmlFor="gender-female"></label>
              </td>
            </tr>

            <ValidComponent msg={userGenderMSG} />

            <tr>
              <td>주소</td>
              <td className="small-td">
                <input
                  id="userAddr"
                  onChange={refChangeEvent}
                  type="text"
                  placeholder="기본주소"
                  className="join-input-style"
                  value={addr}
                  readOnly
                />
              </td>
              <td ref={userAddrBtn}>
                <KakaoAddrPopup
                  addrHandleEvent={addrHandleEvent}
                  className="join-button-large-size join-btn-style createbutton"
                  btnText="주소검색"
                />
              </td>
            </tr>

            <ValidComponent msg={userAddrMSG} />

            <tr>
              <td></td>
              <td className="large-td">
                <input
                  id="userAddrDetail"
                  onChange={refChangeEvent}
                  ref={userAddrDetailInput}
                  type="text"
                  placeholder="상세주소"
                  className="join-input-style"
                />
              </td>
            </tr>
            <ValidComponent msg={userAddrDetailMSG} />
            {/* <tr>
              <td>추천인</td>
              <td className="small-td">
                <input
                ref={suggestion}
                  type="text"
                  placeholder="추천인아이디"
                  className="join-input-style"
                />
              </td>
              <td>
                <button type="button" className="join-button-large-size join-btn-style createbutton">
                  확인
                </button>
              </td>
            </tr> */}
          </tbody>
        </table>
        <button type="submit" className="join-btn-comfirm">
          완료
        </button>
      </form>
    </section>
  );
};

export default Signup;
