import { isKiikeyApp, IS_ANDROID, IS_IOS } from "App-config";

let instance;
let globalState = {
  color: "",
  title: "",
  selectedCateC3Index: 0,
  selectedCateTabIndex: 0,
  basketShopIdx: 0,
  windowInnerHeight: window.innerHeight,
  dataloadTab: [],
  basketList: [],
  selectedMyWalletTabIndex: 0,
};

class StateUtility {
  constructor() {
    if (instance) {
      throw new Error("New instance cannot be created!!");
    }

    instance = this;
  }

  getPropertyByName(propertyName) {
    return globalState[propertyName];
  }
  setPropertyValue(propertyName, propertyValue) {
    globalState[propertyName] = propertyValue;
  }

  setMobileHeaderTitle(title, backBtnFlag = false, rightBtnFlag = false) {
    if (document.getElementById("mobileTitle"))
      document.getElementById("mobileTitle").innerHTML = title;

    const headerContainer = document.querySelector(".header-mobile-top");
    if (headerContainer) {
      if (backBtnFlag) {
        headerContainer.querySelector("img.database").style.display = "none";
        headerContainer.querySelector(
          "div.header-mobile-top-button"
        ).style.display = "flex";
      } else {
        headerContainer.querySelector("img.database").style.display = "block";
        headerContainer.querySelector(
          "div.header-mobile-top-button"
        ).style.display = "none";
      }

      if (rightBtnFlag) {
        headerContainer.querySelector(
          "img.notifications-active"
        ).style.display = "none";
        headerContainer.querySelector("div.overlap").style.display = "none";
      } else {
        headerContainer.querySelector(
          "img.notifications-active"
        ).style.display = "block";
        headerContainer.querySelector("div.overlap").style.display = "block";
      }
    }

    if (isKiikeyApp) {
      if (window.location.pathname == "/order") {
        if (IS_IOS) {
          window.webkit.messageHandlers.callBackHandler.postMessage({
            action: "hideNav",
            data: "false",
          });
        }
        if (IS_ANDROID) {
          window.KiikeyWebView.hideNavBar(false);
        }
      } else {
        if (IS_IOS) {
          window.webkit.messageHandlers.callBackHandler.postMessage({
            action: "hideNav",
            data: "true",
          });
        }
        if (IS_ANDROID) {
          window.KiikeyWebView.hideNavBar(true);
        }
      }
    }
  }
  setOdIdx(od_idx) {
    localStorage.setItem("setOdIdx", od_idx);
  }

  geOdIdx() {
    const odIdx = localStorage.getItem("setOdIdx");
    if (odIdx) {
      return odIdx;
    } else {
      return 0;
    }
  }
  setBasketShopIdx(s_idx) {
    localStorage.setItem("setBasketShopIdx", s_idx);
  }

  getBasketShopIdx() {
    const basketShopIdx = localStorage.getItem("setBasketShopIdx");
    if (basketShopIdx) {
      return basketShopIdx;
    } else {
      return 0;
    }
  }

  setBasketCount(count) {
    const headerContainer = document.querySelector(".header-mobile-top");
    if (!headerContainer) return;

    const basketTextEl = headerContainer.querySelector(
      ".overlap .text-wrapper-3"
    );
    if (!basketTextEl) return;

    basketTextEl.innerHTML = count;
  }
}
let stateUtilityInstance = Object.freeze(new StateUtility());

export default stateUtilityInstance;

// class TestClass{
//     private static instanceRef: Person;
// } = (function() {
//     let instance;

//     let title_;

//     function init(){ // 싱글톤 객체를 리턴할 비공개 함수
//       return {
//         publictMethod: function() {
//           return 'public method';
//         },
//         publicProp: 'public variable',

//         setTitle: function(title){
//             title_ = title
//             return;
//         },

//         getTitle: function(){
//             return title_;
//         },
//       };
//     }

//     return {
//       getInstance: function() {
//         if (instance) {
//           return instance; // 있으면 그냥 반환
//         }
//         instance = init();
//         return instance; // 없으면 객체 생성 후 반환 (이해를 위해 명시적으로 나눔)
//       }
//     };
//   })();
