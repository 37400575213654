import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getShopInfo } from "service/OrderService";

const AppShare = () => {
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    if (params.sIdx) {
      getShopInfo(params.sIdx)
        .then((res) => {
          if (res.data.length !== null) {
            navigate("/order/shop", {
              state: { shopInfo: res.data },
              replace : true,
            });
          } else {
            // 메뉴가 없는 매장처리
          }
        })
        .catch((err) => {
          navigate("/order");
        });
    } else {
      navigate("/order");
    }
  }, []);

  return <></>;
};

export default AppShare;
