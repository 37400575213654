import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { checkPlatform, NOT_SPECIAL_CODE_REG, PHONE_REG } from "App-config";

import { auth, signInWithPhoneNumber } from "service/firebase";
import { duplicatePhoneCheck, findPassword } from "service/AccountService";

import AuthFindIdInputComponent from "components/account/AuthFindIdInputComponent";
import AuthFindIdComponent from "components/account/AuthFindIdComponent";

import "components/account/style/PasswordFind.css";

const PasswordFind = ({ setPassFindResultId, setPassFindValidCheck }) => {
  const navigate = useNavigate();

  const userIdInput = useRef();
  const userPhoneInput = useRef();

  const [authView, setAuthView] = useState(false);
  const [authInputView, setAuthInputView] = useState(false);

  const isButtonView = !authView && !authInputView ? true : false;

  const phoneInputDisabled = (isDisable) => {
    userIdInput.current.disabled = isDisable;
    userPhoneInput.current.disabled = isDisable;
  };

  const phoneAuthHandler = async (e) => {
    e.preventDefault();

    let _userId = userIdInput.current.value.trim();
    if (!_userId || RegExp(NOT_SPECIAL_CODE_REG).test(_userId)) {
      alert("아이디는 영문자 시작, 숫자조합을 하여 4~25자를 입력해주세요.");
      userIdInput.current.focus();
      return;
    }

    let _userPhone = userPhoneInput.current.value.trim();
    if (!_userPhone || !RegExp(PHONE_REG).test(_userPhone)) {
      userPhoneInput.current.focus();
      return;
    }

    _userPhone = _userPhone.replace("-", "");
    _userPhone = _userPhone.replace("-", "");

    let pType = "4";
    const rType = "4";
    if (checkPlatform() === "mobile") {
      pType = "3";
    }
    const isCheck = await duplicatePhoneCheck({ _userPhone, pType, rType })
      .then((res) => {
        console.log(res);
        return true;
      })
      .catch((err) => {
        alert(err.msg);
        console.error(err);
        return false;
      });
    if (!isCheck) return;

    if (_userPhone) {
      _userPhone = _userPhone.replace("010", "+8210");
    }

    phoneInputDisabled(true);
    setAuthView(true);

    signInWithPhoneNumber(auth, _userPhone, window.recaptchaVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        console.log("signInWithPhoneNumber 1", confirmationResult);
        setAuthView(false);
      })
      .catch((error) => {
        console.log("signInWithPhoneNumber 2", error);
      })
      .finally(() => {
        console.log("signInWithPhoneNumber 3");
      });
  };

  useEffect(() => {
    userIdInput.current.focus();
  }, []);

  const submitAction = (e) => {
    e.preventDefault();
    let _userId = userIdInput.current.value.trim();
    let _userPhone = userPhoneInput.current.value.trim();

    _userPhone = _userPhone.replace("-", "");
    _userPhone = _userPhone.replace("-", "");

    const userDTO = {
      userId: _userId,
      userPhone: _userPhone,
    };

    findPassword(userDTO)
      .then((res) => {
        if (res.success) {
          setPassFindValidCheck(true);
          setPassFindResultId(res.data.mb_id);
          navigate("/account/pwReset");
        }
      })
      .catch((err) => {
        phoneInputDisabled(false);
        setAuthInputView(false);
        alert(err.msg);
        console.error(err);
      });
  };

  return (
    <section className="password-find">
      <form className="pw-find-form">
        <div className="form-title">비밀번호 찾기</div>
        <div className="pw-input-area">
          <span className="pw-note">
            비밀번호를 변경하기 위해서 ID 및 휴대전화번호가 필요합니다.
          </span>
          <div className="pw-input">
            <span>
              <label htmlFor="mbId">ID</label>
              <input ref={userIdInput} type="text" name="mbId" id="mbId" />
            </span>
            <span>
              <label htmlFor="userPhone">휴대전화</label>
              <input
                ref={userPhoneInput}
                type="tel"
                id="userPhone"
                placeholder="예) 010-1111-0000"
              />
            </span>
            <AuthFindIdInputComponent
              isView={authInputView}
              setAuthInputView={setAuthInputView}
              phoneInputDisabled={phoneInputDisabled}
              submitAction={submitAction}
            />
            <AuthFindIdComponent
              userPhoneInput={userPhoneInput.current}
              isView={authView}
              setAuthView={setAuthView}
              setAuthInputView={setAuthInputView}
              phoneInputDisabled={phoneInputDisabled}
            />
          </div>
        </div>
        {isButtonView && (
          <button className="join-btn-comfirm" onClick={phoneAuthHandler}>
            인증 요청
          </button>
        )}
      </form>
    </section>
  );
};

export default PasswordFind;
