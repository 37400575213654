import { useEffect, useState } from "react";

import { isKiikeyApp, IS_ANDROID, IS_IOS, serverErrorHandler, USER_DTO } from "App-config";
import { ArrayShuffle, makeYTImgUrl } from "Common";
import { requestGameAnswer } from "service/ADSService";
import { getGame } from "service/ADSService";
import answer_img from "src_assets/answer_img.png";
import answer_imgs from "src_assets/answer_imgs.png";
import sad_img from "src_assets/sad_img.png";
import { SHOP_CUSTOM_IMG_PATH } from "App-config";
import game_title from "src_assets/hiddencatch_game_title.png";

import "components/main/style/AppGameComponent.css";
import GamePopup from "./GamePopupComponent";

const GameStart = ({ isMobile, requestAnswerEvent, data}) => {

  return (
  <GameComponent 
    isMobile = {isMobile}
  />);
};

const GameComponent = ({isMobile}) => {
  const [user, setUser] = useState(USER_DTO);
  const [isRight, setIsRight] = useState(false);

  const [isNotQuiz, setIsNotQuiz] = useState(false);
  const [hcgData, setHCG] = useState({});
  const [isGameRunning, setGameIsRunning] = useState(0);
  const [isWebView, setIsWebView] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const hiddenCatchGame = (e, status) => {
    if (!isKiikeyApp) {
      return;
    }

    getGame(e?.currentTarget.value)
      .then((res) => {
        if (res.data?.length < 1) {
          if (isKiikeyApp) {
            alert("플레이 가능한 게임이 없습니다.");
            scriptHandler(e, "closeGame");
          } else {
            alert("플레이 가능한 게임이 없습니다.");
          }
        } else {
          setHCG(res.data[0]);
          if (status === "app") {
            setIsWebView(true);
          }
          setGameIsRunning(1);
        }
      })
      .catch((err) => {
        alert(err.msg);

        //setIsLogin(false);
        serverErrorHandler(err);
      });
  };

  const closeGamePopupEvent = (e, status) => {
    if ((e && e == "closeBtn") || (e && e.target.dataset?.title == "close")) {
      setGameIsRunning(0);
    }

    setIsLoading(true);
    if (status) {
      setGameIsRunning(status);
    }
    setIsLoading(false);
  };

  useEffect(() => {

    hiddenCatchGame(null, 'app');
  }, []);

  const scriptHandler = (event, message) => {
    if (message == "closeGame") {
      // event.preventDefault();
      try {
        if (IS_IOS) {
          window.webkit.messageHandlers.scriptHandler.postMessage(message);
        }
        if (IS_ANDROID) {
          // 안드로이드
          try {
            window.KiikeyWebView.gameEnded();
          } catch (error) {
            window.gameState.gameEnded();
          }
        }
      } catch (error) {
        alert(error);
      }
    }
  };

  return (
    <>
      <section
        className={"popup-frame"}
        data-title="webViewClose"
        onClick={closeGamePopupEvent}
      >
        <GamePopup
          isMobile={isMobile}
          setIsNotQuiz={setIsNotQuiz}
          hcgData={hcgData}
          isGameRunning={isGameRunning}
          closeGamePopupEvent={closeGamePopupEvent}
          setUser={setUser}
          setIsRight={setIsRight}
          isRight={isRight}
          isApp={isWebView}
          hiddenCatchGame={hiddenCatchGame}
          scriptHandler={scriptHandler}
        />
      </section>
    </>
  )
};

export default GameComponent;
