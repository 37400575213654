import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import {
  checkPlatform,
  EIGHT_BIRTH_REG,
  PHONE_REG,
  USER_PASSWORD_MIN_LENGTH,
} from "App-config";
import { auth, signInWithPhoneNumber } from "service/firebase";
import {
  deleteUser,
  duplicatePhoneCheck,
  modifyUserInfo,
} from "service/AccountService";

import AuthFindIdInputComponent from "components/account/AuthFindIdInputComponent";
import AuthFindIdComponent from "components/account/AuthFindIdComponent";

import "components/mykiikey/style/Myinfo.css";
import "components/mykiikey/style/Mykiikey.css";
import "components/mykiikey/style/Privacy.css";
import stateUtilityInstance from "../../TestClass";

import profileimg from "src_assets/profile.png";

const Privacy = ({ userInfo }) => {
  const navigate = useNavigate();

  const [userName, setUserName] = useState("");
  const [userId, setUserId] = useState(null);
  const [userPhone, setUserPhone] = useState(null);
  const [birth, setBirth] = useState(null);
  const [isBirthChange, setIsBirthChange] = useState(null);
  const [mbGender, setMbGender] = useState(0);
  const [isGenderChange, setIsGenderChange] = useState(null);

  const [changePhoneCheck, setChangePhoneCheck] = useState(false);
  const [changePhone, setChangePhone] = useState(false);

  const userPwInput = useRef();
  const userPwduplicateInput = useRef();
  const userBirthInput = useRef();
  const userPhoneInput = useRef();

  const [authView, setAuthView] = useState(false);
  const [authInputView, setAuthInputView] = useState(false);

  const isButtonView = !authView && !authInputView ? true : false;

  const phoneInputDisabled = (isDisable) => {
    userPhoneInput.current.disabled = isDisable;
  };

  useEffect(() => {
    setUserName(userInfo.mb_name);
    setUserId(userInfo.mb_id);
    setUserPhone(userInfo.mb_phone);
    setBirth(userInfo.mb_birth);
    setIsBirthChange(userInfo.mb_birth);
    setMbGender(userInfo.mb_sex);
    setIsGenderChange(userInfo.mb_sex);
  }, []);

  const saveClickEvent = () => {
    let userDTO = {};

    if (userName != userInfo.mb_name) {
      if (!userName.trim()) {
        alert("변경하실 이름을 입력해주세요.");
        return;
      }
      userDTO.userName = userName.trim();
    }

    if (changePhoneCheck) {
      let _userPhone = userPhoneInput.current.value.trim();
      _userPhone = _userPhone.replace("-", "");
      _userPhone = _userPhone.replace("-", "");
      userDTO.userPhone = _userPhone;
    }

    const _userPw = userPwInput.current.value.trim();
    const _userPwDuplicate = userPwduplicateInput.current.value.trim();

    if (_userPw || _userPwDuplicate) {
      if (userPwInput.current.value.length < USER_PASSWORD_MIN_LENGTH) {
        alert("변경할 비밀번호는 8자리 이상이어야합니다.");
        return;
      } else if (
        userPwInput.current.value === userPwduplicateInput.current.value
      ) {
        userDTO.userPw = userPwInput.current.value;
      } else {
        alert("비밀번호를 다시 확인해주세요.");
        return;
      }
    }

    if (!userInfo.mb_birth) {
      const _userBirth = userBirthInput.current.value.trim();
      if (!_userBirth || !RegExp(EIGHT_BIRTH_REG).test(_userBirth)) {
        userBirthInput.current.focus();
        alert("생년월일을 입력해주세요.");
        return;
      }
      userDTO.userBirth = _userBirth;
    }

    if (isGenderChange < 1) {
      const gender = document.querySelector(".gender input:checked");
      if (!gender) {
        alert("성별을 선택해주세요.");
        return;
      }
      userDTO.userGender = gender.value;
    }

    if (Object.keys(userDTO).length > 0) {
      modifyUserInfo(userDTO)
        .then((res) => {
          if (res.success) {
            navigate("/");
          } else {
            console.log("실패");
          }
        })
        .catch(console.error);
    } else {
      alert("변경할 정보가 없습니다.");
    }
  };

  const deleteClickEvent = () => {
    if (window.confirm("정말 탈퇴하시겠습니까?")) {
      deleteUser()
        .then((res) => {
          if (res.success) {
            navigate("/", { state: { isDelete: true } });
          } else {
            console.log("errr", res);
          }
        })
        .catch((err) => {
          console.error("err::", err);
          alert(err.msg);
        });
    }
  };

  const submitAction = (e) => {
    e.preventDefault();
    setChangePhone(false);
    setChangePhoneCheck(true);
  };

  const phoneAuthHandler = async (e) => {
    e.preventDefault();
    let _userPhone = userPhoneInput.current.value.trim();
    if (!_userPhone || !RegExp(PHONE_REG).test(_userPhone)) {
      alert("유효한 휴대전화번호를 입력해주세요.");
      userPhoneInput.current.focus();
      return;
    }

    _userPhone = _userPhone.replace("-", "");
    _userPhone = _userPhone.replace("-", "");

    let pType = "4";
    const rType = "2";
    if (checkPlatform() === "mobile") {
      pType = "3";
    }
    const isCheck = await duplicatePhoneCheck({ _userPhone, pType, rType })
      .then((res) => {
        console.log(res);
        return true;
      })
      .catch((err) => {
        alert(err.msg);
        console.error(err);
        return false;
      });
    if (!isCheck) return;

    _userPhone = _userPhone.replace("010", "+8210");

    phoneInputDisabled(true);
    setAuthView(true);

    signInWithPhoneNumber(auth, _userPhone, window.recaptchaVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        console.log("signInWithPhoneNumber 1", confirmationResult);
        setAuthView(false);
      })
      .catch((error) => {
        console.log("signInWithPhoneNumber 2", error);
      })
      .finally(() => {
        console.log("signInWithPhoneNumber 3");
      });
  };

  useEffect (() => {
    stateUtilityInstance.setMobileHeaderTitle('개인 정보');
  })

  return (
    <div className="daps-frame">
      <div className="profile-wrap">
        <div className="my-photo">
          <img src={profileimg} alt="프로필사진 이미지"></img>
        </div>
        <div className="my-name">
          <p>
            <input
              type="text"
              placeholder="솜솜"
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
            />
            님
          </p>
          {/* <p>프로필 사진을 변경 할 수 있습니다.</p> */}
        </div>
      </div>
      <div className="privacy-id">
        <p className="subtitle">아이디</p>
        <input type="text" id="idbox" value={userId} disabled />
      </div>
      <div className="phone">
        <p className="subtitle">휴대전화</p>
        <div className="phone-number-input">
          <input
            ref={userPhoneInput}
            type="text"
            id="pn1"
            value={userPhone}
            onChange={(e) => {
              setUserPhone(e.target.value);
            }}
            disabled={!changePhone}
          />
          {!changePhone && (
            <button
              className="phone-number-change"
              onClick={(e) => {
                e.preventDefault();
                setUserPhone("");
                setChangePhone(true);
              }}
            >
              변경
            </button>
          )}
          {isButtonView && changePhone && (
            <button className="phone-number-change" onClick={phoneAuthHandler}>
              인증
            </button>
          )}
        </div>
        <AuthFindIdInputComponent
          isPrivacy={true}
          isView={authInputView}
          setAuthInputView={setAuthInputView}
          phoneInputDisabled={phoneInputDisabled}
          submitAction={submitAction}
        />
        <AuthFindIdComponent
          userPhoneInput={userPhoneInput.current}
          isView={authView}
          setAuthView={setAuthView}
          setAuthInputView={setAuthInputView}
          phoneInputDisabled={phoneInputDisabled}
        />
      </div>
      <div className="password">
        <p className="subtitle">비밀번호</p>
        <input
          ref={userPwInput}
          type="password"
          placeholder="8~20자 이내"
          className="word"
        />
      </div>
      <div className="reconfirm">
        <p className="subtitle">비밀번호 확인</p>
        <input
          ref={userPwduplicateInput}
          type="password"
          placeholder="비밀번호를 다시 입력해주세요."
          className="word"
        />
      </div>
      <div className="birthday">
        <p className="subtitle">생년월일</p>
        {isBirthChange ? (
          <label className="word-no">{birth}</label>
        ) : (
          <input
            ref={userBirthInput}
            type="text"
            placeholder="19801215"
            className="word"
            value={birth}
            onChange={(e) => {
              setBirth(e.target.value);
            }}
          />
        )}
      </div>
      <div className="gender">
        <input
          type="radio"
          name="gender-radio"
          id="male-button"
          checked={mbGender == 1}
          onClick={() => {
            if (isGenderChange < 1) {
              setMbGender(1);
            }
          }}
          value="1"
        />
        <label htmlFor="male-button" />
        <input
          type="radio"
          name="gender-radio"
          id="female-button"
          checked={mbGender == 2}
          onClick={() => {
            if (isGenderChange < 1) {
              setMbGender(2);
            }
          }}
          value="2"
        />
        <label htmlFor="female-button" />
      </div>
      <div className="privacy-save">
        <button className="privacy-save-button" onClick={saveClickEvent}>
          저장하기
        </button>
      </div>
      <div className="secession">
        <p>* 회원 탈퇴를 원하시는 분은 회원 탈퇴 버튼을 눌러주세요!</p>
        <button className="secession-btn" onClick={deleteClickEvent}>
          회원탈퇴
        </button>
      </div>
    </div>
  );
};

export default Privacy;
