import { useState } from "react";

import Calendar from "components/common/Calendar";

import "components/order/style/categoryPart/CateTypeBeauty.css";

const CateTypeBeautyDate = ({
  selectDate,
  setSelectDate,
  shopInfo,
  calendarRef,
  setIsSchedule,
  scheduleClickEvent,
}) => {
  const TimeList = () => {
    const targetDate = new Date(
      calendarRef.current.dataset.year,
      calendarRef.current.dataset.month,
      selectDate
    );

    const targetInfo = shopInfo.rest_rows[targetDate.getDay()];
    const restStime = shopInfo.s_rest_start_time.split(":")[0] * 1;
    const restEtime = shopInfo.s_rest_end_time.split(":")[0] * 1;
    const sTime = targetInfo.d_open_time.split(":")[0] * 1;
    const eTime = targetInfo.d_close_time.split(":")[0];

    let scheduleTagList = [];
    let currentTime = sTime;
    while (currentTime <= eTime) {
      if (restStime <= currentTime && restEtime >= currentTime) {
      } else {
        scheduleTagList.push(currentTime + ":00");
      }
      currentTime++;
    }

    return (
      <ul className="reservation-time">
        {scheduleTagList.map((time, index) => {
          return (
            <>
              <input
                type="radio"
                id={"time-check-button" + index}
                name="time-check"
                value={time}
              />
              <label htmlFor={"time-check-button" + index}>{time}</label>
            </>
          );
        })}
      </ul>
    );
  };
  return (
    <section className="reservation-date-frame">
      <div className="reservation-date-box">
        <button
          className="reservation-close"
          onClick={() => {
            setIsSchedule(false);
          }}
        >
          X
        </button>
        <div className="reservation-date">
          <Calendar calendarRef={calendarRef} setSelectDate={setSelectDate} />
        </div>
        <div className="time-select">
          {/* 추가 될때마다 id에 번호 추기  */}
          {selectDate && calendarRef.current && <TimeList />}
          <button className="reservation-button" onClick={scheduleClickEvent}>
            예약하기
          </button>
        </div>
      </div>
    </section>
  );
};

export default CateTypeBeautyDate;
