import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { getUserNoticeList } from "service/AccountService";

import Pagination from "components/common/Pagination";

import "components/mykiikey/style/Mykiikey.css";
import "components/mykiikey/style/Notice.css";
import stateUtilityInstance from "../../TestClass";
import { isKiikeyApp , IS_IOS, IS_ANDROID, serverErrorHandler } from "App-config";

const Notice = ({ noticeList, setNoticeList, isMobile }) => {
  const navigate = useNavigate();

  const VIEW_LIMIT = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const offset = (currentPage - 1) * VIEW_LIMIT;
  const noticeListLimit = isMobile
    ? noticeList.slice(0, offset + VIEW_LIMIT)
    : noticeList.slice(offset, offset + VIEW_LIMIT);

  const liTagList = noticeListLimit.map((item, index) => {
    return (
      <li
        key={index}
        className="notice-article"
        onClick={(e) => {
          e.currentTarget
            .querySelector(".notice-body")
            .classList.toggle("dis-none");
        }}
      >
        <div className="notice-head">
          <div className="notice-title">
            <span>{item.wr_subject}</span>
            <p>{item.wr_datetime.split(" ")[0]}</p>
          </div>
          <button className="open-button" />
        </div>
        <div className="notice-body dis-none">
          <span readOnly className="notice-text">
            {item.wr_content}
          </span>
        </div>
      </li>
    );
  });

  useEffect(() => {
    stateUtilityInstance.setMobileHeaderTitle('공지사항');
    getUserNoticeList()
      .then((res) => {
        if (res.success) {
          setNoticeList(res.data);
        }
      })
      .catch((err) => {
        console.error("usernotice err", err);
        sessionStorage.clear();
        
        serverErrorHandler(err);
      });
  }, []);

  return (
    <div className="daps-frame">
      <div className="notice-area">
        <ul className="notice-list">{liTagList}</ul>
      </div>
      <Pagination
        isMobile={isMobile}
        limit={VIEW_LIMIT}
        offset={offset}
        type={"notice"}
        totalCount={noticeList.length}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </div>
  );
};

export default Notice;
