import "components/order/style/ShopSearchComponent.css";
import { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import datetime from "src_assets/date-time_b.png";
import stateUtilityInstance from "../../TestClass";
import ShopList from "components/order/ShopSearchListComponent";

import { ReactComponent as LocationIcon } from "src_assets/icon/share_location.svg";
import checkOn from "src_assets/check_on.svg";
import checkOff from "src_assets/check_off.svg";
const ShopSearchComponent = ({ isMobile, searchKeyList }) => {
  const { state } = useLocation();
  console.log("state");
  console.dir(state);
  const navigate = useNavigate();

  searchKeyList = state?.searchKeyList;
  //setHashTagList(searchKeyList);
  const [nowDate, setNowDate] = useState();
  const isRef = useRef(null);

  const [isSearchFocus, setIsSearchFocus] = useState(false);

  const [hashTagList, setHashTagList] = useState([]);

  const [isDeleteMode, setIsDeleteMode] = useState(false);

  const [priceFilter, setPriceFilter] = useState({ min: 0, max: Infinity });

  const [sortType, setSortType] = useState(0);

  const [priceSortMode, setPriceSortMode] = useState(0);
  const [starSortMode, setStarSortMode] = useState(0);
  const [reviewSortMode, setReviewSortMode] = useState(0);
  const [paySortMode, setPaySortMode] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [searchkey, setSearchKey] = useState({
    title: "검색 결과",
    searchKey: "",
    c3_list: state.c3_list,
  });
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };
  const hashTagAddToLocalStorage = (searchKey) => {
    const searchHashTagList = localStorage.getItem("searchHashTagList");
    let newSearchHashTagList = [];
    if (searchHashTagList) {
      newSearchHashTagList = searchHashTagList.split(",");
    } else {
      localStorage.setItem("searchHashTagList", []);
    }

    if (searchKey) {
      newSearchHashTagList.unshift(searchKey);
      newSearchHashTagList = new Set(newSearchHashTagList);
      newSearchHashTagList = [...newSearchHashTagList];
      // if (newSearchHashTagList.length > 4) {
      //   // 최대 4개
      //   newSearchHashTagList.pop();
      // }
    }

    localStorage.setItem("searchHashTagList", newSearchHashTagList);
    setHashTagList(newSearchHashTagList);
  };

  const hashTagDeleteToLocalStorage = (index) => {
    const searchHashTagList = localStorage.getItem("searchHashTagList");
    let newSearchHashTagList = searchHashTagList.split(",");
    newSearchHashTagList.splice(index, 1);
    //newSearchHashTagList = [...newSearchHashTagList];

    localStorage.setItem("searchHashTagList", newSearchHashTagList);
    setHashTagList(newSearchHashTagList);

    if (newSearchHashTagList.length == 0) setIsDeleteMode(false);
  };

  const hashTagDeleteAllToLocalStorage = () => {
    localStorage.removeItem("searchHashTagList");
    setHashTagList([]);
  };

  const gotoSearchKey = (searchKey, index) => {
    /* if (isDeleteMode) {
      hashTagDeleteToLocalStorage(index);
      return;
    } */

    /* if (searchKey == searchKeyword) {
      hashTagDeleteToLocalStorage(index);
      return;
    } */
    if (isRef.current) isRef.current.value = searchKey;
    hashTagAddToLocalStorage(searchKey);
    setSearchKeyword(searchKey);
    setSearchKey({
      title: "검색 결과",
      searchKey: searchKey,
      c3_list: state.c3_list,
    });

    return;

    navigate("/order/search", {
      state: {
        title: "검색 결과",
        searchKey: searchKey,
        c3_list: state.c3_list,
      },
    });
  };

  const searchOnChangeEvent = (e) => {
    if (e.keyCode == "13") {
      gotoSearchKey(e.target.value);
    } else if (e.type == "click") {
      gotoSearchKey(document.getElementById("order-search").value);
    }
  };

  useEffect(() => {
    stateUtilityInstance.setMobileHeaderTitle("검색결과", true);

    //console.log
    hashTagAddToLocalStorage();
  }, [searchKeyword]);

  useEffect(() => {
    const now = new Date();
    setNowDate(
      `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(
        2,
        "0"
      )}-${String(now.getDate()).padStart(2, "0")}`
    );
  }, [searchKeyList]);

  useEffect(() => {
    if (isRef) isRef.current.focus();
  }, [isRef]);

  useEffect(() => {
    if (!state || !state.searchKey) return;

    gotoSearchKey(state.searchKey);
  }, [state]);

  const searchKeyComponents = searchKeyList.map((item, index) => {
    return (
      <li
        key={index}
        onClick={() => {
          gotoSearchKey(item.pk_keyword);
        }}
      >
        <span className="list-number">{index + 1}.</span>
        <span className="pop-name">{item.pk_keyword}</span>
      </li>
    );
  });

  const hashTagListComponents = hashTagList.slice(0, 4).map((item, index) => {
    return (
      <li
        key={index}
        onClick={() => {
          gotoSearchKey(item);
        }}
      >
        #{item}
      </li>
    );
  });

  const sortItemStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "5px",
    border: "1px solid #E0E0E0",
    background: "#FFF",
    color: "#828282",
    textAlign: "center",
    fontSize: "11px",
    // height: "18px",
    lineHeight: "1.5",

    "&[active]": {
      background: "#BDBDBD",
      color: "#FFF",
    },
  };

  return (
    <section
      className="search-order-menu-list-frame"
      style={{
        height: "100%",
        overflow: "auto",
      }}
    >
      <div
        className="search-order-search-wrap"
        style={{
          position: "sticky",
          top: "0",
        }}
      >
        <div className="search-order-search-box">
          <input
            style={{ paddingLeft: "0px" }}
            id="order-search1"
            placeholder="브랜드나 상품명으로 검색하세요."
            onKeyUp={searchOnChangeEvent}
            /* onFocus={() => {
              setIsSearchFocus(true);
            }}
            onBlur={() => {
              setTimeout(() => {
                setIsSearchFocus(false);
              }, 200);
            }} */
            ref={isRef}
            type="text"
          />
        </div>
        <button
          className="search-order-search-button"
          onClick={() =>
            gotoSearchKey(document.getElementById("order-search1").value)
          }
          /* onSubmit={searchOnChangeEvent} */
        />
        {hashTagList.length > 0 && (
          <div className="search-popular-menu-box">
            <ul>
              {/* {hashTagList.map((item, index) => {
                  return (
                    <li
                      key={index}
                      onClick={() => {
                        gotoSearchKey(item);
                      }}
                    >
                      #{item}
                    </li>
                  );
              })} */}

              {hashTagListComponents}
            </ul>
          </div>
        )}
      </div>
      <div className="search-popular-result" style={{ display: "none" }}>
        <div className="search-popular-name">
          <span className="search-popular-name-text">인기검색어</span>
        </div>
        <ul className="search-popular-list">
          {/*인기  검색어 리스트 자리 */}
          {searchKeyComponents}
        </ul>
        <div className="search-popular-date-box">
          <span className="search-popular-date-img">
            <img src={datetime} alt="시간이미지" />
          </span>
          <span className="search-popular-date"> {nowDate}</span>
        </div>
      </div>

      <div className="search-last-list">
        <SeachHistoryComponent
          searchKeyword={searchKeyword}
          searchkey={searchkey}
          gotoSearchKey={gotoSearchKey}
        />

        <div
          style={{
            minHeight: "2px",
            width: "100%",
            background: "#BDBDBD",
            margin: "1em 0",
          }}
        ></div>

        {/* {hashTagList.length > 0 && (
          <ul className="search-last-list-list">
            {hashTagList.map((item, index) => {
              return (
                <li>
                  <div
                    key={index}
                    onClick={() => {
                      gotoSearchKey(item);
                    }}
                  >
                    {item}
                  </div>

                  <button
                    onClick={() => {
                      hashTagDeleteToLocalStorage(index);
                    }}
                  ></button>
                </li>
              );
            })}
          </ul>
        )} */}

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            color: "#000",
            fontFamily: "Inter",
            fontStyle: "normal",
            fontSize: "15px",
            fontWeight: "500",
            lineHeight: "1.5",
            gap: "0.5em",
          }}
        >
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "4em 1fr",
            }}
          >
            <div>가격대</div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                whiteSpace: "nowrap",
                gap: "1em",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "0.5em",
                }}
              >
                <input
                  name="search-price-min"
                  placeholder="최소가격"
                  style={{ ...sortItemStyle, width: "100%", padding: "0.1em" }}
                />
                ~
                <input
                  name="search-price-max"
                  placeholder="최대가격"
                  style={{ ...sortItemStyle, width: "100%", padding: "0.1em" }}
                />
              </div>
              <div
                style={{
                  ...sortItemStyle,
                  width: "64px",
                  background: "#BDBDBD",
                  color: "#FFF",
                  paddingTop: "0.2em",
                }}
                onClick={(e) => {
                  const target = e.currentTarget;
                  const priceFilterContainer = target.previousElementSibling;
                  const priceFilterInputs =
                    priceFilterContainer.querySelectorAll("input");
                  const minPrice = priceFilterInputs[0].value;
                  const maxPrice = priceFilterInputs[1].value;

                  setPriceFilter({
                    min: minPrice || 0,
                    max: maxPrice || Infinity,
                  });
                }}
              >
                확인
              </div>
            </div>
          </div>

          <div
            style={{
              display: "grid",
              gridTemplateColumns: "4em 1fr",
            }}
          >
            <div>정렬</div>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
                gap: "0.5em",
              }}
            >
              <div
                className="search-sort-item"
                active={sortType == 1 ? "" : false}
                onClick={(e) => {
                  const target = e.target;
                  if (target.hasAttribute("active")) {
                    setSortType(0);
                  } else {
                    setSortType(1);
                  }
                }}
              >
                높은가격
              </div>
              <div
                className="search-sort-item"
                active={sortType == 2 ? "" : false}
                onClick={(e) => {
                  const target = e.target;
                  if (target.hasAttribute("active")) {
                    setSortType(0);
                  } else {
                    setSortType(2);
                  }
                }}
              >
                낮은가격
              </div>
              <div
                className="search-sort-item"
                active={sortType == 3 ? "" : false}
                onClick={(e) => {
                  const target = e.target;
                  if (target.hasAttribute("active")) {
                    setSortType(0);
                  } else {
                    setSortType(3);
                  }
                }}
              >
                리뷰순
              </div>
              <div
                className="search-sort-item"
                active={sortType == 4 ? "" : false}
                onClick={(e) => {
                  const target = e.target;
                  if (target.hasAttribute("active")) {
                    setSortType(0);
                  } else {
                    setSortType(4);
                  }
                }}
              >
                높은별점
              </div>
              <div
                className="search-sort-item"
                active={sortType == 5 ? "" : false}
                onClick={(e) => {
                  const target = e.target;
                  if (target.hasAttribute("active")) {
                    setSortType(0);
                  } else {
                    setSortType(5);
                  }
                }}
              >
                낮은별점
              </div>
              <div
                className="search-sort-item"
                active={sortType == 6 ? "" : false}
                onClick={(e) => {
                  const target = e.target;
                  if (target.hasAttribute("active")) {
                    setSortType(0);
                  } else {
                    setSortType(6);
                  }
                }}
              >
                판매순
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              margin: "1em 0",
              gap: "1em",
              whiteSpace: "nowrap",
              boxSizing: "border-box",
            }}
          >
            <div>내주소중심</div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <label className="search-dist-filter-label">
                <input
                  type="checkbox"
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                />
                <div>
                  근접순
                  <img src={isChecked ? checkOn : checkOff} />
                </div>
              </label>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "0.3em",
                }}
              >
                <LocationIcon />
                현재위치로 설정
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            minHeight: "2px",
            width: "100%",
            background: "#BDBDBD",
          }}
        ></div>

        <div
          style={{
            margin: "0.2em 0",
            color: "#000",
            fontFamily: "Inter",
            fontStyle: "normal",
            fontSize: "18px",
            fontWeight: "500",
            lineHeight: "1.5",
          }}
        >
          검색결과
        </div>

        <div
          style={{
            minHeight: "2px",
            width: "100%",
            background: "#BDBDBD",
          }}
        ></div>
        {searchKeyword && (
          <ShopList
            isMobile={isMobile}
            searchKeyList={searchKeyList}
            searchKey={searchkey}
            priceFilter={priceFilter}
            sortType={sortType}
            priceSortMode={priceSortMode}
            starSortMode={starSortMode}
            reviewSortMode={reviewSortMode}
            paySortMode={paySortMode}
          />
        )}
      </div>
    </section>
  );
};

const SeachHistoryComponent = ({ searchKeyword, searchkey, gotoSearchKey }) => {
  const [hashTagList, setHashTagList] = useState([]);

  const hashTagAddToLocalStorage = (searchKey) => {
    const searchHashTagList = localStorage.getItem("searchHashTagList");
    let newSearchHashTagList = [];
    if (searchHashTagList) {
      newSearchHashTagList = searchHashTagList.split(",");
    } else {
      localStorage.setItem("searchHashTagList", []);
    }

    if (searchKey) {
      newSearchHashTagList.unshift(searchKey);
      newSearchHashTagList = new Set(newSearchHashTagList);
      newSearchHashTagList = [...newSearchHashTagList];
      // if (newSearchHashTagList.length > 4) {
      //   // 최대 4개
      //   newSearchHashTagList.pop();
      // }
    }

    localStorage.setItem("searchHashTagList", newSearchHashTagList);
    setHashTagList(newSearchHashTagList);
  };

  const hashTagDeleteToLocalStorage = (index) => {
    const searchHashTagList = localStorage.getItem("searchHashTagList");
    let newSearchHashTagList = searchHashTagList.split(",");
    newSearchHashTagList.splice(index, 1);
    //newSearchHashTagList = [...newSearchHashTagList];

    localStorage.setItem("searchHashTagList", newSearchHashTagList);
    setHashTagList(newSearchHashTagList);
  };
  const hashTagDeleteAllToLocalStorage = () => {
    localStorage.removeItem("searchHashTagList");
    setHashTagList([]);
  };

  useEffect(() => {
    hashTagAddToLocalStorage();
  }, [searchKeyword, searchkey]);

  return (
    <>
      <div className="search-last-list-name">
        <span className="search-popular-name-text">최근검색어</span>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "0.5em",
          }}
        >
          <button
            onClick={() => {
              //setIsDeleteMode(!isDeleteMode);
              const searchHashTagList =
                localStorage.getItem("searchHashTagList");
              let newSearchHashTagList = searchHashTagList.split(",");
              const index = newSearchHashTagList.findIndex(
                (item) => item == searchKeyword
              );
              if (index == -1) return;
              newSearchHashTagList.splice(index, 1);

              //setSearchKeyword("");

              //if (tag) tag.remove();
              //newSearchHashTagList = [...newSearchHashTagList];

              localStorage.setItem("searchHashTagList", newSearchHashTagList);
              setHashTagList(newSearchHashTagList);
            }}
          >
            선택삭제
          </button>

          <button
            onClick={() => {
              hashTagDeleteAllToLocalStorage();
            }}
          >
            전체삭제
          </button>
        </div>
      </div>
      <div className="search-history-list-keyword">
        {hashTagList && hashTagList.length ? (
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fill, minmax(85px ,1fr))",
              gap: "0.5em",
            }}
          >
            {hashTagList.map((item, index) => {
              return (
                <div
                  id={`search-history-list-keyword-item-${index}`}
                  key={index}
                  onClick={() => {
                    gotoSearchKey(item, index);
                  }}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "0.5em",
                    height: "30px",
                    paddingTop: "2px",
                    color: searchKeyword == item ? "#FFF" : "#828282",
                    fontSize: "15px",
                    borderRadius: "8px",
                    border: "1px solid #e0e0e0",
                    background: searchKeyword == item ? "#bdbdbd" : "#FFF",
                    boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)",
                  }}
                >
                  {item}
                </div>
              );
            })}
          </div>
        ) : (
          <div>검색내역이 없습니다</div>
        )}
      </div>
    </>
  );
};

export default ShopSearchComponent;
