import { useNavigate } from "react-router-dom";

import "components/account/style/IdResult.css";

const JustAuthResult = ({
  setIdFindValidCheck
}) => {
  const navigate = useNavigate();

  const onSubmitClickEvent = (e) => {
    e.preventDefault();

    setIdFindValidCheck(false);

    if (e.target.value === "passFind") {
      navigate("/order/confirm");
    } else {
      navigate("/order");
    }
  };
  return (
    <section className="id-result">
      <form className="id-result-form">
        <div className="form-title"></div>
        <div className="id-result-area">
          <span className="form-note">
            휴대전화 인증이 완료되었습니다.
          </span>
        </div>
        <button
          type="submit"
          className="ps-button"
          onClick={onSubmitClickEvent}
          value={"passFind"}
        >
          장바구니 가기
        </button>
        <button
          type="submit"
          className="login-button"
          onClick={onSubmitClickEvent}
          value={"main"}
        >
          홈
        </button>
      </form>
    </section>
  );
};

export default JustAuthResult;
