// 이상윤 220720 상세정보 따로 컴포넌트로 뺌
import "components/order/style/Beautydetail.css";

const BeautyInfo = ({ menuInfo }) => {
  return (
    <section className="beauty-info-section">
      <div className="beauty-info">
        <span className="beauty-info-text">
          {menuInfo.m_explained?.split("\n").map((line) => {
            return (
              <>
                {line}
                <br />
              </>
            );
          })}
        </span>
      </div>
    </section>
  );
};

export default BeautyInfo;
