import "components/mykiikey/style/Mykiikey.css";
import "components/mykiikey/style/ReviewType.css";
import { useRef, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getOrderCart, getOrderDetail } from "service/OrderService";

const ReviewWriting = ({ reviewList, setReviewList }) => {
  const navigate = useNavigate();

  const { state } = useLocation();
  const [orderCartHistoryList, setOrderCartHistoryList] = useState({});
  const [cartList, setCartList] = useState(false);
  const [liTagList, setLiTagList] = useState(false);
  const [productChange, setProductChange] = useState(null);
  const [productName, setProductName] = useState(null);
  const [midx, setmidx] = useState(null);
  const [r_cnt, setRcnt] = useState(null);

  useEffect(() => {
    getOrderCart({ od_idx: state.od_idx, c3_idx: state.c3_idx })
      .then((res) => {
        if (res.success) {
          setOrderCartHistoryList(res.data);
          setCartList(true);
        }
      })
      .catch((err) => {});
  }, []);

  const reviewWritingClickEvent = ({
    od_idx,
    s_name,
    s_idx,
    type,
    c2_idx,
    c3_idx,
  }) => {
    if (productChange == null) {
      return false;
    }
    navigate("/mykiki/orderList/review", {
      state: { od_idx, s_name, s_idx, type, c3_idx, productName, midx, r_cnt },
    });
  };

  const productChangeEvent = (e) => {
    setProductChange(e.target.dataset.idx);
    setProductName(e.target.dataset.mname);
    setmidx(e.target.dataset.midx);
    setRcnt(rcnt);
  };

  let rcnt = 0;

  useEffect(() => {
    if (cartList) {
      const liTagList = orderCartHistoryList.map((productList, index) => {
        if(productList.r_idx == null) {
          rcnt++;
        }
        return (
          <li className="review-type-radio">
            <input
              disabled={productList.r_idx == null ? false : true}
              type="radio"
              className="review-type-radio-btn"
              name="review-type"
              id={"product" + index}
              value={"product" + index}
              data-idx={productList.m_idx}
              data-mname={productList.m_name}
              data-midx={productList.m_idx}
              onChange={productChangeEvent}
            />
            <label
              htmlFor={"product" + index}
              className="review-type-label"
              disabled={productList.r_idx == null ? false : true}
            >
              {productList.m_name}
            </label>
          </li>
        );
      });

      setLiTagList(liTagList);
    }
  }, [cartList]);

  return (
    <div className="daps-frame">
      <div class="daps-title-box">주문내역</div>
      <div className="review-type-frame">
        <div className="review-title">
          <p>상품선택</p>
        </div>
        <ul className="review-type-choice">{liTagList}</ul>
        <div className="review-write-btn">
          <button
            className="review-writing-btn"
            onClick={() =>
              reviewWritingClickEvent({
                od_idx: state.od_idx,
                s_name: state.s_name,
                s_idx: state.s_idx,
                type: state.type,
                c2_idx: state.c2_idx,
                c3_idx: state.c3_idx,
              })
            }
          >
            작성
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReviewWriting;
