import { useState, useEffect, useRef } from "react";
import "components/mykiikey/style/EventEnter.css";
import { teal } from "@material-ui/core/colors";
import KakaoAddrPopup from "components/common/KakaoAddrPopup";
import { useLocation } from "react-router-dom";
import { writeMyEvent, getAdministrativeArea } from "service/AccountService";
import { ReactComponent as CloseIcon } from "src_assets/close.svg";
import loadingimg from "src_assets/loadingimg.png";
import { API_BASE_URL, convertURLtoFile, EVENT_APPLY_IMG_PATH } from "App-config";
import { async } from "@firebase/util";
import { NewLoading, CycleBarLoading } from "components/common/NewLoading";

const EventEnter = ({ userInfo }) => {
    const { state } = useLocation();
    const maxImgCount = 3;
    const [gender, setGender] = useState(1);
    const [addr, setAddr] = useState("");
    const [selectedSNS, setSelectedSNS] = useState("0");
    const [selectedArea, setSelectedArea] = useState("0");
    const [selectedAreaGu, setSelectedAreaGu] = useState("0");
    const selectedAreaSi_Ref = useRef();
    const selectedAreaGu_Ref = useRef();

    const inputRef = useRef([]);
    const userAddrBtn = useRef();

    const [fileImage, setFileImage] = useState([]);
    const [fileImageUrl, setFileImageUrl] = useState([]);
    const [memberName, setMemberName] = useState("");
    const [memberBirth, setMemberBirth] = useState("");
    const [memberSNS, setMemberSNS] = useState("");
    const [eventNote, setEventNote] = useState("");
    const [administrativeArea, setAdministrativeArea] = useState(null);
    const [imgUploading, setImgUploading] = useState(false);

    const fileRef = useRef();
    const contents = useRef();

    const [tel, setTel] = useState({
        tel_1: "",
        tel_2: "",
        tel_3: "",
    });

    const imgFormet = ["png","jpg","jpeg","svg","svgz","webp"];
    const genderClick = (e) => { };

    const tellCheck = (e, idx) => {
        const onlyNum = e.target.value.replace(/[^0-9]/g, "");

        setTel((state) => ({ ...state, [e.target.name]: onlyNum }));
        if (e.target.maxLength == onlyNum.length) {
            if (idx < 2) inputRef.current[idx + 1]?.focus();
        }
    };

    const addrHandleEvent = (fullAddr) => {
        setAddr(fullAddr);
        //document.getElementById("address-detail-change").focus();
    };

    const memberNameChange = (e) => {
        const name = e.target.value;
        setMemberName(name);
    };

    const memberBirthChange = (e) => {
        // const birth =
        //     e.target.value
        //         .replace(/[^0-9]/g, '')
        //         .replace(/(\d{4})(\d{2})(\d{2})/, '$1. $2. $3');
        // setMemberBirth(birth);
    };

    const memberSnsChange = (e) => {
        if (selectedSNS > 0) {
            setMemberSNS(e.target.value);
        }
        else {
            setMemberSNS('');
        }
    }
    // console.dir(fileRef.current.files[0]);
    
    const saveFileImage = e => {
        
        if (fileImage.length < maxImgCount) {
            
            //let addedFiles = fileImage.concat(file)eventmem
            //this.setState({ files: addedFiles })
            //setFileImage(...fileImage,e.target.files);
            //console.dir(e.target);
            let fileObj = e.target.files && e.target.files[0];
            
            if (fileObj) {
                if(fileObj.type.indexOf("image") != -1){

                    let ff = fileObj.type.split('/').pop();
                    if(!imgFormet.includes(ff)){
                        alert("지원 하지 않는 확작자 입니다.")
                        return;
                    } 

                    console.dir(fileObj);

                    console.log('이미지 저장시작');
                    setImgUploading(true);

                    if(fileObj.size > 1024*1024*3){
                        var imgUrl = URL.createObjectURL(fileObj);
                        var img = new Image();
                        img.onload = async function() {

                            console.log('변경전 이미지 크기' + img.width + "," + img.height);

                            var canvas = document.createElement('canvas'),
                            max_size = 1920,// TODO : pull max size from a site config
                            width = this.width,
                            height = this.height;

                            if (width > height) {
                                if (width > max_size) {
                                    height *= max_size / width;
                                    width = max_size;
                                }
                            } else {
                                if (height > max_size) {
                                    width *= max_size / height;
                                    height = max_size;
                                }
                            }
                            canvas.width = width;
                            canvas.height = height;

                            canvas.getContext('2d').drawImage(this, 0, 0, width, height);
                            img.src = canvas.toDataURL();
                            img.onload = null;

                            console.log('변경후 이미지 크기' + width + "," + height);
                            //console.log(img.src);

                            
                            const response = await fetch(img.src);
                            const data = await response.blob();
                            
                            const filename = img.src.substring(img.src.lastIndexOf('.')+1) + "." +fileObj.type.split('/').pop();// url 구조에 맞게 수정할 것
                            const metadata = { type: `${fileObj.type}` };
                            const file = new File([data], filename, metadata);
                            console.dir(file);

                            //setFileImage(origin => [...origin, file]);
                            // file.then((res)=>{
                            //     console.log('ressesese');
                            //     console.dir(res);
                            // })
                            // let tt_imgFile = convertURLtoFile(img.src).then((res)=>{
                            //     console.log('ressesese');
                            //     console.dir(res);
                            // });
                            if(file.size > 1024*1024*3){
                                alert("사진 용량이 너무 큽니다.");
                            }else{
                                setFileImage(origin => [...origin, file]);
                                setFileImageUrl(origin => [...origin, URL.createObjectURL(file)]);
                            }

                            console.log('이미지 저장완료');
                            setImgUploading(false);
                         
                            
                        }
                        
                        img.src = imgUrl;
                        
                    }
                    else{
                        setFileImage(origin => [...origin, fileObj]);
                        setFileImageUrl(origin => [...origin, URL.createObjectURL(fileObj)]);

                        console.log('이미지 저장완료');
                        setImgUploading(false);
                    }
                    

                    


                    
                }
            }

            e.target.value = null;

            

            // if (fileRef.current.files[0]) {
            //     setFileImage(origin => [...origin, fileRef.current.files[0]]);
            //     setFileImageUrl(origin => [...origin, URL.createObjectURL(fileRef.current.files[0])]);

            // }
        }
        
        
        
    };

    const imgCountCheck = (e) => {
        if (fileImage.length >= maxImgCount) {
            alert("더 이상 사진을 추가할 수 없습니다.");
            return;
        }
    };

    // 파일 삭제
    const deleteFileImage = (e, idx) => {
        URL.revokeObjectURL(fileImageUrl[idx]);
        setFileImageUrl((origin) =>
            origin.filter((url) => url != fileImageUrl[idx])
        );
        setFileImage((origin) => origin.filter((url) => url != fileImage[idx]));
    };

    const eventApplyClickEvent = () => {
        if(imgUploading){
            alert("이미지 업로드 중입니다. 잠시만 기다려주세요.");
            return;
        }

        if (
            selectedAreaSi_Ref.current.value == 0 ||
            selectedAreaSi_Ref.current.value == 0
        ) {
            alert("주소를 입력해주세요.");
            return;
        }

        const telNum = tel.tel_1 + tel.tel_2 + tel.tel_3;

        if (!telNum.match(/^01(0|1|[6-9])(\d{3}|\d{4})(\d{4})$/)) {
            alert("올바른 전화번호가 아닙니다.");
            return;
        }

        if (contents.current.value == "") {
            alert("이벤트 사연이 비어있습니다.");
            return;
        }

        // alert("등록 임시")
        // return;

        const formData = new FormData();

        formData.append("esIdx", state.eventData.es_idx);
        formData.append("siIdx", selectedArea);
        formData.append("guIdx", selectedAreaGu);

        formData.append("eaPhone", telNum);

        let sns = "";
        switch (selectedSNS * 1) {
            case 0:
                sns = "";
                break;
            case 1:
                sns = memberSNS + "@Instagram";
                break;
            case 2:
                sns = memberSNS + "@Naver";
                break;
            case 3:
                sns = memberSNS + "@Youtube";
                break;
            default:
                sns = "";
                break;
        }
        console.log(selectedSNS);
        console.log(memberSNS);
        console.log(sns);
        //console.log(selectedAreaSi_Ref.current?.options[selectedAreaSi_Ref.current.selectedIndex].text);
        formData.append("eaSns", sns);
        formData.append("eaContent", contents.current.value);

        if (fileImage.length > 0) {
            fileImage.forEach((item) => {
                console.log(item);
                formData.append("files[]", item);
            });
            formData.append("test_file[]", fileImage);
        }

        formData.append("action", "writeMyEvent");
        formData.append("isModify", state.eventContentData.write);

        console.dir(formData);
        writeMyEvent(formData, true)
            .then((res) => {
                if (res.success) {
                    //   let newEventContentData = [...state.eventContentData.rows];
                    //   if (state.eventContentData.write > 0) {
                    //     for (let index = 0; index < newEventContentData.length; index++) {
                    //       const element = newEventContentData[index];
                    //       if (res.data.mb_id === element.mb_id) {
                    //         newEventContentData.splice(index, 1);
                    //         break;
                    //       }
                    //     }
                    //   }
                    //   newEventContentData.push(res.data);
                    //   setEventContentData({ write: 1, rows: newEventContentData });
                    //   setMyApplyData(res.data);
                    alert("등록 완료");
                    //window.location.replace("/mykiki/eventApply");
                    window.history.back();
                }
            })
            .catch((err) => {
                console.error(err);
                alert(err.msg);
            });
    };

    const ImageComponet = () => {
        const imgList = fileImageUrl.map((data, index) => {
            return (
                <>
                    <section className="photo-box">
                        <img alt="응모 이미지" src={data} />
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                position: "absolute",
                                width: "50%",
                                height: "50%",
                                top: "-25%",
                                left: "75%",
                            }}
                            onClick={(e) => deleteFileImage(e, index)}
                        >
                            <CloseIcon width={"40%"} />
                        </div>
                    </section>
                </>
            );
        });
        return <>{imgList}</>;
    };

    useEffect(() => {
        getAdministrativeArea()
            .then((res) => {
                if (res.success) {
                    setAdministrativeArea(res.data);
                }
            })
            .catch((err) => {
                console.error(err);
                alert(err.msg);
            });
    }, []);

    useEffect(() => {
        if (userInfo) {
            console.dir(userInfo);
            setMemberName(userInfo.mb_name);

            //성별
            setGender(userInfo.mb_sex * 1);

            setMemberBirth(
                userInfo.mb_birth.replace(/(\d{4})(\d{2})(\d{2})/, "$1. $2. $3")
            );

            //주소
            if (administrativeArea) {
                console.dir(administrativeArea);
                setSelectedArea(userInfo.si_idx);
                //selectedAreaSi_Ref.current.selectedIndex = ;
                setSelectedAreaGu(userInfo.gu_idx);
            }

            let tmp_tel = {
                tel_1: userInfo.mb_phone.substr(0, 3),
                tel_2: userInfo.mb_phone.substr(3, 4),
                tel_3: userInfo.mb_phone.substr(7, 4),
            };
            setTel(tmp_tel);
        }

        if (state.myApplyData) {
            console.dir(state.myApplyData);
            //주소
            setSelectedArea(state.myApplyData.si_idx);
            setSelectedAreaGu(state.myApplyData.gu_idx);

            //신청 전화번호
            if(state.myApplyData.ea_phone?.match(/^01(0|1|[6-9])(\d{3}|\d{4})(\d{4})$/)){
                let tmp_tel;
                if(state.myApplyData.ea_phone.length == 10){
                    tmp_tel = {
                        tel_1: state.myApplyData.ea_phone.substr(0, 3),
                        tel_2: state.myApplyData.ea_phone.substr(3, 3),
                        tel_3: state.myApplyData.ea_phone.substr(6, 4),
                    };
                }
                else{
                    tmp_tel = {
                        tel_1: state.myApplyData.ea_phone.substr(0, 3),
                        tel_2: state.myApplyData.ea_phone.substr(3, 4),
                        tel_3: state.myApplyData.ea_phone.substr(7, 4),
                    };
                }
                
                setTel(tmp_tel);
            }
            

            //sns
            if (state.myApplyData.ea_sns != "") {
                let sns = state.myApplyData.ea_sns.split("@");

                setMemberSNS(sns[0]);

                switch (sns[1]) {
                    case "Instagram":
                        setSelectedSNS(1);
                        break;
                    case "Naver":
                        setSelectedSNS(2);
                        break;
                    case "Youtube":
                        setSelectedSNS(3);
                        break;
                }
            }

            //이벤트 사연
            contents.current.value = state.myApplyData.ea_content;


        }

        if (state.eventData) {
            //이벤트 유의사항
            let note_array = state.eventData.es_note?.split("\r\n");
            setEventNote(note_array);
        }
    }, [userInfo, state, administrativeArea]);


    useEffect(() => {
        //이벤트 사연 사진 로드
        let tmp_imgUrl = [];
        let tmp_imgFile = [];

        // for(let i = 0; i < state.myApplyData.ea_img.length; i++){
            
        // }

        const tmp = state.myApplyData?.ea_img.map((url)=>{

            tmp_imgUrl.push(
                EVENT_APPLY_IMG_PATH +
                //API_BASE_URL + "/img/event/applyImg/" +
                state.eventData.em_idx + "/" +
                url);

            console.log(tmp_imgUrl);
            //   console.dir(convertURLtoFile("http://localhost:7777/api/img/event/applyImg/" +
            //   state.eventData.em_idx + "/" +
            //   url));

            let tt_imgFile = convertURLtoFile(
                EVENT_APPLY_IMG_PATH +
                //API_BASE_URL + "/img/event/applyImg/" +
                state.eventData.em_idx + "/" +
                url)
            
                tmp_imgFile.push(tt_imgFile);
                //console.log(tmp_imgFile);
            
            //console.log(url);

            return tt_imgFile;

        });

        const tmp2 = Promise.all(tmp).then((res)=>{
            console.log('res');
            console.log(res);

            setFileImage(res);
        });
        console.log(tmp_imgFile);
        console.log(fileImage);

        // state.myApplyData.ea_img.forEach(async (url) => {
             
        //     tmp_imgUrl.push(
        //         "http://localhost:7777/api/img/event/applyImg/" +
        //         state.eventData.em_idx + "/" +
        //         url);

        //     console.log(tmp_imgUrl);
        //     //   console.dir(convertURLtoFile("http://localhost:7777/api/img/event/applyImg/" +
        //     //   state.eventData.em_idx + "/" +
        //     //   url));

        //     let tt_imgFile = convertURLtoFile(API_BASE_URL + "/img/event/applyImg/" +
        //         state.eventData.em_idx + "/" +
        //         url)
            
        //         tmp_imgFile.push(res);
        //         console.log(tmp_imgFile);
            
        //     console.log(url);
            
        // })

        setFileImageUrl(tmp_imgUrl);
        //setFileImage(tmp_imgFile);

    }, [state.myApplyData?.ea_img])

    return (
        <div className="event-enter-frame">
            <div className="field">
                <b>성명</b>
                <span class="placehold-text">
                    <input
                        type="text"
                        placeholder="이름을 입력해주세요!"
                        value={memberName}
                    />
                </span>
            </div>

            <div className="field">
                <b>성별</b>
                <span class="placehold-text" style={{ border: "none" }}>
                    <input
                        type="radio"
                        onChange={genderClick}
                        value="1"
                        id="event-gender-male"
                        name="gender"
                        checked={gender === 1 && true}
                    //defaultChecked={gender === 1 && true}
                    />
                    <label htmlFor="event-gender-male">남자</label>
                    <input
                        type="radio"
                        onChange={genderClick}
                        value="2"
                        id="event-gender-female"
                        name="gender"
                        checked={gender === 2 && true}
                    //defaultChecked={gender === 2 && true}
                    />
                    <label htmlFor="event-gender-female">여자</label>
                </span>
            </div>

            <div className="field">
                <b>생년월일</b>
                <span class="placehold-text">
                    <input
                        type="text"
                        placeholder="생년월일 8자리를 입력해주세요."
                        onChange={memberBirthChange}
                        value={memberBirth}
                        style={{ textAlign: "center" }}
                    />
                </span>
            </div>

            <div className="field">
                <b>지역</b>
                <span class="placehold-text-addr">
                    {/* <input type="text" value={addr} /> */}
                    <select
                        className="event-sns-select"
                        value={selectedArea}
                        onChange={(e) => {
                            console.dir(e.target.value);
                            setSelectedArea(e.target.value);
                            //setEventData(eventList[e.target.value]);
                        }}
                        ref={selectedAreaSi_Ref}
                    >
                        <option value={0} disabled>시 선택</option>
                        {administrativeArea &&
                            administrativeArea.si.map((data, index) => {
                                return (
                                    <>
                                        <option value={(data.si_idx)}>
                                            {data.si_name}
                                        </option>
                                    </>

                                )
                            })
                        }
                    </select>

                    <select
                        className="event-sns-select"
                        value={selectedAreaGu}
                        onChange={(e) => {
                            console.dir(e.target.value);
                            setSelectedAreaGu(e.target.value);
                            //setEventData(eventList[e.target.value]);
                        }}
                        ref={selectedAreaGu_Ref}
                    >
                        <option value={0} disabled>구 선택</option>
                        {selectedArea > 0 && administrativeArea &&
                            administrativeArea.gu.map((data, index) => {
                                if (data.si_idx == selectedArea) {
                                    return (
                                        <>
                                            <option value={(index + 1)}>
                                                {data.gu_name}
                                            </option>
                                        </>
                                    )
                                }
                            })
                        }

                    </select>
                </span>


            </div>

            <div className="field">
                <b>전화번호</b>
                <span class="placehold-text">
                    <input
                        type="tel"
                        name="tel_1"
                        maxlength="3"
                        onChange={(e) => tellCheck(e, 0)}
                        value={tel?.tel_1}
                        ref={(el) => (inputRef.current[0] = el)}
                    />
                    <b>&nbsp;-&nbsp;</b>
                    <input
                        type="tel"
                        name="tel_2"
                        maxlength="4"
                        onChange={(e) => tellCheck(e, 1)}
                        value={tel?.tel_2}
                        ref={(el) => (inputRef.current[1] = el)}
                    />
                    <b>&nbsp;-&nbsp;</b>
                    <input
                        type="tel"
                        name="tel_3"
                        maxlength="4"
                        onChange={(e) => tellCheck(e, 2)}
                        value={tel?.tel_3}
                        ref={(el) => (inputRef.current[2] = el)}
                    />
                </span>
            </div>

            <div className="field">
                <b>SNS 계정</b>
                <span class="placehold-text-sns">
                    <input type="text" value={memberSNS} onChange={memberSnsChange} />
                    <select
                        className="event-sns-select"
                        value={selectedSNS}
                        onChange={(e) => {
                            setSelectedSNS(e.target.value);
                            if (e.target.value == 0) setMemberSNS("");
                            //setEventData(eventList[e.target.value]);
                        }}
                    >
                        <option value={0}>계정없음</option>

                        <option value={1}>Instagram</option>

                        <option value={2}>Naver</option>

                        <option value={3}>Youtube</option>
                    </select>
                </span>
            </div>

            <div className="field">
                <b>이벤트 사연</b>
                <span class="placehold-text" id="event-story">
                    <textarea
                        ref={contents}
                        rows="5"
                        cols="60"
                        placeholder="사연을 적어주세요!"
                    />
                </span>
            </div>

            <div className="field">
                <div className="photo-field">

                    {fileImageUrl.length > 0 && (
                        <ImageComponet></ImageComponet>
                    )}

                    <section className="photo-box" style={{display: !imgUploading && "none"}  }>
                            {/* <CycleBarLoading></CycleBarLoading> */}
                            <img id="loading-img" src={loadingimg} />
                    </section>
                    


                    {fileImageUrl.length < maxImgCount && (
                        <>
                            <input
                                //ref={fileRef}
                                type="file"
                                id="photo-upload-btn"
                                name="photo"
                                accept="image/png, image/jpg, image/jpeg, image/svg, image/svgz, image/webp"
                                onChange={saveFileImage}
                                onClick={imgCountCheck}
                            />
                            <label htmlFor="photo-upload-btn" className="photo-upload-btn-label"><span>&#43;</span>사진등록</label>
                        </>
                    )}

                </div>
            </div>

            <div className="field">
                <b>유의사항</b>
                <span class="placehold-text" id="event-note">
                    {eventNote?.length > 0 &&
                        eventNote.map((data) => {
                            return <p>{data}</p>;
                        })}
                </span>
            </div>

            <div className="field">
                <div className="submit-field">
                    <button
                        className="event-cancel-button"
                        onClick={() => {
                            window.history.back();
                        }}
                    >
                        취소
                    </button>
                    <button
                        className="event-upload-button"
                        onClick={eventApplyClickEvent}
                    >
                        {state.myApplyData ? '수정' : '등록'}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default EventEnter;
