import { Link } from "react-router-dom";

import TopNav from "components/common/navbar/TopNavComponent";

import "components/common/navbar/style/SideNav.css";

function SideNav({
  mobileBtnClickEvent,
  isMobile,
  isLogin,
  logoutEvent,
  setIsSideView,
}) {
  return (
    <aside className="mobile-menu-section">
      <div className="menu-head">
        <button onClick={mobileBtnClickEvent} className="mobile-menu-button" />
        <div className="mobile-function">
          <span>
            {isLogin ? (
              <Link
                onClick={() => {
                  mobileBtnClickEvent(this, logoutEvent);
                }}
                to="/"
              >
                로그아웃
              </Link>
            ) : (
              <Link to="/account/signinRoute" onClick={mobileBtnClickEvent}>
                로그인
              </Link>
            )}
          </span>
          <button className="aside-close" onClick={mobileBtnClickEvent} />
        </div>
      </div>
      <div className="mobile-nav">
        <TopNav
          isLogin={isLogin}
          isMobile={isMobile}
          setIsSideView={setIsSideView}
          logoutEvent={logoutEvent}
        />
      </div>
    </aside>
  );
}

export default SideNav;
