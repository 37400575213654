/* global nicepaySubmit */
/* global nicepayClose */
/* global nicepayStart */
/* global goPay */

import { useMemo, useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import {
  getMemberInfo,
  paySuccess,
  submitPayment,
} from "service/OrderService";
import { checkPlatform, isKiikeyApp, serverErrorHandler } from "App-config";

import OrderUsePoint from "components/order/payment/OrderUsePoint";
import OrderPayType from "components/order/payment/OrderPayType";
import InputPayFinalConfirm from "components/order/payment/InputPayFinalConfirm";

import "components/order/style/OrderDetail.css";
import stateUtilityInstance from "TestClass";
import { chargePoint, createPublicBarcode } from "service/AccountService";

const { goPay } = window;

const InputPayment = ({ isMobile, userInfo, setUserInfo }) => {
  const targetForm = useRef();
  const navigate = useNavigate();

  const location = useLocation();

  const [isPorc, setIsProc] = useState(false);

  const [myPoint, setMyPoint] = useState(0);
  const [usePoint, setUsePoint] = useState(0);

  const [usePointList, setUsePointList] = useState([]);

  const [isCreate, setIsCreate] = useState(false);
  const [odIdxList, setOdIdxList] = useState([]);

  const [formData, setFormData] = useState({});

  const totalAmt = useMemo(() => {
    let totalPrice = location.state.cAmt;

    return totalPrice;
  }, [usePointList]);

  useEffect(() => {
    // window.onpageshow = (e) => {
    //   console.log("page show : ", e);
    //   navigate("/order/payment");
    // };
    window.nicepaySubmit = (e) => {
      const temp = {
        PayMethod: targetForm.current.PayMethod.value,
        GoodsName: targetForm.current.GoodsName.value,
        Amt: targetForm.current.Amt.value,
        MID: targetForm.current.MID.value,
        Moid: targetForm.current.Moid.value,
        BuyerName: targetForm.current.BuyerName.value,
        BuyerTel: targetForm.current.BuyerTel.value,
        EdiDate: targetForm.current.EdiDate.value,
        SignData: targetForm.current.SignData.value,
        ReturnURL: targetForm.current.ReturnURL.value,
        AuthResultCode: targetForm.current.AuthResultCode.value,
        AuthResultMsg: targetForm.current.AuthResultMsg.value,
        NextAppURL: targetForm.current.NextAppURL.value,
        TxTid: targetForm.current.TxTid.value,
        AuthToken: targetForm.current.AuthToken.value,
        PayMethod: targetForm.current.PayMethod.value,
        MID: targetForm.current.MID.value,
        Moid: targetForm.current.Moid.value,
        Amt: targetForm.current.Amt.value,
        ReqReserved: targetForm.current.ReqReserved.value,
        NetCancelURL: targetForm.current.NetCancelURL.value,
      };
      paySuccess(temp)
        .then((res) => {
          setTimeout(() => {
            try {
              document.getElementById("nice_layer").remove();
              document.getElementById("bg_layer").remove();
            } catch (error) { }
            setIsCreate(false);
            navigate("/order/payresult");
          }, 700);
        })
        .catch((err) => {
          console.error(err);
        });
    };

    window.nicepayStart = () => {
      setTimeout(() => {
        if (checkPlatform() === "mobile" || isMobile) {
          //모바일 결제창 진입
          targetForm.current.action =
            "https://web.nicepay.co.kr/v3/v3Payment.jsp";

          targetForm.current.submit();
          setIsProc(false);
        } else {
          //PC 결제창 진입
          goPay(targetForm.current);
        }
      }, 800);
    };

    getMemberInfo()
      .then((res) => {
        setUserInfo(res);
        setMyPoint(res.mb_point * 1);
      })
      .catch((err) => {
        console.error("payment: err :: ", err);
        //에러처리필요
        serverErrorHandler(err);
      });
  }, []);

  useEffect(() => {
    if (odIdxList.length > 0) {
      const sp_pay_type = document.querySelector(
        'input[name="payment-type"]:checked'
      ).value;
      if (location.state.cAmt - usePoint > 0 && sp_pay_type > 10) {
        submitPayment({ odIdxList, isKiikeyApp })
          .then((res) => {
            if (res.success) {
              setFormData(res.data);
            }
          })
          .catch((err) => {
            console.error("err", err);
          });
        setIsCreate(true);
      } else {
        setIsProc(false);

        if (location.state.odOption == 9) {
          createPublicBarcode({ od_idx: odIdxList[0], price: location.state.cAmt })
            .then((res) => {
              if (res.success) {
                console.log("바코드 생성");
                console.log(res.data);

                // window.location.replace("/mykiki/myWallet?b_code=" + res.data.b_code);
                window.history.go(-2);
                //navigate("/mykiki/myWallet", { state: {data: res.data}, replace: true })
              }
              else {
                alert("바코드 생성 오류");
                navigate("/mykiki/myWallet", { replace: true });
              }
            })
            .catch((err) => {
              console.error(err);
              alert("바코드 생성에러");
            })


          // let returnUrl = window.location.protocol + "//" + window.location.host + "/order/payresult?odOption=" + basketList[0].od_option;
          // //window.location.href = window.location.window.location+"//"+ window.location.host + "/order/payresult?odOption=" + basketList[0].od_option;
          // navigate("/order/payresult?odOption=" + basketList[0].od_option, { replace: true});
        }
      }
    }
    window.nicepayClose = () => {
      alert("결제가 취소 되었습니다");
      setIsProc(false);
      setIsCreate(false);
    };

    if (!isMobile) {
      // 결제모듈이 떠있을때 뒤로가기시
      window.addEventListener("popstate", window.nicepayClose);
      return () => {
        window.removeEventListener("popstate", window.nicepayClose);
      };
    }
  }, [odIdxList]);

  useEffect(() => {
    if (odIdxList.length > 0 && isCreate) {
      window.nicepayStart();
    }
  }, [isCreate]);


  const pointChangeHandler = (pIndex, value) => {
    let newList = usePointList;
    newList[pIndex] = value;

    let newTotalPoint = newList.reduce(
      (prev, current) => prev * 1 + current * 1
    );

    setUsePointList(newList);
    setUsePoint(newTotalPoint);
  };

  return (
    <section className="delivery-history-frame">
      {/* <div className="title_area">
        <div className="delivery-title-pre">
          <img src={pre} alt="뒤로가기"></img>
        </div>
        <span className="delivery-title">주문 내역</span>
      </div> */}

      <PaymentArticle
        userInfo={userInfo}
        pointChangeHandler={pointChangeHandler}
        myPoint={myPoint}
        usePoint={usePoint}
        location={location}
        pIndex={0}
      />

      {/* <PickupTime /> */}

      <OrderPayType isNotMeet={true} />
      <InputPayFinalConfirm
        location={location}
        usePoint={usePoint}
        isPorc={isPorc}
        setIsProc={setIsProc}
        setIsCreate={setIsCreate}
        setOdIdxList={setOdIdxList}
      />

      <div className="final-confirmation">
        <form
          name="payForm"
          action={formData.returnURL}
          method="post"
          acceptCharset="euc-kr"
          ref={targetForm}
        >
          <input type="hidden" name="PayMethod" value={formData.payMethod} />
          <input type="hidden" name="GoodsName" value={formData.goodsName} />
          <input type="hidden" name="Amt" value={formData.price} />
          <input type="hidden" name="MID" value={formData.MID} />
          <input type="hidden" name="Moid" value={formData.moid} />
          <input type="hidden" name="BuyerName" value={formData.buyerName} />
          <input type="hidden" name="BuyerEmail" value="" />
          <input type="hidden" name="BuyerTel" value={formData.buyerTel} />
          <input type="hidden" name="ReturnURL" value={formData.returnURL} />
          <input type="hidden" name="VbankExpDate" value="" />
          <input type="hidden" name="TransType" value="0" />
          <input type="hidden" name="CharSet" value="euc-kr" />
          <input type="hidden" name="GoodsCl" value="1" />
          <input
            type="hidden"
            name="ReqReserved"
            value={formData.reqReserved}
          />
          <input type="hidden" name="EdiDate" value={formData.ediDate} />
          <input type="hidden" name="SignData" value={formData.hashString} />
        </form>
      </div>
    </section>
  );
};

const PaymentArticle = ({
  userInfo,
  pointChangeHandler,
  myPoint,
  usePoint,
  location,
  pIndex
}) => {
  const childComponentRef = useRef();

  return (
    <div className={"order-wrap" + location.state.sIdx} key={location.state.sIdx + pIndex}>
      <div className="order-content">
        {/* <div className="underline"></div> */}
        <div className="wrap_frame">
          <div className="wrap_frame-wrap">
            <div className="order-content-title">
              <span className="order-title-light">주문 내용</span>
            </div>
            <ul className="order-content-list">
              <div className="order-menu-name-box" data-tprice={location.state.cAmt}>
                <span className="menu-name">{location.state.is_private == true ? "포인트 충전" : "바코드 생성"}</span>
                <span className="menu-price">
                  <p>
                    {location.state.cAmt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    <span className="font-weght-light2">원</span>
                  </p>
                </span>
              </div>
            </ul>
          </div>
        </div>
      </div>
      {!location.state.is_private &&
        < OrderUsePoint
          sIdx={location.state.sIdx}
          c2Idx={0}
          payIndex={0}
          shopTotalAmt={location.state.cAmt}
          myPoint={myPoint}
          usePoint={usePoint}
          pointChangeHandler={pointChangeHandler}
          ref={childComponentRef}
          userInfo={userInfo}
        />}
    </div>
  );
};

export default InputPayment;
