import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {
  deleteMyEvent,
  getEventContentData,
  getEventData,
  writeMyEvent,
} from "service/AccountService";

import eventimg from "src_assets/event-select-img.png";

import "components/mykiikey/style/Mykiikey.css";
import "components/mykiikey/style/EventApply.css";
import stateUtilityInstance from "../../TestClass";
import { useRef } from "react";
import { API_BASE_URL, EVENT_APPLY_IMG_PATH, EVENT_SLAVE_IMG_PATH } from "App-config";

// LEE 마크업 2022-07-15
const EventApply = ({ eventList, setEventList }) => {
  const navigate = useNavigate();

  const [selected, setSelected] = useState("0");

  const [isCreateView, setIsCreateView] = useState(false);
  const [isModifyView, setIsModifyView] = useState(false);
  const [eventData, setEventData] = useState(null);
  const [eventContentData, setEventContentData] = useState(null);

  const [myApplyData, setMyApplyData] = useState(null);

  // Mun 모달창
  const [modalOpen, setModalOpen] = useState(false);
  const [modalImgUrl, setModalImgUrl] = useState("");

  const showModal = (url) => {
    setModalOpen(true);
    setModalImgUrl(url);
  };
  const closeModal = () => {
    setModalOpen(false);
  };
  useEffect(() => {
    getEventData()
      .then((res) => {
        if (res.success) {
          setEventList(res.data);
          if (res.data.length > 0) {
            setEventData(res.data[0]);
          }
        }
      })
      .catch(console.error);
  }, []);

  useEffect(() => {
    if (eventData) {
      getEventContentData({ esIdx: eventData.es_idx })
        .then((res) => {
          if (res.success) {
            setEventContentData(res.data);
            if (res.data.write) {
              for (let index = 0; index < res.data.rows.length; index++) {
                const element = res.data.rows[index];
                if (res.data.mb_id === element.mb_id) {
                  setMyApplyData(element);
                  break;
                }
              }
            } else {
              setMyApplyData(null);
            }
          }
        })
        .catch((err) => {
          console.error("getEventContentData", err);
          navigate(-1);
        });
    }
  }, [eventData]);

  const EventApplyList = () => {
    const optionlist = eventList.map((item, index) => {
      return (
        <option key={item.mb_id + index} value={index}>
          {item.em_title}
        </option>
      );
    });
    return (
      <div className="select-event-box">
        <span className="select-event-title">
          <img src={eventimg} alt="이벤트이미지" />
          <p>이벤트 선택</p>
        </span>
        <div className="select-event">
          <select
            value={selected}
            onChange={(e) => {
              setSelected(e.target.value);
              setEventData(eventList[e.target.value]);
            }}
          >
            {optionlist}
          </select>
        </div>
      </div>
    );
  };

  const EventApplyDetail = () => {
    const VIEW_LIMIT = 10;
    const [currentPage, setCurrentPage] = useState(1);
    const offset = (currentPage - 1) * VIEW_LIMIT;

    const rowsLimit = eventContentData?.rows.slice(0, offset + VIEW_LIMIT);

    const loader = useRef();

    const deleteBtnClickEvent = () => {
      deleteMyEvent({ esIdx: eventData.es_idx })
        .then((res) => {
          if (res.success) {
            let newEventContentData = [];
            eventContentData.rows.forEach((item) => {
              if (item.mb_id != res.data) {
                newEventContentData.push(item);
              }
            });
            setEventContentData({ write: 0, rows: newEventContentData });
            setMyApplyData(null);
          }
        })
        .catch(console.error);
    };

    const showMore = () => {
      if(Math.ceil(eventContentData.rows.length / VIEW_LIMIT) > currentPage)
        setCurrentPage((currentPage) => currentPage + 1);
    };

    if (rowsLimit) {
      const trTagList = rowsLimit.map((item, index) => {
        return (
          <tr key={item.mb_id + index}>
            <tr
              className="event-list-head"
              onClick={(e) => {
                e.currentTarget.nextElementSibling?.classList.toggle(
                  "dis-none"
                );
              }}
            >
              {/* <td>{index + 1}</td> */}
              <td>{eventContentData?.rows.length - index}</td>
              <td>{item.mb_id}</td>
              <td>{item.ea_datetime}</td>
            </tr>
            {/* evene-list-body 는 event-list-head를 클릭 했을때 뜸 'event-list-body-none' */}

            {item.ea_img || item.ea_content ? (
              <tr className="event-list-body dis-none">
                {console.log(item.ea_img)}
                <div
                  className={"event-list-body-img-wrap"}
                  style={{ display: item.ea_img.length == 0 && "none" }}
                  // style={{ height: eventData.em_idx && "200px" }}
                >
                  {item.ea_img.map((img, index) => (
                    <span
                      className="event-list-body-img"
                      // style={{ height: index > 0 && "100px" }}
                    >
                      <img
                        onClick={() => {
                          showModal(
                            EVENT_APPLY_IMG_PATH +
                            //API_BASE_URL + "/img/event/applyImg/" +
                              eventData.em_idx +
                              "/" +
                              img
                          );
                        }}
                        src={
                          EVENT_APPLY_IMG_PATH +
                          //API_BASE_URL+ "/img/event/applyImg/" +
                          eventData.em_idx +
                          "/" +
                          img
                        }
                      />
                    </span>
                  ))}
                </div>

                {item.ea_content && (
                  <span className="event-list-text">{item.ea_content}</span>
                )}
              </tr>
            ) : (
              <tr className="event-list-body dis-none">
                <span className="event-list-text">등록한 내용이 없습니다.</span>
              </tr>
            )}
          </tr>
        );
      });

      return (
        <div className="event-list">
          {/* event-do-control 응모를 했을떄 뜸 '.event-do-control-none' */}
          {/* event-to-apply 응모를하지 않았을때 뜸 */}
          {eventContentData.write > 0 ? (
            <div className="event-do-control">
              <button className="event-delete" onClick={deleteBtnClickEvent}>
                삭제
              </button>
              <button
                className="event-modify"
                onClick={() =>
                  navigate("enter", {
                    state: {
                      eventData: eventData,
                      eventContentData: eventContentData,
                      myApplyData: myApplyData,
                    },
                  })
                } //setIsModifyView(true)
              >
                수정
              </button>
            </div>
          ) : (
            <div className="event-to-apply">
              <button
                className="event-to-apply-button"
                onClick={
                  () =>
                    navigate("enter", {
                      state: {
                        eventData: eventData,
                        eventContentData: eventContentData,
                        myApplyData: myApplyData,
                      },
                    }) /*navigate("enter")*/ /*setIsCreateView(true)*/
                }
              >
                응모하기
              </button>
            </div>
          )}

          <div className="event-img-wrap">
            <div className="event-content">
              <div style={{minHeight : '20%'}}>
                <img
                  src={
                    EVENT_SLAVE_IMG_PATH +
                    eventData.es_idx +
                    "/" +
                    eventData.es_img
                  }
                  alt="이벤트이미지"
                
                  // style={{height: eventData.em_content == "" ? '98%' : '88%'}}
                />
              </div>
              
              {eventData.em_content !="" && (
                <span className="event-text">
                  {eventData.em_content.split("\r\n").map((line) => {
                    return (
                      <>
                        {line}
                        <br />
                      </>
                    );
                  })}
                </span>
              )}
              
            </div>
          </div>

          {eventContentData.rows.length > 0 && (
            <table className="event-list-table">
              <thead className="evnet-list-table-head">
                <tr>
                  <td>순서</td>
                  <td>아이디</td>
                  <td>응모시간</td>
                </tr>
              </thead>
              <tbody>{trTagList}</tbody>
            </table>
          )}

          <div
            style={{ textAlign: "center", padding: "10px", display: eventContentData.rows.length<=10 && 'none'}}
            onClick={showMore}
          >
            <span style={{ fontWeight: "bold" }}>
              참가자 더보기 ({currentPage}/
              {Math.ceil(eventContentData.rows.length / VIEW_LIMIT)})
            </span>
          </div>
        </div>
      );
    }
  };

  const EventApplyEmpty = () => {
    return (
      <div className="event-list">
        <span className="event-list-title">
          응모할 수 있는 이벤트가 없습니다.
        </span>
      </div>
    );
  };

  const EventModal = ({}) => {
    const fileRef = useRef();
    const contents = useRef();

    //파일 미리볼 url을 저장해줄 state
    const [fileImage, setFileImage] = useState("");

    // 파일 저장
    const saveFileImage = (e) => {
      setFileImage(URL.createObjectURL(fileRef.current.files[0]));
    };

    // 파일 삭제
    const deleteFileImage = () => {
      URL.revokeObjectURL(fileImage);
      setFileImage("");
    };

    const eventApplyClickEvent = () => {
      const formData = new FormData();

      formData.append("esIdx", eventData.es_idx);
      formData.append("eaContent", contents.current.value);
      formData.append("files", fileRef.current.files[0]);
      formData.append("action", "writeMyEvent");
      formData.append("isModify", eventContentData.write);

      writeMyEvent(formData)
        .then((res) => {
          if (res.success) {
            let newEventContentData = [...eventContentData.rows];
            if (eventContentData.write > 0) {
              for (let index = 0; index < newEventContentData.length; index++) {
                const element = newEventContentData[index];
                if (res.data.mb_id === element.mb_id) {
                  newEventContentData.splice(index, 1);
                  break;
                }
              }
            }
            newEventContentData.push(res.data);
            setEventContentData({ write: 1, rows: newEventContentData });
            setMyApplyData(res.data);
          }
        })
        .catch((err) => {
          console.error(err);
          alert(err.msg);
        });
      if (isCreateView) {
        setIsCreateView(false);
      } else {
        setIsModifyView(false);
      }
    };

    useEffect(() => {
      if (myApplyData) {
        console.dir(myApplyData);
        setFileImage(
          EVENT_APPLY_IMG_PATH + 
          //API_BASE_URL + "/img/event/applyImg/" +
            eventData.em_idx +
            "/" +
            myApplyData.ea_img[0]
        );
        contents.current.value = myApplyData.ea_content;
      }
    }, []);

    return (
      <div className="event-enter-modal-frame">
        <div className="event-enter-modal">
          <div className="photo-upload">
            <span className="photo-box">
              {fileImage && (
                <img
                  alt="응모 이미지"
                  src={fileImage}
                  style={{ margin: "auto" }}
                />
              )}
            </span>
            <input
              ref={fileRef}
              type="file"
              id="photo-upload-btn"
              name="photo"
              accept="image/*"
              onChange={saveFileImage}
            />
            <label htmlFor="photo-upload-btn">+사진등록</label>
          </div>
          <div className="text-input">
            <textarea
              ref={contents}
              name="event-enter-input"
              id="event-enter-input"
              cols="30"
              rows="10"
              placeholder="내용을 입력하세요"
            />
          </div>
          <div className="event-button">
            <button
              className="event-cancel-button"
              onClick={() => {
                if (isCreateView) {
                  setIsCreateView(false);
                } else {
                  setIsModifyView(false);
                }
              }}
            >
              취소
            </button>
            <button
              className="event-upload-button"
              onClick={eventApplyClickEvent}
            >
              {eventContentData.write > 0 ? "수정" : "등록"}
            </button>
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    stateUtilityInstance.setMobileHeaderTitle("이벤트 조회 및 응모");
  });

  return (
    <div className="daps-frame">
      {eventList.length > 0 ? (
        <>
          <EventApplyList eventList={eventList} />
          {eventData && <EventApplyDetail />}
          {/* <Pagination /> */}
        </>
      ) : (
        <EventApplyEmpty />
      )}
      {modalOpen && (
        <div className="event-modal" onClick={closeModal}>
          <div className="event-wrap">
            {/* <button onClick={closeModal}>X</button> */}
            <span className="event-modal-img">
              <img src={modalImgUrl} />
            </span>
            {/* <p>모달창입니다.</p> */}
          </div>
        </div>
      )}
      {isCreateView && <EventModal />}
      {isModifyView && <EventModal />}
    </div>
  );
};

export default EventApply;
