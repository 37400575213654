import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { getAgreeList } from "service/AccountService";

import "components/account/style/JoinAgreement.css";

const DangerHtml = ({ htmlStr }) => {
  return (
    <div
      className="agreement-text"
      dangerouslySetInnerHTML={{ __html: htmlStr }}
    />
  );
};

const Agreement = () => {
  const navigate = useNavigate();
  const AGREEMENTS = useRef([]);
  const TOTAL_AGREEMENT = useRef();

  const [useAgree, setUseAgree] = useState("");
  const [userInfo, setUserInfo] = useState("");
  const [marketing, setMarketing] = useState("");
  const [outAgree, setOutAgree] = useState("");

  useEffect(() => {
    getAgreeList().then((res) => {
      res.data.forEach((item) => {
        if (item.docsSubject === "이용약관") {
          setUseAgree(item.docsContent);
        } else if (item.docsSubject.indexOf("처리방침") > -1) {
          setUserInfo(item.docsContent);
        } else if (item.docsSubject.indexOf("마케팅") > -1) {
          setMarketing(item.docsContent);
        } else if (item.docsSubject.indexOf("제3자") > -1) {
          setOutAgree(item.docsContent);
        }
      });
    });
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    for (let index = 0; index < AGREEMENTS.current.length; index++) {
      const agr = AGREEMENTS.current[index];
      if (!agr.checked) {
        alert("약관에 동의가 필요합니다.");
        document
          .querySelectorAll(".agreement-box-style")
          [index].scrollIntoView({ behavior: "smooth" });
        return;
      }
    }

    navigate("/account/signup");
  };

  const onAgreeChange = () => {
    for (let index = 0; index < AGREEMENTS.current.length; index++) {
      const agr = AGREEMENTS.current[index];
      if (!agr.checked) {
        TOTAL_AGREEMENT.current.checked = false;
        return;
      }
    }
    TOTAL_AGREEMENT.current.checked = true;
  };

  const onTotalAgreeChange = () => {
    AGREEMENTS.current.forEach((checkbox) => {
      if (TOTAL_AGREEMENT.current.checked) {
        checkbox.checked = true;
      } else {
        checkbox.checked = false;
      }
    });
  };

  return (
    <section className="join-agreement-frame">
      <form className="agreement-form" onSubmit={onSubmit}>
        <div className="form-title">회원가입</div>
        <div className="agreement-box-style">
          <span className="agreement-title">
            <h3>
              <b> * </b>[필수] 이용약관 동의
            </h3>
            <button className="agreement-mobile-button"></button>
          </span>

          <DangerHtml htmlStr={useAgree} />

          <div className="agreement-checkbox">
            <span>이용약관에 동의하십니까?</span>
            <input
              onChange={onAgreeChange}
              ref={(el) => (AGREEMENTS.current[0] = el)}
              type="checkbox"
              id="service-agreement"
              name="service-agreement"
            ></input>
            <label htmlFor="service-agreement">
              <span>동의함</span>
            </label>
          </div>
        </div>

        <div className="agreement-box-style">
          <span className="agreement-title">
            <h3>
              <b> * </b>[필수] 개인정보처리방침
            </h3>
            <button className="agreement-mobile-button"></button>
          </span>

          <DangerHtml htmlStr={userInfo} />

          <div className="agreement-checkbox">
            <span>개인정보처리방침에 동의하십니까?</span>
            <input
              onChange={onAgreeChange}
              ref={(el) => (AGREEMENTS.current[1] = el)}
              type="checkbox"
              id="privacy-agreement"
              name="privacy-agreement"
            ></input>
            <label htmlFor="privacy-agreement">
              <span>동의함</span>
            </label>
          </div>
        </div>

        <div className="agreement-box-style">
          <span className="agreement-title">
            <h3>[선택] 마케팅 정보 메일,SMS 수신동의</h3>
            <button className="agreement-mobile-button"></button>
          </span>

          <DangerHtml htmlStr={marketing} />

          <div className="agreement-checkbox">
            <span>마케팅 정보 메일,SMS 수신동의에 동의하십니까?</span>
            <input
              onChange={onAgreeChange}
              ref={(el) => (AGREEMENTS.current[2] = el)}
              type="checkbox"
              id="sms-agreement"
              name="sms-agreement"
            ></input>
            <label htmlFor="sms-agreement">
              <span>동의함</span>
            </label>
          </div>
        </div>

        <div className="agreement-box-style">
          <span className="agreement-title">
            <h3>
              <b> * </b>[필수] 개인정보 제3자 제공동의
            </h3>
            <button className="agreement-mobile-button"></button>
          </span>

          <DangerHtml htmlStr={outAgree} />

          <div className="agreement-checkbox">
            <span>개인정보 제3자 제공동의에 동의하십니까?</span>
            <input
              onChange={onAgreeChange}
              ref={(el) => (AGREEMENTS.current[3] = el)}
              type="checkbox"
              id="out-agreement"
              name="out-agreement"
            ></input>
            <label htmlFor="out-agreement">
              <span>동의함</span>
            </label>
          </div>
        </div>

        <div className="agreement_all_checkbox">
          <span>전체동의</span>
          <input
            onChange={onTotalAgreeChange}
            ref={TOTAL_AGREEMENT}
            type="checkbox"
            id="all-agreement"
            name="all-agreement"
          ></input>
          <label htmlFor="all-agreement">
            <span>동의함</span>
          </label>
        </div>
        <button type="submit" className="join-btn-comfirm">
          회원가입
        </button>
      </form>
    </section>
  );
};

export default Agreement;
