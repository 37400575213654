import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { SHOP_CUSTOM_IMG_PATH, USER_DTO } from "App-config";
import game_start_btn from "src_assets/game_start.png";
import game_title from "src_assets/hiddencatch_game_title.png";
import reset_game from "src_assets/reset_game.png";
import close_btn from "src_assets/close_btn.png";

import "components/main/style/GameReadyModal.css";

function GameReadyModal({
  data,
  setUser,
  closeGamePopupEvent,
  isApp,
  hiddenCatchGame,
  scriptHandler,
}) {
  const navigate = useNavigate();

  var game = true;

  const gameStart = (e) => {
    // form action안씀
    e.preventDefault();
    if (game) {
      closeGamePopupEvent(e, 2);
      game = false;
      setTimeout(function () {
        game = true;
      }, 2000);
    }
  };

  const closeWebView = (e) => {
    scriptHandler(e, "closeGame");
  };

  const resetGame = (e) => {
    e.preventDefault();
    if (game) {
      hiddenCatchGame();
      game = false;
      setTimeout(function () {
        game = true;
      }, 2000);
    }
  };

  useEffect(() => {
    if (isApp) {
      document.querySelector(".foot-btn").style.display = "none";
      document.querySelector(".app-foot-btn").style.display = "flex";
    }
    // if(navigator.userAgent.indexOf("Windows") < 0 && navigator.userAgent.indexOf("Macintosh") < 0) {
    //   document.querySelector('.game-head').className = 'mobile-game-head';
    // }
  }, []);

  return (
    <div className="game-modal">
      {isApp ? (
        <div className="closeWebView">
          <img src={close_btn} onClick={closeWebView} />
        </div>
      ) : (
        ""
      )}
      <div className="game-head">
        <img src={game_title} />
      </div>
      <form className="game-form">
        <div className="game-thumbnail">
          <img
            src={
              SHOP_CUSTOM_IMG_PATH + "game/hiddenCatchGame/" + data.game_img_ori
            }
          />
        </div>
        <ul className="game-list">
          <li>
            <span>정답보상</span>
            <span className="game-colon">:</span>
            <span className="game-data">
              {parseInt(data.g_reward_price) + parseInt(data.g_price)}p
            </span>
          </li>
          <li>
            <span>정답수&nbsp;&nbsp;&nbsp;</span>
            <span className="game-colon">:</span>
            <span className="game-data">{data.answer_cnt}개</span>
          </li>
          <li>
            <span>제한시간</span>
            <span className="game-colon">:</span>
            <span className="game-data">{data.g_play_time}초</span>
          </li>
        </ul>
      </form>
      <div className="game-foot">
        <div className="foot-btn">
          <img
            src={game_start_btn}
            onClick={gameStart}
            data-title="startBtn"
            className="gameStart"
          />
        </div>
        <div className="app-foot-btn">
          <img
            src={reset_game}
            onClick={resetGame}
            data-title="close"
            className="gameReset"
          />
          <img
            src={game_start_btn}
            onClick={gameStart}
            data-title="startBtn"
            className="gameStart"
          />
        </div>
      </div>
    </div>
  );
}

export default GameReadyModal;
